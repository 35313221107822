export const TRANSLATIONS_PT_BR = {
	LandingPage_Title: "Bem-vindo ao Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Escolha o seu país / Região",
	Header_ContactUs: "Contate-nos",
	Header_Search: "Procurar",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Login / Crie a sua conta",
	Header_LogIn: "Login",
	Header_Cart: "Carrinho",
	Header_ShoppingCart: "Carrinho de compras",
	Header_LogOut: "Sair",
	Header_ChangeDetails: "Alterar detalhes",
	Footer_ExploreOurTraining: "Explorar os treinamentos",
	Footer_TrainingAtYourFingertips: "Treinamentos ao seu alcance",
	Footer_DontMissAnyStory: "Não perca nada",
	Footer_ExploreOurStories: "Explore as nossas histórias",
	Footer_GetInTouchWithUs: "Entre em contato com a gente",
	Footer_SendUsAMessage: "Envie-nos uma mensagem",
	Footer_ToContactForm: "Formulário para contato",
	Footer_InventedForLife: "Inovação para a vida",
	Footer_GeneralContactInfo: "Informações gerais de contato",
	Footer_ProductSecurity_Psirt: "Segurança do Produto (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Patentes de Inovação e licenças",
	Footer_PurchasingLogistics: "Compras e logística",
	Footer_CorporateInformation: "Informação Corporativa",
	Footer_LegalNotice: "Aviso legal",
	Footer_DataProtectionNotice: "Aviso de proteção de dados",
	Footer_PrivacySettings: "Definições de privacidade",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "© Robert Bosch Ltda. 2022, todos os direitos reservados",
	Footer_StillLookingForSomething: "Ainda buscando algo?",
	Footer_TrainingServices: "Treinamentos",
	Footer_Courses: "Cursos",
	Footer_Programs: "Programas",
	Footer_EventsCalendar: "Calendário de Eventos",
	Footer_TrainingCenters: "Centros de Treinamento",
	Footer_Dashboard: "Painel",
	Footer_MyDashboard: "Meu Painel",
	Footer_ShoppingCart: "Carrinho de compras",
	Footer_YourShoppingCart: "Seu Carrinho de compras",
	Footer_Administration: "Administração",
	Footer_Employees: "Funcionários",
	Footer_Wholesalers: "Distribuidores",
	Footer_Transactions: "Transações",
	Footer_Workshops: "Oficinas",
	Footer_WorkLikeABoschApplyNow: "Trabalhe  - Candidate-se agora",
	Footer_BoschGlobalCareers: "Bosch Carreiras",
	Footer_PurchasingAndLogistics: "Compras e logística",
	Footer_BecomeABusinessPartner: "Torne-se um parceiro de negócios",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Como nossos treinamentos evoluem para ajudar a sua empresa",
	Footer_Language: "Seleção de país",
	MenuPopup_MyCart: "Meu Carrinho de compras",
	Stories_OurTrainingStories: "Nossas Histórias de Treinamentos",
	Stories_ConnectingExperts: "Conectando especialistas e entusiastas, sonhadores e instrutores, inovadores e influenciadores - explore e experimente",
	Stories_TopStories: "As melhores histórias",
	Stories_AtHome: "Em casa",
	Stories_Careers: "Carreiras",
	Stories_History: "História",
	Stories_IndustryAndTrades: "Indústria e comércio",
	Stories_Mobility: "Mobilidade",
	Stories_Research: "Pesquisa",
	Stories_Sustainability: "Sustentabilidade",
	Stories_AllStories: "Todas as Histórias",
	Stories_BlogATrainersDay: "BLOG | O dia de um instrutor",
	Stories_ADayinLifeOfATrainer: "Um dia na vida de um instrutor",
	Stories_BoschServiceTraining: "A Formação do Bosch Service",
	Stories_StoryWhatDrivesYouDrivesUs: "HISTÓRIA | O que te move, move a gente",
	Stories_StoryHowCanWeBenefitYourBusiness: "HISTÓRIA | Como podemos beneficiar a sua empresa",
	Stories_HowOurTrainingEvolves: "Como nossos treinamentos evoluem",
	Stories_AModernTrainingApproach: "O mundo da formação online",
	Stories_StoryAVirtualOnlineTrainingWorld: "HISTÓRIA | As suas oportunidades de treinamento online",
	Stories_SubscribeToRSSFeed: "Assine o RSS feed",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Sobre nós",
	Homepage_AutomotiveAftermarket: "Pós-venda Automotivo",
	Homepage_GetInTouch: "Entre em contato",
	Homepage_LatestNews: "Descubra porque somos movidos pela sede de conhecimento",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Nossas vagas abertas",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "O que te move, move a gente",
	BoschServiceTraining_BoschServiceTraining: "Treinamentos do Bosch Service",
	BoschServiceTraining_ShareThisOn: "Compatilhar",
	BoschServiceTraining_TheChallenge: "O desafio: Dominar as novas tecnologias",
	BoschServiceTraining_WorldwidePresence: "Presença Mundial",
	BoschServiceTraining_TechnicalTraining: "Treinamentos técnicos da Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "As ofertas e oportunidades que oferecemos na Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Existem treinamentos adequados para cada público - seja novato ou profissional. Isso inclui treinamentos de um dia e treinamentos de vários dias. Os treinamentos são oferecidos em diferentes áreas técnicas e níveis (como sistemas de injeção a gasolina, sistemas de injeção diesel, sistemas elétricos). Nos treinamentos técnicos, a Bosch transmite um amplo conhecimento de todos os sistemas de veículos da Bosch. Os sistemas de outros fabricantes também são discutidos nos treinamentos. O conteúdo é especialmente adequado às necessidades dos funcionários das oficinas.",
	BoschServiceTraining_TrainedEmployeesDesc: "Funcionários formados são um benefício óbvio para as oficinas automóveis. Porque os veículos de hoje estão cada vez mais complexos e as novas tecnologias apresentam constantemente novos desafios. Somente a experiência necessária permite que os funcionários da oficina executem diagnósticos, trabalhos de manutenção e reparação em modelos de veículos atuais de uma maneira eficiente e económica.",
	BoschServiceTraining_RegardlessOfHow: "Independentemente de quão bons e experientes os especialistas automotivos sejam - haverá sempre situações em que eles entrarão em contato com novos sistemas de tecnologia automotivo ou com erros desconhecidos. A este respeito, a resolução de problemas pode tornar-se especialmente demorada ou possivelmente não apresentar resultados satisfatórios. Na pior das hipóteses, os clientes podem ter de ficar sem o seu veículo por muito mais tempo do que o esperado.",
	BoschServiceTraining_ItIsPrecisely: "É precisamente neste ponto que a Bosch concretiza seu treinamneto de serviços: melhorando a qualidade do serviço e agilizando os processos. O treinamento atual da Bosch fornece suporte na localização de problemas mais rapidamente, reparando mais veículos no mesmo período de tempo e reduzindo o tempo de espera de seus clientes. Assim, os procedimentos sistemáticos que a Bosch ensina garantem um trabalho mais rápido e produtivo.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Há mais de 125 anos, a Bosch tem sido o fornecedor preferencial de equipamentos originais, peças, componentes e sistemas para muitos dos principais fabricantes de automóveis em todo o mundo. Esta experiência proporciona um conhecimento único das tecnologias mais recentes que servem como base para o suporte da Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Os Centros de Treinamentos de Serviços Bosch podem ser encontrados em todo o mundo: da Espanha à Turquia, África do Sul, da Austrália ao Brasil - a Bosch oferece aos seus funcionários treinamentos orientados para a parte prática, que estão sempre em linha com as tecnologias de ponta.",
	BoschServiceTraining_AgileCompany: "Empresa ágil",
	BoschServiceTraining_Collaboration: "Colaboração",
	BoschServiceTraining_ConnectedMobility: "Mobilidade Conectada",
	BoschServiceTraining_ArtificialIntelligence: "Inteligência artificial",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Líderes em diálogo",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Equipamentos de diagnóstico e teste",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Fundamentos da tecnologia automotiva",
	BoschServiceTraining_Icon_Gasoline: "Gasolina",
	BoschServiceTraining_Icon_Diesel: "Diesel",
	BoschServiceTraining_Icon_AlternativeDrives: "Condução Alternativa",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Eletricidade e Eletrônica",
	BoschServiceTraining_Icon_BodyAndComfort: "Carroceria e Conforto",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chassis e Freios",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Sistemas de Transmissão",
	BoschServiceTraining_Icon_CommercialVehicles: "Veículos Comerciais",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Treinamentos online",
	BoschServiceTraining_Icon_BusinessSkills: "Competências Empresariais",
	BoschServiceTraining_Icon_Accreditations: "Certificações",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programas de Treinamentos",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "Nome do Treinador",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Um dia na vida de um instrutor",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Cada dia é uma aventura",
	ADayInTheLifeOfATrainer_Summary: "Resumo",
	ADayInTheLifeOfATrainer_TheDayJourney: "A jornada do dia",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Todos os dias são agitados, mas são sempre uma experiência gratificante. O prazer de formar alunos sabendo que eles detêm a chave do nosso futuro é uma responsabilidade, mas ao mesmo tempo é muito emocionante. Ver os alunos crescerem a cada aula, diária e semanalmente, prova-nos que estamos trabalhando com o objetivo comum de melhorar os seus conhecimentos, incorporando a tecnologia de ponta que impulsiona o futuro!",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "No final de cada dia sentamos e refletimos sobre o dia de trabalho. Os dias passam tão rápido, mas isso é a prova de que o que estamos a alcançar não é apenas gratificante para os alunos, mas também para nós como instrutores.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "Podemos voltar para casa no final do dia satisfeitos por termos feito um ótimo trabalho!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Início",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Preparação e verificações finais do material ddo treinamento. Verificar e-mails importantes",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Início do treinamento",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Boas vindas aos participantes. Apresentação da agenda diária. Conhecer o grupo compreendendo a dinâmica de cada indivíduo.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teoria",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Uma certa interação com o grupo é necessária para tornar o treinamento o mais interessante possível. Certifique-se de que há tarefas relacionadas com a teoria para envolver ainda mais o grupo",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Hora de almoço",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Prática",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "O treinamento continuo na oficina com algumas tarefas práticas. Como treinador, é sempre um requisito estar presente e disponível sempre para qualquer eventualidade e orientação",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Recapitular",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Regresse à sala de aula para um resumo, para discutir o treinamento do dia. Responda a quaisquer perguntas que possam surgir. Comece a preparação para as próximas sessões de treinamento.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Final do dia",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Os certificados de presença são entregues aos participantes.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Os participantes vão para casa.",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Como podemos ajudar e beneficiar a sua empresa",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Como nosso treinamento evoluiu",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Estamos em constante evolução para garantir o fornecimento de treinamentos atualizados e de qualidade",
	BoschTrainingBenefits_TheBoschServiceTraining: "O Centro de Treinamento de Serviços Bosch gostaria de auxiliá-lo no desenvolvimento do seu negócio através do seu amplo e relevante portfólio de treinamentos técnicos / empresarial com qualificações certificadas. As páginas seguintes fornecem uma visão geral do nosso programa de treinamento, bem como as oportunidades atuais de educação adicional. Esperamos encontrá-lo e aos seus funcionários nos nossos treinamentos e seminários.",
	BoschTrainingBenefits_LongStandingExperience: "Experiência de longa data com um amplo conhecimento de todos os sistemas e na área da tecnologia automotiva.",
	BoschTrainingBenefits_QuickAndEfficient: "Soluções rápidas e eficientes para muitos problemas.",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "As vantagens e os benefícios",
	BoschTrainingBenefits_LMS: "Sistema de Gestão de Aprendizado - Soluções de treinamento da Bosch",
	BoschTrainingBenefits_BTSCoversTheFullRange: "A Bosch Training Solutions abrange toda a gama da áreas automotiva. Especialize-se em áreas específicas ou amplie a sua competência com as ofertas de treinamento da Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Conhecimento adquirido, manutenção de reparação de instalações e trabalho em serviços.",
	BoschTrainingBenefits_TheHighDegree: "O alto grau de conteúdo prático e pequenos grupos garantem uma aprendizagem orientada para o objetivo",
	BoschTrainingBenefits_ParticipationInTraining: "A participação no treinamento aumenta a motivação e a satisfação dos funcionários e as qualificações complementares convencem os clientes",
	BoschTrainingBenefits_IncreasedSales: "Aumento das vendas através do conhecimento do sistema de marcas cruzadas",
	BoschTrainingBenefits_MethodicalProcedures: "Procedimentos metódicos reduzem o tempo de espera para os clientes",
	BoschTrainingBenefits_HighlyQualified: "Instrutores altamente qualificados transmitem procedimentos sistemáticos",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identificação da solução de treinamentos apropriados para você ou para a sua empresa",
	BoschTrainingBenefits_DirectOnlineBooking: "Inscrição online direta e gestão de cursos para você ou para os seus funcionários",
	BoschTrainingBenefits_OnlineAccess: "Acesso online à documentação de aprendizagem",
	BoschTrainingBenefits_AccessToYourOwn: "Acesso ao seu histórico de treinamento pessoal e certificados",
	BoschTrainingBenefits_ToolsForManagement: "Ferramentas para a gestão do seu próprio desenvolvimento de carreira",
	BoschTrainingBenefits_ManagerHasFull: "O gerente tem uma visão geral completa dos cursos de seus funcionários dentro do sistema",
	BoschTrainingBenefits_Access24hours: "Acesso 24 horas, 7 dias por semana",
	ModernTrainingApproach_KnowledgeIsKey: "O conhecimento é um fator chave de sucesso para qualquer oficina. É por isso que a Bosch oferece um programa abrangente de desenvolvimento e treinamento profissional. Isto permite que os alunos adquiram o conhecimento certo na hora e no lugar certo - conhecimento necessário para serem capazes de fazer a manutenção e reparação do veículo do cliente de uma forma profissional. A Bosch adicionou Lives como um novo módulo do pacote de e-learning já oferecido. Eles consistem em sessões de treinamento de serviço projetadas especificamente para lives.",
	ModernTrainingApproach_ANewLearning: "Uma nova oportunidade de aprendizagem",
	ModernTrainingApproach_YourOnlineTraining: "As suas oportunidades de treinamento online",
	ModernTrainingApproach_RecentlyTheWorld: "Recentemente o mundo mudou, veja o que estamos fazendo para ajudar",
	ModernTrainingApproach_TheOnlineTrainingWorld: "O mundo do treinamento online",
	ModernTrainingApproach_NewTeachingMethod: "Novo método de ensino",
	ModernTrainingApproach_InAdditionTo: "Além do treinamento presencial já existente, incluímos treinamentos online ao vivo no programa de treinamento. O nosso Instrutor está à sua disposição com um amplo conhecimento teórico na sala de aula virtual ou com exemplos práticos. Como sempre, receberá bastante informação sobre os sistemas do veículo e os produtos Bosch correspondentes. Claro que também tem a oportunidade de fazer perguntas. Essas perguntas serão respondidas diretamente na live. As lives têm entre 60 a 120 minutos de duração. É necessária uma conexão estável de Internet. Procure uma live adequada diretamente no nosso catálogo de treinamentos, escrevendo \  Live \ no campo de pesquisa.",
	ModernTrainingApproach_BoschWebcastingHeader: "Live da Bosch: inovador e prático",
	ModernTrainingApproach_BoschWebcastingText: "Para participar num Live da Bosch, primeiro o participante deve conectar-se. Um código de acesso individual é enviado antes da sessão para possibilitar o login. Podem comparecer até 200 participantes ao mesmo tempo através de várias localidades diferentes. O treinamento é conduzida por um Instrutor e as perguntas podem ser enviadas a qualquer momento através da função de chat ao vivo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "Complemento digital para treinamento em sala de aula",
	ModernTrainingApproach_TheDigitalSupplementText: "As lives e os treinamentos em sala de aula complementam-se perfeitamente. Em ambos os casos, um instrutor qualificado conduz o treinamento. As lives são adequados para tópicos que podem ser transmitidos digitalmente sem a necessidade de trabalhar no veículo. Por exemplo, conhecer algumas características do ESI[tronic] de um novo sistema de assistência ao condutor. Quando se trata de um trabalho em equipa eficiente na oficina ou de um trabalho prático numa tecnologia automóvel, o treinamento presencial ainda é a primeira escolha.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "A transmissão online pode ser mais barata",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Os custos por participante são geralmente mais baixos com lives do que com um curso equiparável em sala de aula. A razão é que mais participantes podem beneficiar dele e o esforço associado ao treinamento é significativamente menor (nenhuma viagem de ida e volta para o local e menos tempo necessário).",
	ModernTrainingApproach_BookAndProfitHeading: "Livro e lucro",
	ModernTrainingApproach_BookAndProfitText: "Podem inscrever-se nas lives através do nosso site, assim como nos nossos treinamentos. Existe a opção de pagar uma taxa individual para cada live ou se inscrever em uma assinatura anual para um determinado número de lives.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Comparação entre lives e treinamento em sala de aula na Bosch",
	ModernTrainingApproach_Webcasting: "Lives",
	ModernTrainingApproach_Classroom: "Sala de aula",
	ModernTrainingApproach_WebcastingP1: "Até 200 participantes podem participar ao mesmo tempo de qualquer parte do mundo",
	ModernTrainingApproach_WebcastingP2: "Baixa complexidade e custos relativamente baixos, nenhuma viagem necessária também",
	ModernTrainingApproach_WebcastingP3: "Ideal para tópicos individuais específicos e concisos",
	ModernTrainingApproach_WebcastingP4: "As oficinas podem transmitir uma live para seus funcionários em qualquer lugar e a qualquer hora",
	ModernTrainingApproach_WebcastingP5: "Entre 50-70% menos tempo necessário do que para treinamento em sala de aula",
	ModernTrainingApproach_ClassroomTrainingP1: "Até 16 participantes encontram-se ao mesmo tempo",
	ModernTrainingApproach_ClassroomTrainingP2: "Despesas de viagem e custos geralmente mais altos por pessoa",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal quando se trata de otimizar a colaboração ou aprender diretamente num veículo",
	ModernTrainingApproach_ClassroomTrainingP4: "Exercícios práticos e interação com o instrutor",
	ModernTrainingApproach_ClassroomTrainingP5: "O instrutor pode adaptar melhor o conhecimento a ser transmitido de acordo com as atuais necessidades dos participantes",
	ModernTrainingApproach_OnlineTrainingOfferings: "Ofertas de treinamentos online (WBT / VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Hoje, a aprendizagem independente no computador é parte integrante da transferência de conhecimento. A liberdade de escolher a hora e local, a repetição ilimitada, a velocidade individual de aprendizagem e os conteúdos de aprendizagem interativos tornam este método uma ferramenta de estudo indispensável.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Cada elemento do treinamento online (WBT) foi cuidadosamente estruturado. Além de fornecer uma ampla base de conhecimento, estes cursos podem servir como uma preparação eficaz para programas de treinamentos nos centros de treinamentos da Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "A simulação de diagnóstico virtual oferece ao usuário possibilidades de atuação para situações diárias da oficina, tais como os passos necessários para um procedimento de solução de problemas usando dispositivos de diagnóstico da Bosch numa oficina virtual.",
	ModernTrainingApproach_Advantages: "Vantagens",
	ModernTrainingApproach_AdvantagesP1: "Um método rápido e eficiente de fornecer conhecimento",
	ModernTrainingApproach_AdvantagesP2: "Aprendizagem variada",
	ModernTrainingApproach_AdvantagesP3: "Pode ser usado quantas vezes for necessário",
	ModernTrainingApproach_AdvantagesP4: "Tempo e dinheiro mínimos",
	ModernTrainingApproach_AdvantagesP5: "Velocidade individual de aprendizagem",
	ModernTrainingApproach_AdvantagesP6: "Tempo de estudo flexível",
	ModernTrainingApproach_AdvantagesP7: "Possibilidade de repetição",
	ModernTrainingApproach_AdvantagesP8: "Sem custos de viagem",
	ModernTrainingApproach_AdvantagesP9: "Sem capacidade ou conflitos de agenda",
	Dashboard_CoursesViewAndManageYourCourses: "CURSOS | Visualize e administre os seus cursos",
	Dashboard_MyCourses: "Os meus cursos",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAMA DE TREINAMENTOS | Veja os seus programas de treinamentos",
	Dashboard_MyTrainingProgram: "O meu Programa de Treinamento",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Visualize e administre o seu portfólio de eLearning",
	Dashboard_MyELearning: "O meu eLearning",
	Dashboard_AccountManageYourAccount: "CONTA | Administrar a sua conta",
	Dashboard_MyAccount: "A minha conta",
	Dashboard_MyDashboard: "Meu Painel",
	Dashboard_ManageYourAccount: "Administre a sua conta",
	Dashboard_Workshops: "Oficinas",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRAÇÃO | Administre a sua oficina",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRAÇÃO | Administre as suas equipes",
	Dashboard_Administration: "Administração",
	Dashboard_ViewYourCourses: "Visualize os seus cursos",
	Dashboard_ViewYourTrainingPrograms: "Visualize os seus programas de treinamentos",
	Dashboard_TrainingPrograms: "Programas de Treinamentos",
	Dashboard_ViewAndEditYourDetails: "Visualize e edite os seus detalhes",
	Dashboard_ViewYourELearningCourses: "Visualize os seus cursos de eLearning",
	Dashboard_Pending: "Pendente",
	Dashboard_RequiresAdministratorSignOff: "(requer a autorização do administrador)",
	Dashboard_HistoricalRecords: "Histórico de registros",
	Dashboard_Registered: "Registrado",
	Dashboard_WaitingList: "Lista de Espera",
	Dashboard_RecommendedForYou: "Recomendado para si",
	Dashboard_AccountDetails: "Detalhes de conta",
	Dashboard_ChangeProfileImage: "Mudar imagem de perfil",
	Dashboard_Title: "Título",
	Dashboard_Role: "Função",
	Dashboard_FirstName: "Nome",
	Dashboard_LastName: "Sobrenome",
	Dashboard_Telephone: "Telefone",
	Dashboard_Mobile: "Celular",
	Dashboard_Email: "E-Mail",
	Dashboard_Language: "Idioma",
	Dashboard_Save: "Salvar",
	Dashboard_Student: "Aluno",
	Dashboard_WorkshopTrainingAdministrator: "Administrador do treinamento da oficina",
	Dashboard_WholesalerTrainingAdministrator: "Administrador do treinamento do distribuidor",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administração da Empresa",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Administre as suas oficinas",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Administre os seus funcionários",
	Dashboard_CompanyAdministration_Workshop: "Oficina",
	Dashboard_CompanyAdministration_Address: "Endereço",
	Dashboard_CompanyAdministration_Zip: "Código Postal",
	Dashboard_CompanyAdministration_City: "Cidade",
	Dashboard_CompanyAdministration_Manage: "Administrar",
	Dashboard_AdministrationSection_AdministrationTitle: "Administração",
	Dashboard_AdministrationSection_ManageTheTeam: "Administre a equipa",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRAÇÃO | Administre os seus funcionários",
	Dashboard_AdministrationSection_Employees: "Funcionários",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRAÇÃO | Administre os seus distribuidores",
	Dashboard_AdministrationSection_Wholesalers: "Distribuidores",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRAÇÃO | Administre as suas transações",
	Dashboard_AdministrationSection_Transactions: "Transações",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administração de Funcionários",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Administre os seus funcionários ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Cadastrar em treinamento",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Histórico de registros",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Editar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Funcionário",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Adicionar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Criar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Pendente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(requer a autorização do administrador)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Histórico de registros",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista de Espera",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Lista atual de Funcionários",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Gestão de Funcionários",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nome",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Conta de Funcionário",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Visualize e edite os detalhes do funcionário ...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Definições",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Permitir conta pessoal",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Atualização do proxy da administração de funcionários da oficina",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Permitir que o funcionário se inscreva em eventos e treinamentos",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Enviar convite por e-mail",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Desativar conta",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Salvar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Enviar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Editar",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Administração de Distribuidores",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Administre os seus Distribuidores",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Lista de distribuidores aprovados",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nome",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Apagar",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Criar",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Adicionar",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Criação de conta de novo funcionário",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Criar detalhes para novos funcionários",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Adicionar novo administrador de distribuidor aprovado",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Criar detalhes para novos distribuidores ...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Escolha um distribuidor aprovado da lista",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transações",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Visualize e administre as suas transações ...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Voltar",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Número da Fatura",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Descrição",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Método de Pagamento",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Data",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Quantia",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Download",
	TrainingCentres_TrainingCentres: "Centros de Treinamento",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Os nossos centros de treinamento estão localizados em todo o mundo",
	TrainingCentres_CountryCategory: "Categoria do País",
	TrainingCentres_CityCategory: "Categoria da Cidade",
	TrainingCentres_Country: "País",
	TrainingCentres_City: "Cidade",
	TrainingCatalogue_TrainingCatalogue: "Catálogo de Treinamentos",
	TrainingCatalogue_LearningAtTheHighestLevel: "Aprendizagem no mais alto nível",
	TrainingCatalogue_StartDate: "Data de início",
	TrainingCatalogue_EndDate: "Data de fim",
	TrainingCatalogue_TrainingName: "Nome do treinamento",
	TrainingCatalogue_Language: "Idioma",
	TrainingCatalogue_Days: "Dias",
	TrainingCatalogue_Seats: "Lugares disponiveis",
	TrainingCatalogue_Price: "Preço com impostos",
	TrainingCatalogue_Location: "Localização",
	TrainingCatalogue_PartNumber: "Referência",
	TrainingCatalogue_TrainingCourse: "Treinamentos",
	TrainingCatalogue_Category: "Categoria",
	TrainingCatalogue_TrainingCategory: "Categoria do treinamento",
	TrainingCatalogue_CourseName: "Nome do Curso",
	TrainingCatalogue_TotalDays: "Total de dias",
	TrainingCatalogue_Venue: "Local",
	TrainingCatalogue_Details: "Detalhes",
	TrainingCatalogue_Prerequisites: "Pré-requisitos",
	TrainingCatalogue_CourseType: "Tipo de curso",
	TrainingCatalogue_LearningMethod: "Método de Ensino",
	TrainingCatalogue_AddToWaitingList: "Adicionar à lista de espera",
	ShoppingCart_ShoppingCart: "Carrinho de Compras",
	ShoppingCart_ReviewAndManage: "Reveja e administre as suas subscrições",
	ShoppingCart_YouHaveAddedItem: "Adicionou os seguintes itens ao seu carrinho",
	ShoppingCart_Item: "Item",
	ShoppingCart_Quantity: "Quantidade",
	ShoppingCart_PricePerItem: "Preço por item",
	ShoppingCart_TotalPrice: "Preço total",
	ShoppingCart_VatIsIncluded: "Preço sem impostos",
	ShoppingCart_Redeem: "Se tiver créditos, clique aqui para resgatá-lo",
	ShoppingCart_Purchase: "Clique aqui para comprar créditos de treinamentos",
	ShoppingCart_Checkout: "Finalizar",
	ShoppingCart_ContinueShopping: "Continuar as compras",
	ShoppingCart_TotalPriceInclVat: "Preço total com impostos",
	ShoppingCart_Apply: "Aplicar",
	ShoppingCart_ShoppingCartEmpty: "O seu carrinho de compras está vazio",
	ShoppingCart_VatIncluded: "Impostos",
	ShoppingCart_ManageYourShopping: "Administre as suas compras",
	ShoppingCart_Participant: "Participante",
	ShoppingCart_Complete: "Completo",
	ShoppingCart_NetPrice: "Preço líquido",
	ShoppingCart_Workshop: "Oficina",
	CheckOut_PreviewYourOrder: "Visualize o seu pedido",
	CheckOut_YourShoppingCart: "Carrinho",
	CheckOut_Preview: "Prévia",
	CheckOut_Payment: "Pagamento",
	CheckOut_Summary: "Resumo",
	CheckOut_Back: "Voltar",
	CheckOut_PleaseReadAndAgreeOurTerms: "Por favor leia e concorde com nossos termos e condições",
	CheckOut_IHaveReadTheTermsAndCondition: "Li os [termos e condições], compreendo e aceito",
	CheckOut_YesIAgree: "Sim - eu concordo com os termos e condições",
	CheckOut_Continue: "Continuar",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "Parabéns! Recebemos o seu pedido",
	CheckOut_OrderConfirmation: "Receberá a confirmação final do pedido assim que a sua solicitação for processada com sucesso",
	CheckOut_WeWillContactYouDirectly: "Entraremos em contato diretamente caso tenhamos alguma dúvida relacionada com a sua solicitação",
	CheckOut_AddToCart: "Adicionar ao carrinho",
	CheckOut_CreditCart: "Cartão de Crédito",
	CheckOut_DirectDebit: "Cartão de Débito",
	CheckOut_TrainingCredits: "Crédito de treinamento",
	CheckOut_PreferredWholesaler: "Distribuidor",
	EventCalendar_EventCalendar: "Calendário de eventos",
	EventCalendar_TrainingEventsForAllYourNeeds: "Eventos e treinamentos para todas as suas necessidades",
	TrainingCourse_TrainingCourse: "Catálogo de treinamentos",
	TrainingCourse_LearningAtTheHighestLevel: "Aprendizagem ao mais alto nível",
	TrainingProgram_Title: "Catálogo de Programa de Treinamentos",
	TrainingProgram_SubTitle: "Aprendizagem ao mais alto nível",
	AccountDeactivationModal_Title: "Tem a certeza que deseja desativar esta conta?",
	AccountDeactivationModal_Line1: "A desativação desta conta é permanente e removerá todo o conteúdo, incluindo transações de solicitação de serviços e saldos de membros da equipa e configurações de perfil.",
	AccountDeactivationModal_Line2Part1: "Se estiver dentro de um período de subscrição ativa, por favor consulte a nossa política de cancelamento no nosso",
	AccountDeactivationModal_Line2Part2: "Termos e Condições",
	AccountDeactivationModal_Line2Part3: "antes de enviar a sua solicitação.",
	AccountDeactivationModal_Line3: "Tem a certeza que deseja enviar uma solicitação para desativar esta conta?",
	AccountDeactivationModal_Cancel: "Cancelar",
	AccountDeactivationModal_Send: "Enviar",
	CurrentUserAccountDeactivationModal_Title: "Tem a certeza que deseja desativar a sua conta?",
	CurrentUserAccountDeactivationModal_Line1: "A desativação da sua conta é permanente e removerá todo o conteúdo, incluindo transações de solicitação de serviços e saldos de membros da equipa e configurações de perfil.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Se estiver dentro de um período de subscrição ativa, por favor consulte a nossa política de cancelamento no nosso",
	CurrentUserAccountDeactivationModal_Line3: "Tem a certeza que deseja enviar uma solicitação para desativar a sua conta?",
	CurrentUserAccountDeactivationModal_Yes: "Sim",
	CurrentUserAccountDeactivationModal_No: "Não",
	CourseWithdrawalModal_Title: "Cancelamento de curso",
	CourseWithdrawalModal_Line1: "Tem a certeza que deseja cancelar a sua inscrição no curso?",
	CourseWithdrawalModal_Line2Part1: "Os cancelamentos de inscrição estão sujeitos a taxas de cancelamento e serão cobradas de acordo com os",
	CourseWithdrawalModal_Line2Part2Link: "Política de cancelamento em nossos [Termos e Condições]",
	CourseWithdrawalModal_Yes: "Sim, cancelar inscrição",
	CourseWithdrawalModal_No: "Não, cancelamento sem efeito",
	Settings_Settings: "Definições",
	Settings_ManageYourSettings: "Administre as suas configurações",
	Settings_Profile: "Perfil",
	Settings_MyBoschGlobalIdLoginInformation: "Informações de Login Bosch",
	Settings_Edit: "Editar",
	Settings_CompanyDetails: "Detalhes da Empresa",
	Settings_RequestAccountDeletion: "Solicitar eliminação de conta",
	Settings_AccountDeletionText: "Se excluir a sua conta, todos os seus dados (incluindo o seu endereço e dados de pagamento) serão apagados e não estarão mais disponíveis.",
	Settings_DeleteAccountRequestConfirmation: "Confirmação de solicitação de exclusão de conta",
	Settings_DeleteAccountRequestConfirmationText: "Recebemos a sua solicitação de exclusão de conta e entraremos em contato por e-mail ou telefone para confirmação final",
	RecommendedHotels_RecommendedHotels: "Hotéis recomendados",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "O nossos centros de treinamentos estão localizados em todo o mundo",
	Error_Common: "Algo deu errado!",
	Error_404: "A página que você visitou não existe",
	Error_401: "Você não tem acesso a esta página",
	Error_500: "O servidor está apresentando um erro",
	Dashboard_Courses_Table_Course: "Treinamento",
	Dashboard_Courses_Table_Date: "Data [Duração]",
	Dashboard_Courses_Table_Venue: "Local",
	Dashboard_Courses_Table_TrainingType: "Tipo de Treinamento",
	Dashboard_Courses_Table_Action: "Ação",
	Dashboard_Courses_Table_TasksToComplete: "Você tem as seguintes tarefas para concluir",
	Dashboard_Courses_Table_Withdraw: "Cancelamento",
	Dashboard_Courses_Table_Remove: "Remover",
	Dashboard_Courses_Table_View: "Visualizar",
	Dashboard_Courses_Table_NoRecordsToDisplay: "Não há registros para serem exibidos",
	Dashboard_Courses_Table_CompletionDate: "Data de conclusão",
	Dashboard_Courses_Table_Actions_Assignments: "Atribuições",
	Dashboard_Courses_Table_Actions_Certification: "Certificação",
	Dashboard_Courses_Table_Actions_Evaluations: "Avaliações",
	Dashboard_Courses_Table_Actions_Assessments: "Análises",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Adicionar ao calendário",
	Dashboard_Courses_Table_CourseDetails_Task: "Tarefa",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Data de vencimento",
	ContactUs_Headline: "Contate-nos",
	ContactUs_HowCanWeHelp: "Como podemos ajudá-lo?",
	ContactUs_EmailTab: "E-mail",
	ContactUs_PhoneTab: "Telefone",
	ContactUs_LetterTab: "Carta",
	ContactUs_Phone_Headline: "Ligue para nós!",
	ContactUs_Phone_Description: "Aguardamos o seu contato e teremos todo o prazer em atendê-lo.",
	ContactUs_Phone_PhoneNumber: "Por favor, utilize o formulário de contato para nos enviar uma mensagem.",
	ContactUs_Phone_ChargeNotice: "As cobranças variam dependendo do seu fornecedor de serviços e do país.",
	ContactUs_Letter_Headline: "Envie-nos uma mensagem!",
	ContactUs_Letter_Description: "Tem dúvidas, pedidos ou sugestões? Aguardamos a sua consulta.",
	ContactUs_Email_Headline: "Envie-nos um e-mail!",
	ContactUs_Email_Description: "Envie-nos a sua mensagem preenchendo o formulário abaixo. Um dos nossos consultores terá todo o prazer em entrar em contato com você.",
	ContactUs_Email_InputName: "Nome*",
	ContactUs_Email_InputCompany: "Empresa",
	ContactUs_Email_InputCountry: "País*",
	ContactUs_Email_InputEmail: "E-mail*",
	ContactUs_Email_InputMessage: "Mensagem*",
	ContactUs_Email_DataProtection: "Aviso de proteção de dados*",
	ContactUs_Email_DataProtectionConfirm: "Li a Política de Privacidade.*",
	ContactUs_Email_SecurityCheck: "Verificação de segurança",
	ContactUs_Email_SecurityCodeConfirm: "Por favor insira os caracteres corretos que você vê na caixa acima.",
	ContactUs_Email_SecurityCodeInvalid: "A resposta de texto captcha que você digitou está incorreta.",
	ContactUs_Email_MandatoryNotice: "Por favor, preencha os campos obrigatórios.",
	ContactUs_Email_SuccessMessage: "A sua mensagem foi enviada",
	ContactUs_Email_TakenNoteDataProtection1: "Eu li a",
	ContactUs_Email_TakenNoteDataProtection2: "Política de Privacidade.*",
	ContactUs_Email_SubmitButton: "Enviar",
	ContactUs_Email_FieldErrorMessage: "Por favor, preencha os campos obrigatórios.",
	ContactUs_Email_SuccessModal_OK: "OK",
	AccountInReviewModal_Title: "A sua conta está em análise!",
	AccountInReviewModal_Paragraph1: "Obrigado por registrar uma conta pessoal na Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Nossa equipe de treinamento dedicada está atualmente revisando e configurando a sua conta. Você receberá a confirmação final por e-mail, assim que sua conta for configurada com sucesso.",
	AccountInReviewModal_Paragraph3: "Enquanto isso, fique à vontade para navegar no nosso site com acesso limitado.",
	AccountInReviewModal_Paragraph4: "Se tiver alguma dúvida ou se a sua conta não for processada, excluíndo fins de semana e feriados, entre em contato conosco",
	AccountInReviewModal_ContactUsLink: "Contate a gente",
	AccountInReviewModal_OKButton: "OK, deixe-me entrar!",
	Header_YesButton: "Sim",
	Header_NoButton: "Não",
	Header_LogOutText: "Quer encerrar a sessão?",
	Footer_EnquiryText: "Aguardamos a sua consulta,",
	Footer_Telephone: "Telefone",
	Footer_TermsAndConditions: "Termos e Condições",
	Footer_HowOurTrainingEvolves: "Como os nossos treinamentos evoluem",
	FooterSearch_SearchFieldPlaceholder: "Procurar",
	TrainingCatalogue_Action: "Ação",
	TrainingCatalogue_Duration: "Duração",
	TrainingCatalogue_Information: "Informação",
	Settings_PersonalDetails: "Detalhes pessoais",
	Reset_All_Filters: "Redefinir todos os filtros",
	EventTable_Days: "Dias",
	SearchField_AllResults: "Todos os resultados",
	TrainingCentresLayout_ViewDetails: "Ver detalhes",
	Dropdown_Placeholder: "Escolher...",
	WaitlistPopup_SelectButtonText: "Selecionar",
	WaitlistPopup_StartDate: "Data de início",
	WaitlistPopup_EndDate: "Data final",
	WaitlistPopup_Language: "Idioma",
	WaitlistPopup_City: "Cidade",
	WaitlistPopup_Workshop: "Oficina",
	WaitlistPopup_Student: "Estudante",
	WaitlistPopup_PreferredLocation: "Localização preferida",
	WaitlistPopup_SignUpCourseWaitingList: "Inscreva-se na lista de espera do curso",
	WaitlistPopup_SignUpWithoutSelectingDate: "Inscreva-se na lista de espera sem selecionar uma data",
	WaitlistPopup_GeneralWaitList: "Lista de espera geral",
	WatinglistPopup_CourseLabel: "Curso",
	Common_ContinueButton: "Continuar",
	UserAccountDeactivationModal_ConfirmLabel: "Confirmar",
	Filter_FilterLabel: "Filtro",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Você já se encontra na lista de espera, deseja cancelar o seu registro existente e fazer um novo?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Sim, cancelar",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "Não, voltar",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Cancelar sua inscrição",
	WaitinglistPopup_SignUpButtonText: "Inscrever-se",
	MyAccount_FirstNameRequied: "O primeiro nome é obrigatório",
	MyAccount_LastNameRequired: "O sobrenome é obrigatório",
	MyAccount_RoleRequired: "A função é obrigatória",
	MyAccount_MobileRequired: "O celular é obrigatório",
	MyAccount_EmailRequired: "O e-mail é obrigatório",
	MyAccount_LanguageRequired: "O idioma é obrigatório",
	MyAccount_UpdateSuccessMessage: "Os detalhes da sua conta foram atualizados com sucesso!",
	MyAccount_UpdateSuccessModal_ButtonText: "Sim, fechar",
	Infor_CorporateInformation_Header: "Informação Corporativa",
	Infor_LegalNotice_Header: "Aviso Legal",
	Infor_PrivacyPolicy_Header: "Aviso de proteção de dados (Política de Privacidade)",
	CreateEmployeeAccountModification_FormValidation_Role: "A função é obrigatória",
	CreateEmployeeAccountModification_FormValidation_Firstname: "O primeiro nome é obrigatório",
	CreateEmployeeAccountModification_FormValidation_Lastname: "O sobrenome é obrigatório",
	CreateEmployeeAccountModification_FormValidation_Mobile: "O celular é obrigatório",
	CreateEmployeeAccountModification_FormValidation_Email: "O e-mail é obrigatório",
	CreateEmployeeAccountModification_FormValidation_Language: "O idioma é obrigatório",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Convite para se juntar ao Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "Você adicionou com sucesso um novo membro à sua equipe!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "Gostaria que enviássemos um convite por e-mail para o seu novo membro da equipe, com instruções sobre como eles podem criar a sua própria conta pessoal na Bosch Automotive Training Solutions?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "Não",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Sim, enviar convite",
	ShoppingCart_Venue_Fee: "Taxa de localidade",
	ShoppingCart_Trainning_Name: "Nome do treinamento",
	ShoppingCart_Available_Credits: "Créditos disponíveis",
	ConfirmItsMeModal_Hi: "Olá",
	ConfirmItsMeModal_Description1: "Você concluiu com sucesso o convite compartilhado pelo administrador da sua empresa, para criar a sua própria conta pessoal na Bosch Automotive Training Solutions.",
	ConfirmItsMeModal_Description2: "Antes de continuar, confirme ou atualize os detalhes da sua conta.",
	ConfirmItsMeModal_Description3: "Após a confirmação, será solicitado um novo login para concluir a criação da sua conta.",
	ConfirmItsMeModal_CompanyDetails: "Detalhes da Empresa",
	ConfirmItsMeModal_YourDetails: "Seus detalhes",
	ConfirmItsMeModal_YourDetails_Title: "Título",
	ConfirmItsMeModal_YourDetails_Function: "Função",
	ConfirmItsMeModal_YourDetails_Name: "Nome",
	ConfirmItsMeModal_YourDetails_Mobile: "Celular",
	ConfirmItsMeModal_YourDetails_Email: "E-mail",
	ConfirmItsMeModal_YourDetails_Language: "Idioma",
	ConfirmItsMeModal_ConfirmButton: "Confirmar",
	SelectWholesalerModal_WholesalerGroupLabel: "Distribuidor",
	SelectWholesalerModal_WholesalerBranchLabel: "Pontos de venda",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Por favor selecione",
	ApplyButton: "Aplicar",
	SelectWholesalerModal_Title: "Escolha o seu Distribuidor",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "Deseja cancelar o seu registro?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "O seu registro foi cancelado com sucesso!",
	WaitlistPopup_AddToWaitListSuccess: "O seu registro foi adicionado com sucesso!",
	ErrorCommon_Header: "ERRO",
	CommonButton_Close: "Fechar",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "Por favor feche esta janela para continuar!",
	EmbeddedVideoPlayer_StartButtonText: "Iniciar",
	EmbeddedVideoPlayer_RefresherButtonText: "Atualização",
	DeactivateWholesalerModal_ConfirmMessage: "Tem a certeza que deseja remover este distribuidor?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "Envio de e-mail de convite bem sucedido!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Convidar novamente",
	ContactUs_Mobile_BR: "(19) 2103-1419",
	ContactUs_Address_BR: "Campinas -SP, Rodovia Anhanguera Km 98, Portaria 7, Vl. Boa Vista, Campinas - SP, CEP: 13065-090, treinamento.automotivo@br.bosch.com",
	ConfirmItsMeModal_CompanyDetails_Name: "Nome da Empresa",
	ConfirmItsMeModal_CompanyDetails_Address: "Endereço",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Caixa Postal da Empresa",
	ConfirmItsMeModal_CompanyDetails_Phone: "Telefone",
	ConfirmItsMeModal_CompanyDetails_Email: "E-mail",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Cancelamento da inscrição no curso bem sucedido!",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "A criação da conta falhou. A conta já existe!",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "A atualização da conta falhou. A conta já existe!",
	Footer_ExploreTrainingStories: "Explore todas as nossas histórias de formação",
	Breadcrumb_Home: "Início",
	Breadcrumb_Contact: "Contato",
	Breadcrumb_Stories: "Histórias",
	Breadcrumb_Stories_BoschServiceTraining: "Bosch Service Training",
	Breadcrumb_Stories_ADayInLife: "Um dia na vida de um treinador",
	Breadcrumb_Stories_BoschTrainingBenefits: "Benefícios da formação Bosch",
	Breadcrumb_Stories_TrainingApproach: "Uma abordagem de formação moderna",
	Breadcrumb_Settings: "Configurações",
	Breadcrumb_ShoppingCart: "Carrinho de compras",
	Breadcrumb_Checkout: "Saída",
	Breadcrumb_OrderSummary: "Resumo do pedido",
	Breadcrumb_Exception: "Exceção",
	Breadcrumb_Dashboard: "Painel",
	Breadcrumb_MyCourses: "Os meus cursos",
	Breadcrumb_MyELearning: "O meu e-learning",
	Breadcrumb_CompanyAdministration: "Administração da empresa",
	Breadcrumb_EmployeeManagement: "Gestão de funcionários",
	Breadcrumb_EmployeeAccount: "Conta de funcionário",
	Breadcrumb_EmployeeCreation: "Criação de funcionários",
	Breadcrumb_Administration: "Administração",
	Breadcrumb_EmployeeAdministration: "Administração de funcionários",
	Breadcrumb_WholesalerManagement: "Gestão de Distribuidores",
	Breadcrumb_NewWholesalerAccountCreation: "Criação de nova conta de Distribuidor",
	Breadcrumb_Transactions: "Transações",
	Breadcrumb_MyAccount: "A minha conta",
	Breadcrumb_TrainingCentres: "Centros de treinamentos",
	Breadcrumb_EventCalendar: "Calendário de eventos",
	Breadcrumb_TrainingCourseCatalogue: "Catálogo de Cursos de Treinamento",
	Breadcrumb_RecommendedHotels: "Hotéis recomendados",
	Breadcrumb_CorporateInformation: "Informação corporativa",
	Breadcrumb_LegalNotice: "Aviso Legal",
	Breadcrumb_DataProtectionNotice: "Aviso de proteção de dados",
	Breadcrumb_PrivacyPolicy: "Política de Privacidade",
	Breadcrumb_TermCondition: "Termo e condição",
	Breadcrumb_Terms: "Termos",
	Breadcrumb_PrivacyStatement: "Declaração de privacidade",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Fornecedor",
	Breadcrumb_RegisterAccount: "Registrar Conta",
	Stories_ExploreMore: "Explorar mais",
	CheckOutLayout_ShoppingCartHeader: "Carrinho de compras",
	CheckOutLayout_AddedItemCartMessage: "Adicionou o seguinte ao seu carrinho",
	CheckOutLayout_CheckYourCartMessage: "Verifique o seu carrinho",
	ModernTrainingApproach_DateText: "28-02-2022",
	ADayInLifeofATrainer_DateText: "28-02-2022",
	BoschTrainingBenefits_DateText: "28-02-2022",
	BoschServiceTraining_DateText: "28-02-2022",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Pesquisar funcionários",
	CheckOutLayout_OrderWasNotPlaced_Message: "Lamentamos! O seu pedido não foi realizado",
	CheckOutLayout_RefusalReason: "Motivo da recusa",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "O pagamento falhou. Por favor tente novamente com uma forma de pagamento diferente",
	CheckOutLayout_SubmittingApiFailed_Message: "Falha no envio da API",
	CartItem_ChooseWorkshop_Message: "Por favor, selecione uma oficina para escolher o participante",
	CartItem_ChooseParticipant_Message: "Por favor, escolha o participante para continuar",
	CompanyAdministrationTable_SearchBox_Placeholder: "Pesquisar oficinas",
	CartItem_RemoveCartItem_ConfirmText: "Deseja remover este item do carrinho?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Função",
	AddToCartSuccessModal_Message: "O seu carrinho de compras foi atualizado!",
	AddToCartSuccessModal_ContinueButtonText: "Continue com as compras",
	AddToCartSuccessModal_MyShoppingCartButtonText: "O meu carrinho de compras",
	AddVoucher_Header: "Adicionar voucher",
	AddVoucher_InputPlaceholder: "Introduza o código e aplique",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Infelizmente, não temos nenhum evento agendado no momento. Por favor, registre-se na lista de espera, e iremos informá-lo assim que um evento estiver disponível",
	ADYEN_ERR_2_: "A transação foi recusada.",
	ADYEN_ERR_3_: "Erro de referências",
	ADYEN_ERR_4_: "A transação não foi efetuada devido a um erro ocorrido por parte do adquirente.",
	ADYEN_ERR_5_: "O cartão usado para a transação está bloqueado, portanto, inutilizável.",
	ADYEN_ERR_6_: "O cartão usado para a transação expirou. Portanto, é inutilizável.",
	ADYEN_ERR_7_: "Ocorreu uma incompatibilidade de valor durante o processo de transação.",
	ADYEN_ERR_8_: "O número do cartão especificado está incorreto ou inválido.",
	ADYEN_ERR_9_: "Não é possível entrar em contato com o banco do comprador para autorizar a transação.",
	ADYEN_ERR_10_: "O banco do comprador não suporta ou não permite este tipo de transação.",
	ADYEN_ERR_11_: "A autenticação 3D Secure não foi executada ou não foi executada com sucesso.",
	ADYEN_ERR_12_: "O cartão não tem crédito suficiente para cobrir o valor a pagar. Este problema pode ocorrer devido a um limite de valor a pagar mensalmente no seu cartão. Por favor tente usar outro cartão, como MasterCard, ou entre em contato com o seu banco para redefinir o limite mensal do seu cartão.",
	ADYEN_ERR_14_: "Possível fraude.",
	ADYEN_ERR_15_: "A transação foi cancelada.",
	ADYEN_ERR_16_: "O comprador cancelou a transação antes de concluí-la.",
	ADYEN_ERR_17_: "O PIN especificado está incorreto ou inválido.",
	ADYEN_ERR_18_: "O comprador especificou um PIN incorreto mais de três vezes seguidas.",
	ADYEN_ERR_19_: "Não é possível validar o número PIN especificado.",
	ADYEN_ERR_20_: "Possível fraude.",
	ADYEN_ERR_21_: "A transação não foi enviada corretamente para processamento.",
	ADYEN_ERR_22_: "A verificação de risco sinalizou a transação como fraudulenta (pontuação de risco >= 100); portanto, a operação é cancelada.",
	ADYEN_ERR_23_: "Os seguintes códigos recusados são mapeados para este motivo de recusa: Transação não permitida ao emissor/titular do cartão",
	ADYEN_ERR_24_: "O CVC (código de segurança do cartão) especificado é inválido.",
	ADYEN_ERR_25_: "Os seguintes códigos de recusa são mapeados para este motivo de recusa: Cartão inválido neste país",
	ADYEN_ERR_26_: "R1: Revogação da Ordem de Autorização / \"R3: Revogação de Todas as Ordens de Autorização\" / \"R0: Ordem de Interrupção de Pagamento\"",
	ADYEN_ERR_27_: "Esta resposta mapeia todos os códigos de resposta que não podem ser mapeados de forma fiável. Isto torna mais fácil distinguir recusas genéricas (por exemplo, resposta Mastercard \"05: Não honrar\") de outras mais específicas.",
	ADYEN_ERR_28_: "O valor de levantamento permitido para o cartão do comprador foi excedido.",
	ADYEN_ERR_29_: "O número de levantamentos permitidos para o cartão do comprador foi excedido.",
	ADYEN_ERR_31_: "O emissor relatou a transação como suspeita de fraude.",
	ADYEN_ERR_32_: "Os dados de endereço que o comprador digitou estão incorretos.",
	ADYEN_ERR_33_: "O banco do comprador exige que o comprador insira um PIN online.",
	ADYEN_ERR_34_: "O banco do comprador exige uma conta corrente para concluir a compra.",
	ADYEN_ERR_35_: "O banco do comprador exige uma conta poupança para concluir a compra.",
	ADYEN_ERR_36_: "O banco do comprador exige que o comprador insira um PIN de celular.",
	ADYEN_ERR_37_: "O comprador abandonou a transação depois de tentar um pagamento sem contato e foi solicitado a tentar um método de entrada de cartão diferente (PIN ou banda magnética).",
	ADYEN_ERR_38_: "O emissor recusou a solicitação de isenção de autenticação e exige autenticação para a transação. Tente novamente com o 3D Secure.",
	ADYEN_ERR_39_: "O emissor ou o esquema não conseguiu comunicar o resultado via RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "O nome não deve incluir números e caracteres especiais (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "O nome deve ser menor que 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "O nome não deve incluir números e caracteres especiais (ex: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "O apelido deve ser menor que 75",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "O número do celular deve ser numérico (ex: +12567567...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "O número do celular deve ser menor que 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "O número de telefone deve ser numérico (ex: +12567567...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "O número de telefone deve ser inferior a 45",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "Endereço de e-mail inválido",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "O e-mail deve ser menor que 100 caracteres",
	ConfirmGeolocationModal_ConfirmButton: "Continuar",
	ConfirmGeolocationModal_CancelButton: "Mudar",
	ConfirmGeolocationModal_CurrentCountrySelection: "Sua seleção de país atual é:",
	ConfirmGeolocationModal_AskChangeCountry: "Deseja permanecer neste site ou alterar sua preferência de país?",
	Addtocart_Modal_AddToCartFailTitle: "Falha ao adicionar participante",
	Addtocart_Modal_AlreadyInCart: "Participante já inscrito neste Evento",
	TrainingCatalogue_DateRange: "Intervalo de datas",
	TrainingCatalogue_DateRange_placeholder: "Selecione o filtro de intervalo de datas",
	Dashboard_MyPendingCourses: "Os meus cursos pendentes",
	Dashboard_MyWaitingList: "A minha lista de espera",
	Dashboard_LatestNews: "Últimas notícias",
	SettingsMainPage_EditAccountButton: "Editar conta",
	SettingsMainPage_EditBoschId: "Editar ID Bosch",
	EventInfoView_EventDetailTab: "Detalhes do evento",
	EventInfoView_AssignmentsTab: "Atribuições",
	EventInfoView_EvaluationTab: "Avaliação",
	EventInfoView_AttachmentTab: "Anexos",
	EventInfoView_CourseDescriptionTab: "Descrição",
	EventInfoView_DescriptionTab_General: "Geral",
	EventInfoView_DescriptionTab_PartLabel: "Parte",
	AssignmentTab_Description: "Aqui encontrará todas as informações necessárias para concluir este curso.",
	AssignmentTab_Columns_Download: "Download",
	AssignmentTab_Columns_Upload: "Carregar",
	AssignmentTab_Columns_TaskComplete: "Tarefa concluída",
	EventDetailTab_Location_Message_Video: "Este é um evento online que pode ser acessado através do link \"Iniciar Vídeo\".",
	EventDetailTab_Location_Message_WebBased: "Este é um evento online que pode ser acessado usando o link \"Launch WBT\".",
	EventDetailTab_Location_Message_WebCast: "Este é um evento online que pode ser acessado usando o link \"Inscreva-se agora\".",
	EventDetailTab_Link_Video: "Iniciar vídeo",
	EventDetailTab_Link_Video_Remark: "*O lançamento do vídeo abrirá uma nova janela. Será redirecionado de volta a esta página assim que a janela for fechada.",
	EventDetailTab_Link_WebBased: "Iniciar treinamento",
	EventDetailTab_Link_WebBased_Remark: "*Iniciar treinamento abrirá uma nova janela. Será redirecionado de volta a esta página assim que a janela for fechada.",
	EventDetailTab_Link_WebCast: "Entrar",
	EventDetailTab_Link_WebCast_Remark: "*O link para inscrever-se agora estará ativo 30 minutos antes do início do evento.",
	EventDetailTab_Title_Status: "Estado",
	EventDetailTab_Title_Duration: "Durações (horas)",
	EventDetailTab_Title_Trainers: "Instrutor(es)",
	EventDetailTab_Title_Language: "Língua",
	EventDetailTab_Title_Score: "Pontuação (%)",
	EventDetailTab_Title_Calendar: "Adicionar ao calendário",
	EventDetailTab_Title_Certificate: "Descarregar certificado",
	EventDetailTab_Title_Messages: "Mensagens",
	EventDetailTab_Title_StartDate: "Data de início",
	EventDetailTab_Title_AvailableFrom: "Disponível a partir de",
	EventDetailTab_Title_Time: "Hora",
	EventDetailTab_Title_EndDate: "Data de fim",
	EventDetailTab_Title_ExpiresOn: "Expira em",
	EventDetailTab_Title_Room: "Sala",
	EventDetailTab_Title_Method: "Método",
	EventDetailTab_Title_Link: "Link*",
	EventDetailTab_Title_Location: "Localização",
	AttachmentTab_EmptyScreenDescription: "Não há anexos para este evento.",
	AttachmentTab_Columns_Download: "Descarregar",
	AttachmentTab_Description: "Aqui encontrará algumas informações importantes para ajudá-lo com este curso",
	EvaluationTab_Annotation: "Valorizamos os seus comentários e gostaríamos da sua opinião sobre o nosso trabalho. Por favor, dedique algum tempo para preencher a seguinte avaliação de forma a nos ajudar a melhorar continuamente o nosso serviço. Obrigado.",
	EvaluationTab_Notice: "As classificações são as seguintes",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Por favor, responda a todas as perguntas obrigatórias antes de enviar sua avaliação do evento",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "As suas respostas foram enviadas com sucesso",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "O envio da avaliação falhou",
	TrainingCatalogueLayout_ExportButtonText: "Exportar",
	EvaluationTab_FeedbackHasBeenGiven_Message: "A Avaliação foi concluída ou expirou.",
	EventDetailTab_Link_CopiedTooltip: "Copiado para a área de transferência",
	EventDetailTab_QRCode_ExplanationText_WBT: "Digitalize para iniciar o Treinamento",
	EventDetailTab_QRCode_ExplanationText_Video: "Digitalize para iniciar o vídeo",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Digitalize para iniciar o Webcast",
	Footer_TrainingCoure_Headline: "Cursos de formação fornecidos pela Bosch",
	Footer_TrainingCourse_Content: "Como uma supermarca de renome mundial e com mais de um século de experiência em inovação, a Bosch estabeleceu os padrões globalmente como fornecedora de sistemas para o automóvel, peças e soluções técnicas. Grande parte da nossa força vem das nossas estreitas parcerias com fabricantes de veículos, para os quais projetamos, desenvolvemos e produzimos sistemas e componentes de última geração em todo o mundo. Consequentemente, a Bosch consolidou uma forte história em produtos e suporte de reposição automóvel com um portfólio diversificado de peças, soluções de diagnóstico e serviços de oficina. Aprendizagem e desenvolvimento são chaves seguras para o sucesso e esta filosofia é sustentada por uma ampla gama de cursos de formação, em todas as áreas de tecnologia automóvel, sistemas de veículos e especialização em diagnóstico.",
	SearchField_NoRecordsFound: "Não foram encontrados registros",
	TrainingCatalogue_FreeTextSearch: "Texto livre",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Procurar",
	CheckoutLayout_WholeSalerGroup: "Escolha o grupo de distribuidores",
	CreditInsufficientModal_Message: "Não tem crédito suficiente para usar esta forma de pagamento. Tente novamente com outra forma de pagamento.",
	ShoppingCart_Required_Credits: "Preço total",
	MaintainingInfo_Message1: "O site está em modo de manutenção.",
	MaintainingInfo_Message2: "Voltaremos em breve",
	CountryValidationModal_Message: "Não conseguiu fazer login em [CountryA] porque a sua conta foi criada em [CountryB].",
	CountryValidationModal_ButtonText: "Continuar para [País]",
	EventTable_LoginRequired: "Faça login para se registar",
	TrainingPartTableLayout_Date: "Data",
	TrainingPartTableLayout_StartTime: "Hora de início",
	TrainingPartTableLayout_EndTime: "Hora do fim",
	TrainingPartTableLayout_Duration: "Durações (horas)",
	TrainingPartTableLayout_TrainingMethod: "Metodologia",
	MyCoursesTableContents_ActiveHeader: "Cursos ativos",
	MyCoursesTableContents_ActiveTabHeader: "Ativo",
	MyCoursesTableContents_ScheduledHeader: "Cursos agendados",
	MyCoursesTableContents_ScheduledTabHeader: "Agendado",
	MyCoursesTableContents_OnlineHeader: "Cursos online",
	MyCoursesTableContents_OnlineTabHeader: "Online",
	MyCoursesTableContents_CancelledHeader: "Cursos cancelados",
	MyCoursesTableContents_CancelledTabHeader: "Cancelado",
	OnlineCoursesTable_Title_Progress: "Progresso",
	ScheduledCoursesTable_Title_Date: "Data [dias]",
	CoursesTable_Title_Completed: "Concluído",
	Footer_TrainingProgram: "Treinamentos",
	Breadcrumb_TrainingProgram: "Treinamentos",
	TrainingProgram_Title_Table: "Título",
	TrainingProgram_TrainingProgram: "Programas de Treinamento",
	TrainingProgram_TargetGroup: "Grupo alvo",
	TrainingProgram_MaxLength: "Comprimento Máx.",
	TrainingProgram_Information: "Informação",
	TrainingProgram_Detail: "Detalhes",
	TrainingProgram_Modules: "Módulos",
	TrainingProgram_Course: "Cursos",
	TrainingProgram_PartNumber: "Referência",
	TrainingProgram_Max_Length: "Duração máxima",
	TrainingProgram_Duration: "Duração",
	TrainingProgram_Exam: "Exame",
	TrainingProgram_Assign_Popup: "Inscreva-se no treinamento",
	TrainingProgram_Student: "Aluno",
	TrainingProgram_Assign: "Atribuir",
	TrainingProgram_Popup_Remove_Title: "Cancelar treinamento?",
	TrainingProgram_Description: "Descrição",
	TrainingProgram_Days: "Dias",
	TrainingProgram_Type: "Tipo",
	TrainingProgram_Action: "Ação",
	TrainingProgram_Enrolled: "Você já se inscreveu neste treinamento",
	TrainingProgram_SelfAssign_Enrolled: "Já está atribuído a este treinamento",
	TrainingProgram_Warning: "Por favor registre outro aluno ou selecione um treinamento diferente.",
	TrainingProgram_Workshop: "Oficina",
	TrainingProgram_Durations: "anos",
	TrainingProgram_Duration_DaysLabel: "dias",
	TrainingProgram_HasExam_Yes: "Sim",
	TrainingProgram_HasExam_No: "Não",
	AssignModal_SuccessModal_Header: "Parabéns!",
	AssignModal_SuccessModal_Message: "Foi inscrito neste programa de formação com sucesso.",
	AssignModal_SelfAssign_SuccessModal_Message: "Foi atribuído com sucesso para o programa de formação.",
	Dashboard_MyTraningProgram: "Meu treinamento",
	Dashboard_MyTraningProgram_Title: "Título",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Visão",
	Dashboard_MyTraningProgram_Register: "Registro",
	Dashboard_MyTraningProgram_Records: "Histórico de registro",
	Dashboard_MyTrainingProgram_In_Progress: "Em curso",
	Dashboard_MyTrainingProgram_Accredited: "Credenciado",
	Dashboard_Program_Modal_Withdraw: "Retirar",
	Dashboard_Program_Modal_Grade: "Avaliação",
	Dashboard_MyTrainingProgram_Registered: "Registrado",
	Dashboard_MyTrainingProgram_Completedd: "Concluído",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Sim",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "Não",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Por favor tenha em atenção que desistir significa que desistirá do programa de formação completo, não apenas de um curso de formação individual.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "Ainda deseja retirar seu cadastro deste treinamento?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Pendente",
	WithdrawProgramModal_Withdraw_Success_Message: "Você foi retirado deste treinamento!",
	Common_Warning_Title: "Aviso!",
	Common_Error_Title: "Ups, algo correu mal!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Adicionar ao carrinho",
	AddEventFromCatalogToCartModal_HoursText: "horas",
	AddElearningToCartSuccessModal_Message: "foi adicionado ao seu carrinho de compras!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "O participante selecionado foi registrado neste Curso ou o Curso já foi adicionado ao carrinho.",
	EventDetailTab_SubjectForChange_Message: "Sujeito a alteração",
	Dashboard_MyTrainingProgram_Status_Failed: "Reprovado",
	Dashboard_MyTrainingProgram_Status_Passed: "Aprovado",
	Dashboard_MyTrainingProgram_Status_Merit: "Mérito",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinção",
	Dashboard_Employee_Courses: "Cursos",
	Dashboard_Employee_Program: "Programa de treinamento",
	Dashboard_Employee_WaitList: "Lista de espera",
	Dashboard_Employee_Account: "Conta",
	Course_Price_Free: "Livre",
	Dashboard_Employee_Account_Update_Success_Message: "Você atualizou com sucesso as informações do seu funcionário!",
	Dashboard_Supplier: "Fornecedor",
	Event_Detail_Comment: "No momento não há mensagens",
	DownLoad_PDF: "Download PDF",
	TrainingCatalogue_Currency: "Moeda",
	My_Wallet: "Minha carteira",
	Wallet: "Carteira",
	Credits_Balance: "Créditos disponíveis",
	MyFullCalendar_Today: "Hoje",
	Header_Available_Languages: "Linguagens disponíveis",
	Header_CurrentRegion: "Você está em",
	Header_ChangeRegions: "Você quer mudar de país?",
	Header_Anonymous: "Treinamentos",
	Header_Anonymous_Login: "Login/Registrar-se",
	Header_RegisterCourse: "Entre em contato",
	Homepage_Start: "Iniciar",
	Homepage_Login: "Entrar",
	MainPage_Title: "A solução definitiva para oficinas",
	MainPage_Content: "A sua oficina, a sua escolha",
	MainPage_ImageTitle: "Bosch Automotive \n Soluções de formação",
	MainPage_IntroContent: "Permite que a oficina moderna trabalhe de forma profissional, confiável e eficiente em procedimentos de diagnóstico, solução de problemas, reparação e manutenção de todos os tipos de veículos.",
	BoschNews_TrainingNews: "Notícias de formação",
	Service_Assist_Workshop: "Melhore a sua experiência com o Workshop Service Assist",
	Enhance_Title_CDM3_v2: "O que posso fazer com a aplicação?",
	Enhance_Body_CDM3_Item_1: "Inscreva-se em cursos e mantenha o seu percurso de aprendizagem",
	Enhance_Body_CDM3_Item_2: "Faça download de material de formação e dos certificados de formação",
	Enhance_Body_CDM3_Item_3: "Participe em cursos de eLearning",
	Enhance_Body_CDM3_Item_4: "Acesse a vários serviços automotivos na aplicação móvel",
	Enhance_Body_CDM3_Item_5: "Transmissão de vídeo ao vivo com Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Ferramenta gratuita para scanarização automática de veículos",
	Training_Stories: "Histórias de formação",
	SettingsMyAccount_JoinCompanyButton: "Junte-se à empresa",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Junte-se à empresa",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Por favor, insira o seu código de ativação abaixo",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "Observação! Por favor entre em contato com o administrador da empresa para obter um código de ativação.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "A sua ativação foi processada com sucesso e a sua conta agora está vinculada à sua empresa.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Clique em Continuar para entrar no site.",
	ContinueButton: "Continuar",
	WelcomeBatsModal_Title: "Sua conta está a ser revista!",
	WelcomeBatsModal_ActivationLink: "Clique aqui se tiver um Código de Ativação para Entrar numa Empresa!",
	WelcomeBatsModal_ActivationLinkNotes: "Observação! Por favor entre em contato com o administrador da empresa para obter um código de ativação.",
	AccountInReviewModal_Description_Paragraph1: "Obrigado por registar uma conta pessoal na Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "A nossa dedicada equipa de formação está a rever e a configurar a sua conta. Receberá a confirmação final por e-mail, assim que a sua conta for configurada com sucesso.",
	AccountInReviewModal_CloseButton: "OK, deixe-me entrar!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "Não foi possível validar o seu código de ativação e endereço de e-mail.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Tente novamente ou entre em contato com o administrador da empresa para obter assistência.",
	TryAgain_Button: "Tente novamente",
	Error_Title: "Ops, alguma coisa correu mal!",
	SettingsMyProfileActivationSuccessModal_Title: "Sucesso!",
	AccountInReviewModal_Description_Paragraph3: "Enquanto isso, sinta-se à vontade para navegar no nosso site com acesso limitado.",
	AccountInReviewModal_Description_Paragraph4: "Se tiver alguma dúvida ou se a sua conta não for processada em 48 horas, exceto fins de semana e feriados, entre em contato connosco [aqui]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Algumas orientações para o ajudar...",
	Support_FAQ_Headline: "Perguntas frequentes",
	Support_FAQs_section1_title: "Geral",
	Support_FAQs_section1_question1: "O que é o Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "A Bosch Training Solutions é uma plataforma que fornece uma visão abrangente sobre formação técnica e permite agendar e gerir formações.",
	Support_FAQs_section1_question2: "Posso usar a minha conta existente da Bosch (singleKey ID) para fazer login no Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Sim, pode aceder com todas as contas criadas com \"My Bosch ID\".",
	Support_FAQs_section1_question3: "Posso usar uma conta criada para o Bosch Training Solutions noutros serviços ou aplicações da Bosch?",
	Support_FAQs_section1_question3_answer1: "Sim, também pode usar a sua ID pessoal da Bosch para uma variedade de outras aplicações da Bosch - tanto profissional como privada, como e-bike ou smart home.",
	Support_FAQs_section2_title: "Formação",
	Support_FAQs_section2_question1: "Como posso marcar uma formação?",
	Support_FAQs_section2_question1_answer1: "Faça login com a sua conta existente OU registe uma conta no portal. Vá para \"Serviços de Formação\" OU \"Calendário de Eventos\" selecione uma formação e clique no botão adicionar ao carrinho.",
	Support_FAQs_section2_question2: "Nenhuma data de formação adequada está disponível. O que posso fazer?",
	Support_FAQs_section2_question2_answer1: "Tem a possibilidade de colocar-se numa lista de espera \"geral\" (sem marcar data) e a nossa equipa irá informá-lo assim que uma nova formação estiver disponível.",
	Support_FAQs_section2_question3: "Onde posso encontrar uma visão geral dos meus cursos agendados?",
	Support_FAQs_section2_question3_answer1: "Pode encontrar uma visão geral completa de todos os cursos agendados e concluídos após o login no seu painel.",
	Support_FAQs_section3_title: "Configurações",
	Support_FAQs_section3_question1: "Como posso alterar o meu e-mail e password?",
	Support_FAQs_section3_question1_answer1: "Precisa fazer o login no portal e mudar para o seu perfil pessoal. (Dentro do seu Painel pode encontrar as informações do seu perfil ou se clicar no seguinte botão no cabeçalho",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Aí tem a possibilidade de alterar os detalhes do seu Bosch ID",
	Support_FAQs_section3_question2: "O que posso fazer quando me esquecer da minha password?",
	Support_FAQs_section3_question2_answer1: "Por favor, use o botão de login/registo e preencha o seu endereço de e-mail",
	Support_FAQs_section3_question2_answer2: "Se clicar em continuar tem a possibilidade de clicar em \"Esqueci a password\"",
	Support_FAQs_section3_question3: "Como configurar uma conta de oficina?",
	Support_FAQs_section3_question3_answer1: "Se desejar obter uma função de oficina, por favor contacte a Administração de Formação local, por exemplo, pode usar o formulário de contacto no site.",
	LandingPage_Header_Title: "Bosch Automotive Training Solutions",
	LandingPage_ChooseCountry_Title: "Escolha o seu país/região",
	MaintenanceBanner_Message_1: "O Portal Bosch Automotive Training Solutions será atualizado em [maintenance_schedule]. A atualização deve demorar 30 minutos. O Portal não estará disponível durante a atualização.",
	MaintenanceBanner_Message_2: "\nPara mais assistência, entre em contacto connosco por e-mail [support_email]",
	MaintenancePage_Message_1: "O Portal Bosch Automotive Training Solutions está em atualização",
	MaintenancePage_Message_2: "Esperamos que o Portal esteja novamente disponível em [maintenance_online_date] às [maintenance_online_time]",
	MenuHeader_Support: "Suporte",
	Course_Detail_No_Prerequisites: "Sem pré-requisitos",
	TableCell_Hours: "Horas",
	Dashboard_MenuItem_Dashboard: "Painel",
	Dashboard_MenuItem_Curricula: "Currículos",
	Dashboard_MenuItem_Certificates: "Certificados",
	Dashboard_MenuItem_Team: "Equipa",
	Dashboard_MenuItem_Workshops: "Oficinas",
	Dashboard_MenuItem_External: "Externo",
	Dashboard_MenuItem_Wallet: "Carteira",
	Dashboard_MenuItem_Profile: "Perfil",
	Dashboard_Headline_YourUpcomingCourses: "Os seus próximos cursos",
	Dashboard_Headline_YourELearningCourses: "Os seus cursos de eLearning",
	Dashboard_Headline_TeamOverview: "Visão geral da equipa",
	Dashboard_Headline_YourOverview: "A sua visão geral",
	Dashboard_Headline_YourWishlist: "A sua lista de desejos",
	Dashboard_Headline_ActivePrograms: "Programas ativos",
	Dashboard_Headline_CompletedPrograms: "Programas concluídos",
	Dashboard_Headline_YourCertificates: "Os seus certificados",
	Dashboard_Headline_Transactions: "Transações",
	Dashboard_Table_NoRecordsToDisplay: "Nenhum registo para exibir",
	Dashboard_TableColumn_Location_Online: "Online",
	Dashboard_TableColumn_Duration_Hours: "horas",
	Dashboard_ELearningCourses_TableHeader_Title: "Título",
	Dashboard_ELearningCourses_TableHeader_Duration: "Duração (horas)",
	Dashboard_ELearningCourses_ActionButton_View: "Visualizar",
	Dashboard_UpcomingCourses_TableHeader_Title: "Título",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Data [dias]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Localização",
	Dashboard_UpcomingCourses_ActionButton_View: "Visualizar",
	Dashboard_YourOverview_TableHeader_Title: "Título",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Data / Duração",
	Dashboard_YourOverview_TableHeader_Location: "Localização",
	Dashboard_YourOverview_TableHeader_Status: "Estado",
	Dashboard_YourOverview_ActionButton_View: "Visualizar",
	Dashboard_YourWishlist_TableHeader_Title: "Título",
	Dashboard_YourWishlist_TableHeader_Action: "Ação",
	Dashboard_YourWishlist_ViewAllRecords: "Ver todos os registos",
	Dashboard_TeamOverview_TableHeader_Employee: "Funcionário",
	Dashboard_TeamOverview_TableHeader_Title: "Título",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Data / Duração",
	Dashboard_TeamOverview_TableHeader_Location: "Localização",
	Dashboard_TeamOverview_TableHeader_Status: "Estado",
	Dashboard_TeamOverview_ActionButton_View: "Visualizar",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Procurar",
	Dashboard_ActiveProgram_TableHeader_Title: "Título",
	Dashboard_ActiveProgram_TableHeader_Status: "Estado",
	Dashboard_ActiveProgram_ActionButton_View: "Visualizar",
	Dashboard_CompletedProgram_TableHeader_Title: "Título",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Visualizar",
	Dashboard_Certificates_TableHeader_Title: "Título",
	Dashboard_Certificates_TableHeader_Date: "Data",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Ação",
	Dashboard_Certificates_ActionButton_Download: "Download",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Número da Fatura",
	Dashboard_Transactions_TableHeader_Description: "Descrição",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Forma de pagamento",
	Dashboard_Transactions_TableHeader_Date: "Data",
	Dashboard_Transactions_TableHeader_Amount: "Quantia",
	Dashboard_Transactions_ActionButton_Download: "Download",
	Breadcrumb_MyTraining: "A minha Formação",
	Breadcrumb_MyTraining_CourseDetails: "Detalhes do curso",
	Breadcrumb_MyTraining_Curricula: "Currículos",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Detalhes do programa de formação",
	Breadcrumb_MyTraining_Team: "Equipa",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Ver funcionário",
	MyTraining_Headerbar_CourseDetails: "Detalhes do curso",
	MyTraining_Headerbar_TrainingProgramDetails: "Detalhes do programa de formação",
	TrainingProgramDetails_Label_Status: "Estado",
	TrainingProgramDetails_Label_OverallProgress: "Progresso geral",
	TrainingProgramDetails_ProgramItems_Headline: "Itens do programa",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Título",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Estado",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Nota",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Data",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Visualizar",
	TrainingProgramDetails_Status_InProgress: "Em Progresso",
	TrainingProgramDetails_Status_Completed: "Concluído",
	CourseDetails_Label_Status: "Estado",
	CourseDetails_Label_Duration: "Duração",
	CourseDetails_Label_Trainer: "Formador",
	CourseDetails_Label_Language: "Língua",
	CourseDetails_Label_Score: "Classificação:",
	CourseDetails_Label_StartDate: "Data de início",
	CourseDetails_Label_EndDate: "Data final",
	CourseDetails_Label_Venue: "Local",
	CourseDetails_Label_Address: "Endereço",
	CourseDetails_Label_Grade: "Nota",
	CourseDetails_Action_Withdraw: "Retirar",
	CourseDetails_Action_Evaluate: "Avaliar curso",
	CourseDetails_Action_Evaluate_Description: "Por favor, avalie este curso para gerar o seu certificado!",
	CourseDetails_Action_DownloadCertificate: "Download certificado",
	CourseDetails_Action_JoinWebcast: "Participe no webcast",
	CourseDetails_Action_StartCourse: "Iniciar curso",
	CourseDetails_Action_RestartCourse: "Reiniciar Curso",
	CourseDetails_Action_RepeatCourse_Description: "Por favor, repita e passe neste curso para fazer o download do  seu certificado!",
	CourseDetails_PartSpecificDetails_Headline: "Detalhes específicos da parte",
	CourseDetails_PartSpecificDetails_Tab_Part: "Parte",
	CourseDetails_PartSpecificDetails_Description: "Descrição",
	CourseDetails_PartSpecificDetails_StartDate: "Data de início",
	CourseDetails_PartSpecificDetails_EndDate: "Data de fim",
	CourseDetails_PartSpecificDetails_StartTime: "Hora de início",
	CourseDetails_PartSpecificDetails_EndTime: "Hora de fim",
	CourseDetails_PartSpecificDetails_Method: "Método",
	Dashboard_Team_TableHeader_Name: "Nome",
	Dashboard_Team_TableHeader_Role: "Função",
	Dashboard_Team_TableHeader_Email: "E-mail",
	Dashboard_Team_TableHeader_PersonalAccount: "Conta pessoal",
	Dashboard_Team_ActionButton_Select: "Selecionar",
	Dashboard_Team_PersonalAccount_Activated: "Ativado",
	Dashboard_Team_PersonalAccount_NotActivated: "Não ativado",
	Dashboard_Team_ActionButton_AddMember: "Adicionar membro",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Procurar",
	Dashboard_External_TableHeader_Name: "Nome",
	Dashboard_External_ActionButton_AddNew: "Adicionar novo",
	Dashboard_External_Headline: "Acesso externo aprovado",
	Dashboard_Workshops_Headline: "Oficinas",
	Dashboard_Workshops_TableHeader_Company: "Empresa",
	Dashboard_Workshops_TableHeader_Address: "Endereço",
	Dashboard_Workshops_TableHeader_Postcode: "Código Postal",
	Dashboard_Workshops_TableHeader_City: "Cidade",
	Dashboard_Workshops_ActionButton_Select: "Selecionar",
	Wallet_ServiceCredits: "Créditos de serviço",
	Wallet_Headline_CreditBalance: "Balanço de crédito",
	Wallet_Headline_CreditActivity: "Atividade de crédito",
	Wallet_Headline_BillingTransactions: "Transações de faturamento",
	Wallet_BillingTransaction_TableHeader_Order: "Ordem",
	Wallet_BillingTransaction_TableHeader_Date: "Data",
	Wallet_BillingTransaction_TableHeader_Amount: "Quantia",
	Wallet_BillingTransaction_TableHeader_Action: "Ação",
	Wallet_CreditActivity_TableHeader_Date: "Data",
	Wallet_CreditActivity_TableHeader_Description: "Descrição",
	Wallet_CreditActivity_TableHeader_User: "Utilizador",
	Wallet_CreditActivity_TableHeader_Amount: "Quantia",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, todos os direitos reservados",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Segurança do produto (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Inovações, patentes e licenças",
	FooterAdditionalLinks_Navigation_Logistics: "Compras e logística",
	MenuHeader_MyTraining: "A Minha Formação",
	MenuHeader_TrainingServices: "Serviços de Formação",
	MenuHeader_OurLocations: "As nossas localizações",
	MenuHeader_ContactsUs: "Contacte-nos",
	MenuHeader_Logout: "Sair",
	MenuHeader_FAQ: "FAQ",
	MyProfile_MyBoschId: "O meu Bosch Id",
	MyProfile_AccountDeletion: "Apagar Conta",
	MyProfile_Edit: "Alterar e-mail ou editar password",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Editar perfil de usuário",
	BackButton: "Voltar",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Aguardamos o seu telefonema e teremos todo o prazer em atendê-lo. Estamos disponíveis 24 horas, 7 dias por semana.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Insira os caracteres corretos, conforme indicado na caixa acima.",
	ContactUs_Email_MandatoryNotice_V3: "Preencha todos os campos obrigatórios.",
	ContactUs_Email_SendButton: "Enviar",
	Breadcrumb_TrainingCenters: "Centros de Formação",
	Training_Centres_Book_Btn: "Inscreva-se num curso",
	Training_centres_booking_code: "Código de inscrição",
	Training_centers_header_bar_title: "Os nossos Centros de Formação",
	Training_centers_filter_item: "Centro",
	TeamEdit_PersonalAccountToolTop: "Ativar esta opção permite que o funcionário tenha acesso a este site. O usuário poderá criar uma conta pessoal e fazer parte da vossa conta corporativa. Uma vez ativa, poderá enviar um convite com um código de ativação para o funcionário. O convite será enviado para o endereço de e-mail.",
	TeamEdit_Update: "Ativar a opção de administrador da empresa permite automaticamente a esse funcionário o acesso total à sua conta corporativa. A ativação dessa opção requer que o usuário saia e volte ao site, se o usuário já estiver conectado.",
	TeamEdit_PersonalAccount: "Permitir conta web pessoal da Bosch Training Solutions",
	TeamEdit_SendInvitation: "Enviar convite",
	TeamEdit_Deactivate: "Desativar",
	TeamEdit_Telephone: "Telefone",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "Telemóvel deve ser números",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "Telefone deve ser números",
	TeamEdit_Activate: "Ativo",
	TeamEdit_NotActivated: "Não ativar",
	TeamEdit_StatusPopup: "O estado será mostrado como 'Ativo' se o usuário tiver criado uma conta pessoal com sucesso. Se o estado for definido como ‘Não ativo’, significa que o usuário não concluiu o seu convite.",
	Training_Catalog_Courses: "Cursos",
	Training_Catalog_Course: "Curso",
	Reset_Filter: "Redefinir filtro",
	TrainingCatalogue_CourseCatalogue: "Catálogo de cursos",
	CheckoutHeader_CartItemCount_Text: "Você tem [count] item no seu carrinho de compras",
	CheckoutHeader_CartItemsCount_Text: "Você tem [count] itens no seu carrinho de compras",
	ShoppingCart_PriceItem: "Preço do item",
	ShoppingCartItemParticipants_AddParticipant_Button: "Adicionar participantes",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Impostos",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Inclui IVA de [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Métodos de pagamento online disponíveis",
	ShoppingCartItemRow_VenueFeeLabel: "Taxa do local [fee_amount] por participante",
	ShoppingCartItemRow_OnlineLabel: "Online",
	AddParticipantsModal_Title: "Adicionar participante",
	AddParticipantsModal_SelectEmployeeLabel: "Adicionar Participante",
	AddParticipantsModal_SelectWorkshopLabel: "Selecione a Oficina",
	ShoppingCartTotalPrice_ContinueButtonText: "Continuar para finalizar a compra",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "O participante não foi adicionado!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Tem um ou mais cursos no seu carrinho de compras sem participante.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Por favor adicione um participante para continuar a finalização da compra.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Editar participantes ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nome",
	AddParticipantsModal_ParticipantTable_Email: "E-mail",
	AddParticipantsModal_ParticipantTable_Action: "Ação",
	AddParticipantsModal_ParticipantTable_NoParticipant: "Nenhum participante…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Endereço de e-mail não configurado no participante selecionado",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "O participante selecionado não possui um endereço de e-mail configurado no seu perfil. O endereço de e-mail é obrigatório para se inscrever num curso de formação.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Adicione o endereço de e-mail para continuar ou cancele para selecionar outro participante.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Adicionar e-mail",
	CancelButton: "Cancelar",
	AddParticipantsModal_AddEmployeeButtonText: "Adicionar funcionário",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Está prestes a apagar um curso do seu carrinho",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Está a tentar remover um curso do seu carrinho com participantes adicionados.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Clique em \"Continuar\" se desejar prosseguir ou clique em \"Cancelar\".",
	ShoppingCartItemHeader_Participants: "Participante(s)",
	ShoppingCartItemHeader_ParticipantPrice: "Preço por lugar",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Mostrar em [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Mostrar em créditos",
	ShoppingCart_CreditsPriceUnit: "Créditos",
	CheckOutHeader_Step1_Question: "Como deseja efetuar o pagamento?",
	CheckOutHeader_Step2_Question: "Reveja o seu pedido",
	CheckOutHeader_Step3_Question: "Complete o seu pedido",
	CheckOutHeader_Step4_Question: "A sua inscrição foi efetuada com sucesso!",
	CheckOutHeader_Step_Payment: "Pagamento",
	CheckOutHeader_Step_Review: "Revisão",
	CheckOutHeader_Step_Place_Order: "Faça o Pedido",
	CheckOutRightPanel_Review_Order_Btn: "Reveja o pedido",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Faça o Pedido",
	CheckoutRightPanelContent_OrderSummary_Title: "Resumo do pedido",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Editar o carrinho de compras",
	ShoppingCart_Participants: "Participantes",
	CheckOutStepPayment_Payment_Methods_Title: "Selecione o método de pagamento",
	CheckOutStepPayment_Credit_Title: "Crédito ou débito",
	CheckOutStepPayment_Wholesaler_Title: "Distribuidor",
	CheckOutStepPayment_ServiceCredit_Title: "Créditos de serviço",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Tem [available_credit] Créditos",
	CheckOutStepPayment_BoschAccount_Title: "Conta Bosch",
	Checkout_Second_PaymentMethod_Headline: "Forma de pagamento",
	CheckOutStepReview_Edit: "Editar",
	CheckOutStepReview_Terms_Label: "Termos, condições e política de cancelamento",
	CheckOutStepReview_Terms_Text: "Li, compreendi e concordo com os [termos, condições e política de cancelamento] de venda.",
	SelectSecondPaymentMethodModal_Title: "Selecione o método de pagamento secundário",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Pagar [amount]",
	StepPaymentWholesaler_Title: "Selecione o distribuidor",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Aceita termos e Condições",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Por favor, leia e aceite nossos termos, condições e política de cancelamento antes de continuar.",
	CheckoutDescription_ThankYou: "Obrigado por enviar um pedido na Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Nossos bits e bytes já começaram a processar o seu pedido. Enquanto isso, mantenha o seu motor parado.",
	CheckoutDesctiption_Text: "Iremos enviar-lhe uma confirmação final por e-mail, incluindo todos os detalhes da sua inscrição.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Está prestes a remover a oficina selecionada",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Ao remover a oficina selecionada, todos os participantes adicionados serão redefinidos. Esta alteração afetará todos os cursos no seu carrinho de compras.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Clique em \"Continuar\" se desejar continuar ou clique em \"Cancelar\" para interromper esta ação.",
	CheckOutStepPayment_Insufficient_Credit_Message: "Tem créditos insuficientes. O seu saldo de crédito é [available_credit] créditos, mas precisa de [cart_total_credits] créditos para continuar a finalização da compra. Por favor selecione outro método de pagamento ou remova itens do seu carrinho de compras.",
	Training_centers_filter_items: "Centros",
	Training_Programs_Filter_Item: "Programas",
	Training_Program_Filter_Item: "Programa",
	Training_Program_Year: "Ano(s)",
	Training_Program_Detail_Title: "Programas de Cursos de Formação",
	AccountButton_Workshops_MenuItem: "Oficinas",
	AccountButton_Wallet_MenuItem: "Carteira",
	Header_MyBoschProfile_Header: "Perfil MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Disponível",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Adicionar/editar participante",
	ShoppingCart_CreditPriceUnit: "Crédito",
	Common_IncludedVAT: "Inclui IVA",
	TrainingCatalogue_Length: "Comprimento",
	TrainingCatalogue_Method: "Método",
	Course_Price_Credits: "Créditos",
	Catalogue_Grid_Register_Button: "Registo",
	Catalogue_Credit_Tilte: "Clique aqui para ver os preços em Créditos",
	Catalogue_Price_Tilte: "Clique aqui para ver os preços em EUR",
	Catalogue_Grid_Vat: "Sem IVA",
	EventCalendar_Seats: "Lugares",
	MenuHeader_Calendar: "Calendário",
	MenuHeader_Catalog: "Catálogo",
	TableCell_Minutes: "Minutos",
	ListFilter_NoResult: "Nenhum resultado",
	Course_Description: "Descrição do curso",
	Training_course_detail: "Noções básicas de redes sociais",
	Training_course_detail_add_waiting: "Adicionar à lista de espera geral",
	Register_now_btn: "Registar agora!",
	Register_now_btn_add_event_to_cart: "Registar agora!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Ver todos os registos",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Ver menos",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Ver todos os registos",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Ver menos",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Ver todos os registos",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Ver menos",
	TeamCreate_TitleError: "O título é obrigatório",
	Dashboard_Headerbar_BookCourse: "Registar em curso",
	Dashboard_Headerbar_ExitProxy: "Sair do proxy",
	Dashboard_Headerbar_EvaluateCourse: "Avaliar curso",
	Dashboard_Headerbar_EvaluateCourse_Note: "Por favor avalie este curso para gerar o seu certificado!",
	Dashboard_Headerbar_DownloadCertificate: "Fazer download do certificado",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Cancelar",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Cancelar",
	Dashboard_Headerbar_WelcomeMessage: "Bem-vindo ao seu painel de aprendizagem",
	Dashboard_Headerbar_ProxingAs: "Proxy de",
	Dashboard_TrainingProgram_Status_Completed: "Concluído",
	Dashboard_TrainingProgram_Status_InProgress: "Em progresso",
	External_SelectWholesalerModal_Title: "Adicionar acesso de usuário externo",
	External_SelectWholesalerModal_Company_Label: "Empresa",
	External_SelectWholesalerModal_Outlet_Label: "Saída",
	External_SelectWholesalerModal_Company_Placeholder: "Por favor selecione",
	External_SelectWholesalerModal_Outlet_Placeholder: "Por favor selecione",
	External_SelectWholesalerModal_ApplyButton: "Aplicar",
	External_SelectWholesalerModal_CancelButton: "Cancelar",
	External_RemoveWholesalerModal_ConfirmLabel: "Confirmação",
	External_SelectWholesalerModal_ConfirmMessage: "Tem certeza de que deseja remover este distribuidor?",
	External_SelectWholesalerModal_YesButton: "Sim",
	External_SelectWholesalerModal_NoButton: "Não",
	Training_program_team_member: "Membro da equipe",
	Training_program_assign_training_program: "Atribuir programa de formação",
	Assign_modal_student_confirmation_text1: "Está prestes a atribuir [Training Program Name] à sua jornada de aprendizagem.",
	Assign_modal_student_confirmation_text2: "Gostaria de continuar?",
	MyFullCalendar_Jan: "Janeiro",
	MyFullCalendar_Feb: "Fevereiro",
	MyFullCalendar_Mar: "Março",
	MyFullCalendar_Apr: "Abril",
	MyFullCalendar_May: "Maio",
	MyFullCalendar_Jun: "Junho",
	MyFullCalendar_Jul: "Julho",
	MyFullCalendar_Aug: "Agosto",
	MyFullCalendar_Sep: "Setembro",
	MyFullCalendar_Oct: "Outubro",
	MyFullCalendar_Nov: "Novembro",
	MyFullCalendar_Dec: "Dezembro",
	MenuHeader_CourseCatalog: "Catálogo de cursos",
	MenuHeader_Event: "Calendário de eventos",
	MenuHeader_Program: "Programa de Formação",
	MenuHeader_Services: "Serviços de formação",
	MenuHeader_Log_out: "Sair",
	Filter_Location: "Localização",
	TeamEdit_UpgradeToCompanyAdministrator: "Atualizar para administrador da empresa",
	TeamEdit_PersonalWebAccountStatusIs: "O estado da conta web pessoal é",
	Header_CreateNewEmployee: "Criar novo funcionário",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "Não há vagas disponíveis!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "Não há vagas disponíveis no evento selecionado.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Por favor selecione outro evento ou remova um participante já adicionado para continuar.",
	Common_SuccessModal_Title: "Parabéns!",
	TrainingProgramDetails_Merit_StatusText: "Mérito",
	TrainingProgramDetails_Resit_StatusText: "Voltar a fazer o exame",
	EmployeeProfile_UserExistErrorMessage: "A conta já existe!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "O pagamento falhou. Por favor tente novamente ou use outro método de pagamento.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "Desativou com sucesso o funcionário selecionado!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "Guardado!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "As suas alterações foram guardadas.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "O cancelamento não é possível em cursos de eLearning. Por favor entre em contato connosco caso sejam necessários mais detalhes.",
	Dashboard_Team_PersonalAccount_Pending: "Pendente",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "Funcionário criado!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "A criação do funcionário foi concluída com sucesso.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Essa configuração permite que você restrinja um funcionário de se registrar em eventos de treinamento. Somente os administradores da empresa poderão inscrever o funcionário em eventos de treinamento quando esta configuração estiver desmarcada.",
	Training_catalog_sort_by: "Ordenar por",
	CourseDetail_EvaluationRestriction_Title: "Avaliação de treinamento não é possível!",
	CourseDetail_EvaluationRestriction_Message: "As avaliações do treinamento só podem ser submetidas pelo próprio aluno. \nInstrua o aluno a fazer login no Bosch Automotive Training Solutions e concluir a avaliação do treinamento.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Se o aluno ainda não tiver uma conta, um convite pode ser compartilhado nas configurações de perfil do membro da equipe.",
	Breadcrumb_Download_Page: "Download",
	Download_Table_Category_Cell: "Categoria",
	Download_Table_Title_Cell: "Título",
	Download_File_Filter_Item: "Ficheiro",
	Download_File_Filter_Items: "Ficheiros",
	Download_Header: "Área de download",
	DownloadArea_Button_Download: "Download",
	Popup_Title_Error: "Erro",
	Popup_Title_Success: "Sucesso",
	Popup_Title_Info: "Informações",
	Popup_Title_Warning: "Aviso!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Potenciais inscrições em cursos já existentes, não serão canceladas, e deverão ser canceladas individualmente.",
	TrainingProgram_PDF_Export: "Exportação de PDF",
	TrainingProgram_PDF_Export_Participant: "Participante:",
	TrainingProgram_PDF_Export_Status: "Status:",
	TrainingProgram_PDF_Export_Progress: "Progresso geral:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Título da formação",
	TrainingProgram_PDF_Export_TableHeader_Status: "Status",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Nota",
	TrainingProgram_PDF_Export_TableHeader_Date: "Data",
	AccountButton_CompanyChange_MenuItem: "Mudança de empresa",
	SelectCompanyModal_Title: "Selecione a empresa",
	CloseButton: "Fechar",
	Change_Button: "Mudança",
	ChangeManagerModal_Content: "Selecione o usuário",
	ChangeCompanyModal_HeadOffice: "(Sede)",
	SelectCompanyModal_Content: "Por favor selecione Empresa para fazer login…",
	AlertSuccess_Title: "Sucesso",
	CheckOutStepPayment_SecondaryPayment_Title: "Selecione a forma de pagamento secundária",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Adicionar forma de pagamento secundária",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Adicione a forma de pagamento secundária abaixo ou selecione outra forma de pagamento acima.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "Não tem créditos de serviço suficientes na sua conta para pagar pelos itens do carrinho. Para continuar usando o método de pagamento selecionado, deverá pagar a diferença. A diferença é [difference_amount] (sem IVA).",
	ShoppingCartTotalPrice_TotalInCredit: "Preço total em créditos",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Os seus créditos disponíveis",
	ShoppingCartTotalPrice_YourDifference: "Diferença",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal sem IVA",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal com IVA",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Método de pagamento secundário",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Editar forma de pagamento",
	Checkout_Second_PaymentMethod: "Segunda forma de pagamento",
	Breadcrumb_TrainingCourseDetails: "Detalhes do curso de formação",
	DownloadArea_FailedToDownload_ErrorMessage: "Não foi possível fazer o downlod do documento. Por favor tente novamente ou entre em contacto com o administrador da sua empresa para obter assistência.",
	DownloadArea_FileNotAvailable_ErrorMessage: "O arquivo para download não está disponível. Por favor tente novamente ou entre em contacto com o administrador da sua empresa para obter assistência.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "É proibido fazer o download do ficheiro. Por favor tente novamente ou entre em contacto com o administrador da sua empresa para obter assistência.",
	SSO_Redirecting_Message: "Você está redirecionando para Bosch Training",
	SSO_Redirecting_ParameterErrorMessage: "País e/ou idioma não são especificados. Tente novamente ou entre em contato com o administrador da empresa para obter assistência.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Solicitação(ões) de inscrição não aceita(s).",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Isso pode ser devido ao fato de estar dentro do limite de dias de inscrição do curso para um ou mais cursos. Utilize a área 'Contacte-nos' se tiver alguma dúvida ou pedido.",
	TimeSlotPicker_DateFormat_Title: "Formato de data",
	UpdateAccountSettingsConfirmationModal_Title: "Você está prestes a alterar o formato da data!",
	UpdateAccountSettingsConfirmationModal_Message1: "O formato da data será exibido de acordo com sua seleção e poderá ser alterado a qualquer momento.",
	UpdateAccountSettingsConfirmationModal_Message2: "Você deseja continuar?",
	ProfileSummary_Settings_Title: "Configurações",
	UpdateAccountSettings_DateFormat_Title: "Configurações de formato de data",
	UpdateAccountSettings_DateFormat_ErrorMessage: "Não foi possível atualizar o formato da data.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "A configuração do formato de data foi atualizada com sucesso."
};