import LoadableComponent from "../../Common-components/loadable/index";
import { RouteConfigure } from "../../models/Route";
import {
  HOME_SHARED_ROUTE,
  TRAINING_COURSE_CATALOGUE_SHARED_ROUTE,
  COURSE_DETAIL_SHARED_ROUTE,
  TRAINING_COURSE_DETAIL_SHARED_ROUTE,
  EVENT_CALENDAR_SHARED_ROUTE,
  TRAINING_PROGRAM_SHARED_ROUTE,
  TRAINING_PROGRAM_DETAIL_SHARED_ROUTE,
  TRAINING_CENTERS_SHARED_ROUTE,
  RECOMMEND_HOTELS_SHARED_ROUTE,
  CONTACT_US_SHARED_ROUTE,
  STORIES_SHARED_ROUTE,
  STORIES_BOSCH_SERVICE_TRAINING_SHARED_ROUTE,
  STORIES_A_DAY_IN_LIFE_SHARED_ROUTE,
  STORIES_TRAINING_BENEFIT_SHARED_ROUTE,
  STORIES_MODERN_TRAINING_APPROACH_SHARED_ROUTE,
  SSO_SHARED_ROUTE,
  SUPPORT_SHARED_ROUTE,
  FAQ_SHARED_ROUTE,
  DOWNLOAD_AREA_SHARED_ROUTE,
  MY_PROFILE_SHARED_ROUTE,
  MY_SHOPPING_CART_SHARED_ROUTE,
  CHECK_OUT_SHARED_ROUTE,
  DASHBOARD_SHARED_ROUTE,
  DASHBOARD_CURRICULA_SHARED_ROUTE,
  DASHBOARD_TEAM_SHARED_ROUTE,
  DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  DASHBOARD_EXTERNAL_SHARED_ROUTE,
  DASHBOARD_WORKSHOP_SHARED_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_SHARED_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_ADD_MEMBER_SHARED_ROUTE,
  DASHBOARD_WORKSHOP_TEAM_EDIT_PROFILE_SHARED_ROUTE,
  DASHBOARD_WALLET_SHARED_ROUTE,
  MY_TRAINING_COURSE_DETAIL_SHARED_ROUTE,
  MY_TRAINING_PROGRAM_DETAIL_SHARED_ROUTE,
  DASHBOARD_MY_TRAINING_EVENT_SHARED_ROUTE,
  JOIN_COMPANY_SHARED_ROUTE,
} from "./sharedRoutes.constants";

/**
 * BATS v4 ONLY ROUTES
 */

export const HOME_BATS_V4_ROUTE: RouteConfigure = {
  ...HOME_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../components/views/Home")),
};

export const TRAINING_SERVICES_BATS_V4_ROUTE: RouteConfigure = {
  ...TRAINING_COURSE_CATALOGUE_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        "../../pages/BATSv4/TrainingServices/TrainingServiceTrainingCourseCatalog"
      )
  ),
};

export const TRAINING_COURSE_CATALOGUE_BATS_V4_ROUTE: RouteConfigure = {
  ...TRAINING_COURSE_CATALOGUE_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/TrainingCourseCatalogue"
        "../../pages/BATSv4/TrainingServices/TrainingServiceTrainingCourseCatalog"
      )
  ),
  parentRoute: TRAINING_SERVICES_BATS_V4_ROUTE,
};

export const COURSE_DETAIL_BATS_V4_ROUTE: RouteConfigure = {
  ...COURSE_DETAIL_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        "../../Common-components/TrainingCatalogue/TrainingCourseDetail/CourseDetail"
      )
  ),
  parentRoute: TRAINING_COURSE_CATALOGUE_BATS_V4_ROUTE,
};
export const TRAINING_COURSE_DETAIL_BATS_V4_ROUTE: RouteConfigure = {
  ...TRAINING_COURSE_DETAIL_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../Common-components/TrainingCatalogue/TrainingCourseDetail/TrainingCourseDetail"
        "../../pages/BATSv4/TrainingServices/TrainingServiceTrainingCourseDetail"
      )
  ),
  parentRoute: TRAINING_COURSE_CATALOGUE_BATS_V4_ROUTE,
};

export const EVENT_CALENDAR_BATS_V4_ROUTE: RouteConfigure = {
  ...EVENT_CALENDAR_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/EventCalendar"
        "../../pages/BATSv4/TrainingServices/TrainingServiceEventCalendar"
      )
  ),
  parentRoute: TRAINING_SERVICES_BATS_V4_ROUTE,
};
export const TRAINING_PROGRAM_BATS_V4_ROUTE: RouteConfigure = {
  ...TRAINING_PROGRAM_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/TrainingProgram"
        "../../pages/BATSv4/TrainingServices/TrainingServiceTrainingProgram"
      )
  ),
  parentRoute: TRAINING_SERVICES_BATS_V4_ROUTE,
};
export const TRAINING_PROGRAM_DETAIL_BATS_V4_ROUTE: RouteConfigure = {
  ...TRAINING_PROGRAM_DETAIL_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../Common-components/TrainingProgramLayout/TrainingProgramDetailPage"
        "../../pages/BATSv4/TrainingServices/TrainingServiceTrainingProgramDetail"
      )
  ),
  parentRoute: TRAINING_PROGRAM_BATS_V4_ROUTE,
};

export const TRAINING_CENTERS_BATS_V4_ROUTE: RouteConfigure = {
  ...TRAINING_CENTERS_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/TrainingCentres"
        "../../pages/BATSv4/TrainingLocation/TrainingLocationTrainingCenters"
      )
  ),
};
export const RECOMMEND_HOTELS_BATS_V4_ROUTE: RouteConfigure = {
  ...RECOMMEND_HOTELS_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/RecommendedHotels"
        "../../pages/BATSv4/TrainingLocation/TrainingLocationRecommendHotel"
      )
  ),
  parentRoute: TRAINING_CENTERS_BATS_V4_ROUTE,
};

export const CONTACT_US_BATS_V4_ROUTE: RouteConfigure = {
  ...CONTACT_US_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/ContactUs"
        "../../pages/BATSv4/Support/SupportContactUs"
      )
  ),
};
export const STORIES_BATS_V4_ROUTE: RouteConfigure = {
  ...STORIES_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/Stories/Stories")
  ),
};
export const STORIES_BOSCH_SERVICE_TRAINING_BATS_V4_ROUTE: RouteConfigure = {
  ...STORIES_BOSCH_SERVICE_TRAINING_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/Stories/BoschServiceTraining")
  ),
  parentRoute: STORIES_BATS_V4_ROUTE,
};
export const STORIES_A_DAY_IN_LIFE_BATS_V4_ROUTE: RouteConfigure = {
  ...STORIES_A_DAY_IN_LIFE_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/Stories/ADayInLifeOfTrainer")
  ),
  parentRoute: STORIES_BATS_V4_ROUTE,
};
export const STORIES_TRAINING_BENEFIT_BATS_V4_ROUTE: RouteConfigure = {
  ...STORIES_TRAINING_BENEFIT_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/Stories/BoschTrainingBenefits")
  ),
  parentRoute: STORIES_BATS_V4_ROUTE,
};
export const STORIES_MODERN_TRAINING_APPROACH_BATS_V4_ROUTE: RouteConfigure = {
  ...STORIES_MODERN_TRAINING_APPROACH_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/Stories/AModernTrainingApproach")
  ),
  parentRoute: STORIES_BATS_V4_ROUTE,
};
export const SSO_BATS_V4_ROUTE: RouteConfigure = {
  ...SSO_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/sso/SSOPage")
  ),
};
export const SUPPORT_BATS_V4_ROUTE: RouteConfigure = {
  ...SUPPORT_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/Support/Support")
  ),
};
export const FAQ_BATS_V4_ROUTE: RouteConfigure = {
  ...FAQ_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/Support/Support"
        "../../pages/BATSv4/Support/SupportFAQPage"
      )
  ),
  parentRoute: SUPPORT_BATS_V4_ROUTE,
};
export const DOWNLOAD_AREA_BATS_V4_ROUTE: RouteConfigure = {
  ...DOWNLOAD_AREA_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/Support/downloadPage/downloadPage"
        "../../pages/BATSv4/Support/SupportDownloadArea"
      )
  ),
  parentRoute: SUPPORT_BATS_V4_ROUTE,
};
export const MY_PROFILE_BATS_V4_ROUTE: RouteConfigure = {
  ...MY_PROFILE_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../components/views/MyProfile/MyProfile"
        "../../pages/BATSv4/Account/MyAccountProfile"
      )
  ),
};

export const JOIN_COMPANY_BATS_V4_ROUTE: RouteConfigure = {
  ...JOIN_COMPANY_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        "../../Common-components/MyDashboard/JoinCompanyPage"
      )
  ),
};

export const MY_SHOPPING_CART_BATS_V4_ROUTE: RouteConfigure = {
  ...MY_SHOPPING_CART_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../components/views/ShoppingCart")
  ),
};
export const CHECK_OUT_BATS_V4_ROUTE: RouteConfigure = {
  ...CHECK_OUT_SHARED_ROUTE,
  component: LoadableComponent(() => import("../../components/views/Checkout")),
  parentRoute: MY_SHOPPING_CART_BATS_V4_ROUTE,
};
export const DASHBOARD_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
};

export const DASHBOARD_MY_TRAINING_EVENTS_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_MY_TRAINING_EVENT_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_BATS_V4_ROUTE,
};

export const DASHBOARD_CURRICULA_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_CURRICULA_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_BATS_V4_ROUTE,
};
export const DASHBOARD_TEAM_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_BATS_V4_ROUTE,
};

export const DASHBOARD_TEAM_CREATE_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_CREATE_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../Common-components/Dashboard/Team/Administration"
        "../../pages/BATSv4/Dashboard/Team/DashboardTeamCreate"
      )
  ),
  parentRoute: DASHBOARD_TEAM_BATS_V4_ROUTE,
};

export const DASHBOARD_TEAM_PROFILE_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_TEAM_EDIT_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_TEAM_BATS_V4_ROUTE,
};
export const DASHBOARD_EXTERNAL_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_EXTERNAL_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_BATS_V4_ROUTE,
};

export const DASHBOARD_WORKSHOP_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_WORKSHOP_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_BATS_V4_ROUTE,
};
export const DASHBOARD_WORKSHOP_TEAM_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_WORKSHOP_TEAM_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
};

export const DASHBOARD_WORKSHOP_TEAM_ADD_MEMBER_BATS_V4_ROUTE: RouteConfigure =
  {
    ...DASHBOARD_WORKSHOP_TEAM_ADD_MEMBER_SHARED_ROUTE,
    component: LoadableComponent(
      () =>
        import(
          //"../../Common-components/Dashboard/Team/Administration"
          "../../pages/BATSv4/Dashboard/Team/DashboardTeamCreate"
        )
    ),
  };
export const DASHBOARD_WORKSHOP_TEAM_EDIT_PROFILE_BATS_V4_ROUTE: RouteConfigure =
  {
    ...DASHBOARD_WORKSHOP_TEAM_EDIT_PROFILE_SHARED_ROUTE,
    component: LoadableComponent(
      () => import(
        //"../../pages/BATSv4/Dashboard/MyDashboardV4"
        "../../pages/BATSv4/Account/MyAccountProfile"
      )
    ),
  };
export const DASHBOARD_WALLET_BATS_V4_ROUTE: RouteConfigure = {
  ...DASHBOARD_WALLET_SHARED_ROUTE,
  component: LoadableComponent(
    () => import("../../pages/BATSv4/Dashboard/MyDashboardV4")
  ),
  parentRoute: DASHBOARD_BATS_V4_ROUTE,
};
export const MY_TRAINING_COURSE_DETAIL_BATS_V4_ROUTE: RouteConfigure = {
  ...MY_TRAINING_COURSE_DETAIL_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../Common-components/Dashboard/Courses/CourseDetails/CourseDetails"
        "../../pages/BATSv4/Dashboard/DashboardCourseDetails"
      )
  ),
};
export const MY_TRAINING_PROGRAM_DETAIL_BATS_V4_ROUTE: RouteConfigure = {
  ...MY_TRAINING_PROGRAM_DETAIL_SHARED_ROUTE,
  component: LoadableComponent(
    () =>
      import(
        //"../../Common-components/Dashboard/Curricula/TrainingProgramDetails"
        "../../pages/BATSv4/Dashboard/Curricula/DashboardTrainingProgramDetail"
      )
  ),
};
