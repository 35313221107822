export const TRANSLATIONS_ES_AR = {
	LandingPage_Title: "Bienvenido a Bosch Automotive Training Solutions",
	LandingPage_SubTitle_ChooseCountry: "Elegí tu país/región",
	Header_ContactUs: "Contactanos",
	Header_Search: "Buscar",
	Header_Menu: "Menu",
	Header_LogInOrCreateYourAccount: "Login / Crea tu cuenta",
	Header_LogIn: "Login",
	Header_Cart: "Carrito",
	Header_ShoppingCart: "Carrito de Compras",
	Header_LogOut: "Salir",
	Header_ChangeDetails: "Cambiar detalles",
	Footer_ExploreOurTraining: "Explorar nuestras capacitaciones",
	Footer_TrainingAtYourFingertips: "Capacitaciones a tu alcance",
	Footer_DontMissAnyStory: "No te pierdas nada",
	Footer_ExploreOurStories: "Explora nuestras historias",
	Footer_GetInTouchWithUs: "Contactenos",
	Footer_SendUsAMessage: "Envíenos un mensaje",
	Footer_ToContactForm: "Formulario de contacto",
	Footer_InventedForLife: "Innovación para tu vida",
	Footer_GeneralContactInfo: "Información general de contacto",
	Footer_ProductSecurity_Psirt: "Seguridad de producto (PSIRT)",
	Footer_InnovationsPatentsAndLicenses: "Innovaciones, patentes y licencias",
	Footer_PurchasingLogistics: "Compras y logística",
	Footer_CorporateInformation: "Información corporativa",
	Footer_LegalNotice: "Aviso Legal",
	Footer_DataProtectionNotice: "Aviso de protección de datos",
	Footer_PrivacySettings: "Configuración de privacidad",
	Footer_RobertBoschGmbh2021_AllRightsReserved: "© Robert Bosch Ltda. 2022, todos los derechos reservados",
	Footer_StillLookingForSomething: "¿Sigue buscando algo?",
	Footer_TrainingServices: "Capacitaciones",
	Footer_Courses: "Cursos",
	Footer_Programs: "Programas",
	Footer_EventsCalendar: "Calendario de eventos",
	Footer_TrainingCenters: "Centros de capacitación",
	Footer_Dashboard: "Panel",
	Footer_MyDashboard: "Mi Panel",
	Footer_ShoppingCart: "Carrito de Compras",
	Footer_YourShoppingCart: "Tu Carrito de Compras",
	Footer_Administration: "Administración",
	Footer_Employees: "Empleados",
	Footer_Wholesalers: "Mayoristas",
	Footer_Transactions: "Transacciones",
	Footer_Workshops: "Talleres",
	Footer_WorkLikeABoschApplyNow: "Trabaje #LikeABosch: postúlese ahora",
	Footer_BoschGlobalCareers: "Carreras globales de Bosch",
	Footer_PurchasingAndLogistics: "Compras y logística",
	Footer_BecomeABusinessPartner: "Conviértase en socio comercial",
	Footer_HowOurTrainingEvolvesToHelpYourBusiness: "Cómo evoluciona nuestra capacitación para ayudar a su negocio",
	Footer_Language: "Selección de país",
	MenuPopup_MyCart: "Mi carrito",
	Stories_OurTrainingStories: "Nuestras historias de capacitación",
	Stories_ConnectingExperts: "Conectamos expertos, entusiastas, soñadores, emprendedores, innovadores y influyentes: explore y experimente",
	Stories_TopStories: "Historias principales",
	Stories_AtHome: "En casa",
	Stories_Careers: "Carreras",
	Stories_History: "Historia",
	Stories_IndustryAndTrades: "Industria y oficios",
	Stories_Mobility: "Movilidad",
	Stories_Research: "Búsqueda",
	Stories_Sustainability: "Sustentabilidad",
	Stories_AllStories: "Todas las historias",
	Stories_BlogATrainersDay: "BLOG | El día de un entrenador",
	Stories_ADayinLifeOfATrainer: "Un día en la vida de un entrenador",
	Stories_BoschServiceTraining: "Capacitación del Bosch Service",
	Stories_StoryWhatDrivesYouDrivesUs: "HISTORIA | Lo que te mueve, nos mueve a nosotros",
	Stories_StoryHowCanWeBenefitYourBusiness: "HISTORIA | ¿Cómo podemos beneficiar a su negocio?",
	Stories_HowOurTrainingEvolves: "Cómo evoluciona nuestra capacitación",
	Stories_AModernTrainingApproach: "El mundo de capacitación online",
	Stories_StoryAVirtualOnlineTrainingWorld: "HISTORIA | Tus oportunidades de capacitación online",
	Stories_SubscribeToRSSFeed: "Suscríbete al RSS feed",
	Title_Main: "Bosch Automotive Training Solutions",
	Homepage_AboutUs: "Sobre nosotros",
	Homepage_AutomotiveAftermarket: "Posventa Automotriz",
	Homepage_GetInTouch: "Contactenos",
	Homepage_LatestNews: "Descubra por qué nos mueve la sed de conocimiento",
	Homepage_MainTitle: "Bosch Automotive Training Solutions",
	Homepage_OurOpenPositions: "Nuestras vacantes",
	BoschServiceTraining_WhatDrivesYouDrivesUs: "Lo que te mueve, nos mueve a nosotros",
	BoschServiceTraining_BoschServiceTraining: "Capacitaciones del Bosch Service",
	BoschServiceTraining_ShareThisOn: "Compartir",
	BoschServiceTraining_TheChallenge: "El desafío: dominar las nuevas tecnologías",
	BoschServiceTraining_WorldwidePresence: "Presencial mundial",
	BoschServiceTraining_TechnicalTraining: "Capacitaciones técnicas de Bosch",
	BoschServiceTraining_TheOffersAndOpportunities: "Las ofertas y oportunidades que ofrecemos en Bosch",
	BoschServiceTraining_TechnicalCoursesDesc: "Para cada grupo objetivo, ya sea principiante o profesional, existen cursos de formación adecuados. Ofrecemos cursos de formación de un día o de varios días. Los cursos de formación se imparten en diferentes áreas técnicas y de formación (por ejemplo, inyección de gasolina, inyección diésel, sistemas eléctricos). En estos cursos de formación técnica, Bosch transmite amplios conocimientos sobre todos los sistemas estándar de vehículos y sistemas Bosch; en los cursos de formación también se tratan temas de otros fabricantes. El contenido está especialmente adaptado a las necesidades de los empleados del taller.",
	BoschServiceTraining_TrainedEmployeesDesc: "Los trabajadores formados son una ventaja evidente para los talleres de automoción, ya que los vehículos actuales son cada vez más complejos y las nuevas tecnologías plantean nuevos retos. Solo los expertos necesarios permiten a los trabajadores del taller realizar trabajos de diagnóstico, mantenimiento y reparación en los modelos de vehículos actuales de forma eficiente y rentable.",
	BoschServiceTraining_RegardlessOfHow: "Por muy buenos y experimentados que sean los especialistas en automoción, siempre hay situaciones en las que entran en contacto con nuevos sistemas tecnológicos de vehículos o fallos desconocidos. En este sentido, la localización y resolución de problemas puede resultar especialmente intensiva en tiempo o, posiblemente, no dar resultados satisfactorios. En el peor de los casos, los clientes deben prescindir de su vehículo mucho más tiempo del esperado.",
	BoschServiceTraining_ItIsPrecisely: "Precisamente en este punto, Bosch aporta su Service Training para mejorar la calidad del servicio y acelerar los procesos. La formación actual de Bosch ayuda a localizar problemas más rápidamente, reparar más vehículos al mismo tiempo y reducir los tiempos de espera de sus clientes. Así, los procedimientos sistemáticos que aprendemos en Bosch garantizan un trabajo más rápido y productivo.",
	BoschServiceTraining_WorldwidePresenceDesc1: "Desde hace más de 125 años, Bosch es el proveedor preferido de piezas, componentes y sistemas originales de muchos de los principales fabricantes de automóviles del mundo. Esta experiencia se complementa con un conocimiento único de las últimas tecnologías que sirve de base para el soporte de Bosch.",
	BoschServiceTraining_WorldwidePresenceDesc2: "Los centros de formación de servicio de Bosch se encuentran en todo el mundo. Bosch ofrece a sus empleados una formación orientada a la práctica que siempre está en línea con las tecnologías más modernas.",
	BoschServiceTraining_AgileCompany: "Empresa ágil",
	BoschServiceTraining_Collaboration: "Colaboración",
	BoschServiceTraining_ConnectedMobility: "Movilidad conectada",
	BoschServiceTraining_ArtificialIntelligence: "Inteligencia artificial",
	BoschServiceTraining_ThoughtLeadersInDialogue: "Líderes en diálogo",
	BoschServiceTraining_Icon_DiagnosticsAndTestEquipments: "Diagnóstico y equipos de prueba",
	BoschServiceTraining_Icon_AutomotiveTechnologyFundamentals: "Fundamentos de la tecnología automotriz",
	BoschServiceTraining_Icon_Gasoline: "Gasolina",
	BoschServiceTraining_Icon_Diesel: "Diésel",
	BoschServiceTraining_Icon_AlternativeDrives: "Transmisiones alternativas",
	BoschServiceTraining_Icon_ElectricalAndElectronics: "Electricidad y electrónica",
	BoschServiceTraining_Icon_BodyAndComfort: "Carrocería y confort",
	BoschServiceTraining_Icon_ChassisAndBraking: "Chasis y frenos",
	BoschServiceTraining_Icon_TransmissionAndDrivetrain: "Sistemas de transmisión",
	BoschServiceTraining_Icon_CommercialVehicles: "Vehículos comerciales",
	BoschServiceTraining_Icon_OnlineTrainingCourses: "Capacitaciones online",
	BoschServiceTraining_Icon_BusinessSkills: "Habilidades gerenciales",
	BoschServiceTraining_Icon_Accreditations: "Certificaciones",
	BoschServiceTraining_Icon_TrainingCourseProgrammes: "Programas de capacitaciones",
	ADayInTheLifeOfATrainer_TrainerFirstNameLastName: "Nombre del entrenador",
	ADayInTheLifeOfATrainer_ADayInTheLifeOfATrainer: "Un día en la vida de un entrenador",
	ADayInTheLifeOfATrainer_EverydayIsAnAdventure: "Cada día es una aventura",
	ADayInTheLifeOfATrainer_Summary: "Resumen",
	ADayInTheLifeOfATrainer_TheDayJourney: "El viaje del día",
	ADayInTheLifeOfATrainer_EverydayIsABusyDay: "Cada día es un día ajetreado, pero siempre es una experiencia gratificante. El placer de formar a los estudiantes sabiendo que ellos tienen la clave de nuestro futuro es una responsabilidad, pero al mismo tiempo muy emocionante. Ver a los estudiantes crecer con cada lección a diario y semanalmente nos demuestra que estamos trabajando hacia un objetivo común: mejorar sus conocimientos al tiempo que incorporamos tecnología moderna que impulsa el futuro.",
	ADayInTheLifeOfATrainer_AtTheEndOfEachDay: "Al final de cada día, nos sentamos y reflexionamos sobre el trabajo del día. Los días pasan muy rápido, pero esto es una prueba en sí misma de que lo que estamos logrando no solo es gratificante para los estudiantes, sino también para nosotros como formadores.",
	ADayInTheLifeOfATrainer_WeCanGoHome: "¡Podemos volver a casa al final del día satisfechos de haber hecho un gran trabajo!",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Heading: "Comienzo",
	ADayInTheLifeOfATrainer_DaysJourneyStep1Text: "Preparación y comprobaciones finales del material de formación. Revisa todo el correo importante.",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Heading: "Comienza la formación",
	ADayInTheLifeOfATrainer_DaysJourneyStep2Text: "Recepción de los participantes. Presentación del programa diario. Conozca al grupo entendiendo la dinámica de cada individuo.",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Heading: "Teoría",
	ADayInTheLifeOfATrainer_DaysJourneyStep3Text: "Se requiere cierta interacción con el grupo para que la formación sea lo más interesante posible. Asegúrese de que haya tareas entrelazadas con la teoría para involucrar aún más al grupo.",
	ADayInTheLifeOfATrainer_DaysJourneyStep4Heading: "Pausa para el almuerzo",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Heading: "Práctica",
	ADayInTheLifeOfATrainer_DaysJourneyStep5Text: "La formación continúa en el taller con algunas tareas prácticas. Como formador, siempre es un requisito estar presente y disponible como siempre para orientar o cualquier eventualidad.",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Heading: "Resumen",
	ADayInTheLifeOfATrainer_DaysJourneyStep6Text: "Regreso al aula para una sesión informativa para comentar la formación del día. Responda a las preguntas que puedan surgir. Comience la preparación para las próximas sesiones de formación.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading: "Último día",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Text: "Se entregan los certificados de asistencia a los participantes.",
	ADayInTheLifeOfATrainer_DaysJourneyStep7Heading2: "Los participantes se van a casa.",
	BoschTrainingBenefits_HowCanWeHelpBenefitYourBusiness: "Cómo podemos ayudar a beneficiar a su negocio",
	BoschTrainingBenefits_HowOurTrainingEvolves: "Cómo evoluciona nuestra formación",
	BoschTrainingBenefits_WeAreConstantlyEvolving: "Estamos en constante evolución para garantizar que ofrecemos una formación actualizada y de calidad",
	BoschTrainingBenefits_TheBoschServiceTraining: "El Centro de Formación de Servicio Bosch le ayudará a desarrollar su negocio a través de su amplia y relevante oferta de cursos de formación técnica y empresarial y de formación continua con cualificaciones certificadas. En las siguientes páginas encontrará una visión general de nuestro programa de formación, así como de las actuales oportunidades de formación continua. Esperamos conocerle a usted y a sus empleados en nuestros cursos de formación y seminarios.",
	BoschTrainingBenefits_LongStandingExperience: "Una grand experiencia con un amplio conocimiento de todos los sistemas y en el área de la tecnología del automóvil.",
	BoschTrainingBenefits_QuickAndEfficient: "Soluciones rápidas y eficaces para muchos problemas",
	BoschTrainingBenefits_TheAdvantagesAndBenefits: "Las ventajas y los beneficios",
	BoschTrainingBenefits_LMS: "Sistema de gestión de aprendizaje - Bosch Training Solutions",
	BoschTrainingBenefits_BTSCoversTheFullRange: "Bosch Training olutions cubre toda la gama de áreas automotrizes. Puede especializarse en área específicas o ampliar su competencia con la oferta de formación de Bosch.",
	BoschTrainingBenefits_AcquiredKnowledge: "Adquiera conocimientos sobre reparación, mantenimiento y servicio técnico.",
	BoschTrainingBenefits_TheHighDegree: "El alto grado de contenido práctico y los grupos reducidos garantizan un aprendizaje orientado a objetivos.",
	BoschTrainingBenefits_ParticipationInTraining: "La participación en la formación aumenta la motivación de los empleados, la satisfacción y las cualificaciones adicionales para tranquilizar a los clientes",
	BoschTrainingBenefits_IncreasedSales: "Aumento de las ventas gracias al conocimiento del sistema entre marcas",
	BoschTrainingBenefits_MethodicalProcedures: "Los procedimientos metódicos reducen el tiempo de espera de los clientes",
	BoschTrainingBenefits_HighlyQualified: "Instructores altamente cualificados transmiten procedimientos sistemáticos",
	BoschTrainingBenefits_IdentificationOfTheTraining: "Identificación de la solución de formación adecuada para usted o su empresa",
	BoschTrainingBenefits_DirectOnlineBooking: "Reserva y gestión directa en línea de cursos para usted o sus empleados",
	BoschTrainingBenefits_OnlineAccess: "Acceso online a la documentación de aprendizaje",
	BoschTrainingBenefits_AccessToYourOwn: "Acceso a su histórico de formación personal y a sus certificados",
	BoschTrainingBenefits_ToolsForManagement: "Herramientas para la gestión de su propio desarrollo profesional",
	BoschTrainingBenefits_ManagerHasFull: "Los directivos tienen una visión completa de los cursos de sus empleados dentro del sistema",
	BoschTrainingBenefits_Access24hours: "Acceso las 24 horas, los 7 días de la semana",
	ModernTrainingApproach_KnowledgeIsKey: "El conocimiento es un factor clave para el éxito de cualquier taller. Por eso, Bosch ofrece un completo programa de desarrollo de formación profesional. Este permite a los estudiantes adquirir exactamente los conocimientos adecuados en el momento y lugar adecuados: los conocimientos necesarios para poder realizar el mantenimiento y la reparación de los vehículos de los clientes de forma profesional. Bosch ha añadido la retransmisión web como un nuevo módulo que forma parte del paquete de aprendizaje electrónico que ya ofrece. Consisten en sesiones de formación de servicio diseñadas específicamente para su retransmisión.",
	ModernTrainingApproach_ANewLearning: "Una nueva oportunidad de aprendizaje",
	ModernTrainingApproach_YourOnlineTraining: "Sus oportunidades de formación online",
	ModernTrainingApproach_RecentlyTheWorld: "¡El mundo ha cambiado recientemente! Vea lo que estamos haciendo para ayudarle",
	ModernTrainingApproach_TheOnlineTrainingWorld: "El mundo de la formación online",
	ModernTrainingApproach_NewTeachingMethod: "Nuevo método de enseñanza",
	ModernTrainingApproach_InAdditionTo: "Además de la formación presencial existente, hemos incluido en el programa de formación la formación webcast en directo. Nuestro formador estará a su disposición con amplios conocimientos teóricos en el aula virtual y le proporcionará ejemplos prácticos. Como es habitual, recibirá mucha información sobre los sistemas del vehículo y los productos Bosch correspondientes. Por supuesto, también tendrá la oportunidad de hacer preguntas que se responderán directamente en la transmisión web en directo. Las transmisiones web en directo duran entre 60 y 120 minutos. Se requiere una conexión a Internet estable. Busque una transmisión web adecuada directamente en nuestro catálogo de formación introduciendo \"Webcast\" en el campo de búsqueda.",
	ModernTrainingApproach_BoschWebcastingHeader: "Transmisión web de Bosch: innovadora y práctica",
	ModernTrainingApproach_BoschWebcastingText: "Para participar en una transmisión web de Bosch, en primer lugar, el participante debe marcar el número de teléfono. En segundo lugar, se envía el código de acceso individual antes de la sesión para permitir el inicio de sesión. Pueden participar hasta 200 participantes al mismo tiempo desde cualquier número de ubicaciones. La formación está dirigida por un formador y se pueden enviar preguntas en cualquier momento a través de la función de chat en directo.",
	ModernTrainingApproach_TheDigitalSupplementHeading: "El complemento digital de la formación en el aula",
	ModernTrainingApproach_TheDigitalSupplementText: "La transmisión web y la formación en el aula se complementan perfectamente. En ambos casos, la formación la imparte un formador cualificado. Los webcasts son adecuados para temas que se pueden transmitir digitalmente sin necesidad de trabajar en el vehículo. Por ejemplo, para conocer determinadas funciones ESI[tronic] de un nuevo sistema de asistencia al conductor. Cuando se trata de trabajar en equipo de forma eficiente en el taller o de realizar trabajos prácticos en un vehículo, la formación presencial tecnológica sigue siendo la primera opción.",
	ModernTrainingApproach_StreamingCanBeCheaperHeading: "El streaming puede ser más económico",
	ModernTrainingApproach_StreamingCanBeCheaperText: "Los costes por participante suelen ser inferiores con el webcasting que con un curso presencial comparable. El motivo es que pueden beneficiarse de él más participantes y el esfuerzo asociado a la formación es significativamente menor (no es necesario viajar hasta el lugar de realización y se requiere menos tiempo).",
	ModernTrainingApproach_BookAndProfitHeading: "Reserve y obtenga beneficios",
	ModernTrainingApproach_BookAndProfitText: "Los webcasts se pueden reservar en nuestro sitio web, al igual que nuestros otros cursos de formación y ofertas de aprendizaje.",
	ModernTrainingApproach_BoschWebcastingAndClassroomTrainingCompared: "Comparación de la formación presencial y webcasting de Bosch",
	ModernTrainingApproach_Webcasting: "Webcasting",
	ModernTrainingApproach_Classroom: "Aula",
	ModernTrainingApproach_WebcastingP1: "Pueden participar hasta 200 participantes al mismo tiempo desde cualquier parte del mundo",
	ModernTrainingApproach_WebcastingP2: "Baja complejidad y costes más bajos, ya que no es necesario viajar",
	ModernTrainingApproach_WebcastingP3: "Ideal para temas individuales específicos y concisos",
	ModernTrainingApproach_WebcastingP4: "Los talleres pueden realizar un webcast para sus empleados en cualquier lugar y en cualquier momento",
	ModernTrainingApproach_WebcastingP5: "Se requiere entre un 50 y un 70 % menos de tiempo que para la formación presencial",
	ModernTrainingApproach_ClassroomTrainingP1: "Hasta 16 participantes se reúnen al mismo tiempo",
	ModernTrainingApproach_ClassroomTrainingP2: "Gastos de viaje y, en general, costes más elevados por persona",
	ModernTrainingApproach_ClassroomTrainingP3: "Ideal para optimizar, colaborar o aprender directamente en el vehículo",
	ModernTrainingApproach_ClassroomTrainingP4: "Ejercicios prácticos e interacción con el formador",
	ModernTrainingApproach_ClassroomTrainingP5: "El formador puede adaptar mejor los conocimientos a las necesidades reales de los participantes",
	ModernTrainingApproach_OnlineTrainingOfferings: "Ofertas de formación online (WBT/VBS)",
	ModernTrainingApproach_OnlineTrainingOfferingsPara1: "Hoy en día, el aprendizaje autónomo en el ordenador forma parte integral de la transferencia de conocimientos. La libertad de elegir el momento y el lugar, la repetibilidad ilimitada, la velocidad de aprendizaje individual y los contenidos de aprendizaje interactivos hacen de este método una herramienta de estudio indispensable.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara2: "Cada elemento de la formación basada en la web (WBT) ha sido cuidadosamente estructurado. Además de proporcionar una amplia base de conocimientos, estos cursos pueden servir como preparación eficaz para los programas de formación en los centros de formación de Bosch.",
	ModernTrainingApproach_OnlineTrainingOfferingsPara3: "La simulación de diagnóstico virtual ofrece al usuario la posibilidad de realizar juegos de rol para situaciones cotidianas del taller. Por ejemplo, el flujo de trabajo de un procedimiento de resolución de problemas utilizando dispositivos de diagnóstico de Bosch en un taller virtual.",
	ModernTrainingApproach_Advantages: "Ventajas",
	ModernTrainingApproach_AdvantagesP1: "Un método rápido y eficaz para impartir conocimientos",
	ModernTrainingApproach_AdvantagesP2: "Aprendizaje variado",
	ModernTrainingApproach_AdvantagesP3: "Se puede utilizar con la frecuencia que se desee",
	ModernTrainingApproach_AdvantagesP4: "Tiempo y dinero mínimos",
	ModernTrainingApproach_AdvantagesP5: "Velocidad de aprendizaje individual",
	ModernTrainingApproach_AdvantagesP6: "Tiempo de estudio flexible",
	ModernTrainingApproach_AdvantagesP7: "Repetibilidad",
	ModernTrainingApproach_AdvantagesP8: "Sin costes de desplazamiento",
	ModernTrainingApproach_AdvantagesP9: "Sin conflictos de capacidad ni de horarios",
	Dashboard_CoursesViewAndManageYourCourses: "CURSOS | Ver y gestionar tus cursos",
	Dashboard_MyCourses: "Mis cursos",
	Dashboard_TrainingProgramViewYourTrainingPrograms: "PROGRAMA DE FORMACIÓN | Ver tus programas de formación",
	Dashboard_MyTrainingProgram: "Mi programa de formación",
	Dashboard_ELearningViewAndManageYourELearningPortfolio: "eLEARNING | Ver y gestionar tu portfolio de eLearning",
	Dashboard_MyELearning: "Mi eLearning",
	Dashboard_AccountManageYourAccount: "CUENTA | Gestionar tu cuenta",
	Dashboard_MyAccount: "Mi cuenta",
	Dashboard_MyDashboard: "Mi panel de control",
	Dashboard_ManageYourAccount: "Gestionar tu cuenta",
	Dashboard_Workshops: "Talleres",
	Dashboard_AdministrationManageYourWorkshop: "ADMINISTRACIÓN | Gestionar tu taller",
	Dashboard_AdministrationManageYourTeams: "ADMINISTRACIÓN | Gestionar tus equipos",
	Dashboard_Administration: "Administración",
	Dashboard_ViewYourCourses: "Ver tus cursos",
	Dashboard_ViewYourTrainingPrograms: "Ver tus programas de formación",
	Dashboard_TrainingPrograms: "Programas de formación",
	Dashboard_ViewAndEditYourDetails: "Ver y editar tus datos",
	Dashboard_ViewYourELearningCourses: "Ver tus cursos de eLearning",
	Dashboard_Pending: "Pendiente",
	Dashboard_RequiresAdministratorSignOff: "(Requiere la aprobación del administrador)",
	Dashboard_HistoricalRecords: "Registros históricos",
	Dashboard_Registered: "Registrado",
	Dashboard_WaitingList: "Lista de espera",
	Dashboard_RecommendedForYou: "Recomendado para usted",
	Dashboard_AccountDetails: "Detalles de la cuenta",
	Dashboard_ChangeProfileImage: "Cambiar imagen de perfil",
	Dashboard_Title: "Título",
	Dashboard_Role: "Rol",
	Dashboard_FirstName: "Nombre",
	Dashboard_LastName: "Apellido",
	Dashboard_Telephone: "Teléfono",
	Dashboard_Mobile: "Celular",
	Dashboard_Email: "Correo electrónico",
	Dashboard_Language: "Idioma",
	Dashboard_Save: "Guardar",
	Dashboard_Student: "Estudiante",
	Dashboard_WorkshopTrainingAdministrator: "Administrador de capacitación de talleres",
	Dashboard_WholesalerTrainingAdministrator: "Administrador de capacitación de mayoristas",
	Dashboard_CompanyAdministration_CompanyAdministrationTitle: "Administración de la empresa",
	Dashboard_CompanyAdministration_ManageYourWorkshops: "Administra tus talleres...",
	Dashboard_CompanyAdministration_ManageYourEmployees: "Administra a tus empleados...",
	Dashboard_CompanyAdministration_Workshop: "Taller",
	Dashboard_CompanyAdministration_Address: "Dirección",
	Dashboard_CompanyAdministration_Zip: "Código postal",
	Dashboard_CompanyAdministration_City: "Ciudad",
	Dashboard_CompanyAdministration_Manage: "Administrar",
	Dashboard_AdministrationSection_AdministrationTitle: "Administración",
	Dashboard_AdministrationSection_ManageTheTeam: "Administra el equipo",
	Dashboard_AdministrationSection_AdministrationManageYourEmployees: "ADMINISTRACIÓN | Administra a tus empleados",
	Dashboard_AdministrationSection_Employees: "Empleados",
	Dashboard_AdministrationSection_AdministrationManageYourWholesalers: "ADMINISTRACIÓN | Administrar a sus mayoristas",
	Dashboard_AdministrationSection_Wholesalers: "Mayoristas",
	Dashboard_AdministrationSection_AdministrationManageYourTransactions: "ADMINISTRACIÓN | Administrar sus transacciones",
	Dashboard_AdministrationSection_Transactions: "Transacciones",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAdministration: "Administración de empleados",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_ManageYourEmployees: "Administrar a sus empleados...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Register: "Registrar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Records: "Registros",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Edit: "Editar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Employee: "Empleado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Add: "Agregar nuevo",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Create: "Crear",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Registered: "Registrado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Pending: "Pendiente",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_RequiresAdministratorSignOff: "(Requiere la aprobación del administrador)",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_HistoricalRecords: "Registros históricos",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_WaitingList: "Lista de espera",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_CurrentEmployeeList: "Lista de empleados actual",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeManagement: "Administración de empleados",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Name: "Nombre",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeAccount: "Cuenta de empleado",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ViewAndEditEmployeeDetails: "Ver y editar los detalles de los empleados...",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Settings: "Configuración",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowPersonalAccount: "Permitir cuenta personal",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_EmployeeWorkshopAdministrationProxyUpgrade: "Administración de talleres para empleados Actualización de proxy",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents: "Permitir que los empleados se registren para eventos de capacitación",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_SendEmailInvitation: "Enviar invitación por correo electrónico",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_DeactivateAccount: "Desactivar cuenta",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Save: "Guardar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Send: "Enviar",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_Edit: "Editar",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerManagement: "Administración de mayoristas",
	Dashboard_AdministrationSection_WholesalerManagementSection_ManageYourWholesalers: "Administrar a sus mayoristas...",
	Dashboard_AdministrationSection_WholesalerManagementSection_ApprovedWholesalerList: "Lista de mayoristas aprobados",
	Dashboard_AdministrationSection_WholesalerManagementSection_Name: "Nombre",
	Dashboard_AdministrationSection_WholesalerManagementSection_Delete: "Eliminar",
	Dashboard_AdministrationSection_WholesalerManagementSection_Create: "Crear",
	Dashboard_AdministrationSection_WholesalerManagementSection_Add: "Añadir",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_NewEmployeeAccountCreation: "Creación de nueva cuenta de empleado",
	Dashboard_AdministrationSection_WholesalerManagementSection_EmployeeCreation_CreateDetailsForNewEmployees: "Crear detalles para nuevos empleados...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_AddNewApprovedWholesalerAdministrator: "Añadir nuevo administrador de mayoristas aprobados",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_CreateDetailsForNewWholesalers: "Crear detalles para nuevos mayoristas...",
	Dashboard_AdministrationSection_WholesalerManagementSection_WholesalerCreationSection_ChooseApprovedWholesalerFromDropDownMenu: "Elegir mayorista aprobado en el menú desplegable...",
	Dashboard_AdministrationSection_TransactionsSection_Transactions: "Transacciones",
	Dashboard_AdministrationSection_TransactionsSection_ViewAndManageYourTransactions: "Ver y gestionar sus transacciones...",
	Dashboard_AdministrationSection_TransactionsSection_Back: "Volver",
	Dashboard_AdministrationSection_TransactionsSection_InvoiceNumber: "Número de factura",
	Dashboard_AdministrationSection_TransactionsSection_Description: "Descripción",
	Dashboard_AdministrationSection_TransactionsSection_PaymentMethod: "Método de pago",
	Dashboard_AdministrationSection_TransactionsSection_Date: "Fecha",
	Dashboard_AdministrationSection_TransactionsSection_Amount: "Importe",
	Dashboard_AdministrationSection_TransactionsSection_Download: "Descargar",
	TrainingCentres_TrainingCentres: "Centros de formación",
	TrainingCentres_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nuestros centros de formación están situados en todo el mundo",
	TrainingCentres_CountryCategory: "Categoría de país",
	TrainingCentres_CityCategory: "Categoría de ciudad",
	TrainingCentres_Country: "País",
	TrainingCentres_City: "Ciudad",
	TrainingCatalogue_TrainingCatalogue: "Catálogo de cursos de formación",
	TrainingCatalogue_LearningAtTheHighestLevel: "Aprendizaje al más alto nivel",
	TrainingCatalogue_StartDate: "Fecha de inicio",
	TrainingCatalogue_EndDate: "Fecha de finalización",
	TrainingCatalogue_TrainingName: "Nombre de la formación",
	TrainingCatalogue_Language: "Idioma",
	TrainingCatalogue_Days: "Días",
	TrainingCatalogue_Seats: "Cupos disponibles",
	TrainingCatalogue_Price: "Precio (IVA incluido)",
	TrainingCatalogue_Location: "Ubicación",
	TrainingCatalogue_PartNumber: "Número de pieza",
	TrainingCatalogue_TrainingCourse: "Curso de capacitación",
	TrainingCatalogue_Category: "Categoría",
	TrainingCatalogue_TrainingCategory: "Categoría de capacitación",
	TrainingCatalogue_CourseName: "Nombre del curso",
	TrainingCatalogue_TotalDays: "Días totales",
	TrainingCatalogue_Venue: "Lugar",
	TrainingCatalogue_Details: "Detalles",
	TrainingCatalogue_Prerequisites: "Requisitos previos",
	TrainingCatalogue_CourseType: "Tipo de curso",
	TrainingCatalogue_LearningMethod: "Método de aprendizaje",
	TrainingCatalogue_AddToWaitingList: "Agregar a la lista de espera",
	ShoppingCart_ShoppingCart: "Carrito de compras",
	ShoppingCart_ReviewAndManage: "Revise y administre sus suscripciones",
	ShoppingCart_YouHaveAddedItem: "Agregó los siguientes artículos a su carrito",
	ShoppingCart_Item: "Artículo",
	ShoppingCart_Quantity: "Cantidad",
	ShoppingCart_PricePerItem: "Precio por artículo",
	ShoppingCart_TotalPrice: "Precio total",
	ShoppingCart_VatIsIncluded: "Sin IVA",
	ShoppingCart_Redeem: "Si tiene créditos de capacitación, haga clic aquí para canjearlos",
	ShoppingCart_Purchase: "Haga clic aquí para comprar créditos de capacitación",
	ShoppingCart_Checkout: "Ir a caja",
	ShoppingCart_ContinueShopping: "Seguir comprando",
	ShoppingCart_TotalPriceInclVat: "Precio total (IVA incluido)",
	ShoppingCart_Apply: "Aplicar",
	ShoppingCart_ShoppingCartEmpty: "Su carrito de compras está vacío...",
	ShoppingCart_VatIncluded: "IVA (incluido)",
	ShoppingCart_ManageYourShopping: "Administre sus compras",
	ShoppingCart_Participant: "Participante",
	ShoppingCart_Complete: "Cerrar",
	ShoppingCart_NetPrice: "Precio neto",
	ShoppingCart_Workshop: "Taller",
	CheckOut_PreviewYourOrder: "Vista previa de su pedido",
	CheckOut_YourShoppingCart: "Carrito",
	CheckOut_Preview: "Vista previa",
	CheckOut_Payment: "Pago",
	CheckOut_Summary: "Resumen",
	CheckOut_Back: "Volver",
	CheckOut_PleaseReadAndAgreeOurTerms: "Lea y acepte nuestros Términos y condiciones",
	CheckOut_IHaveReadTheTermsAndCondition: "He leído los [Términos y condiciones] y los comprendo y acepto",
	CheckOut_YesIAgree: "Acepto los Términos y condiciones",
	CheckOut_Continue: "Continuar",
	CheckOut_CongratulationsWeHaveReceivedYourOrder: "¡Gracias, hemos recibido su pedido!",
	CheckOut_OrderConfirmation: "Recibirá una confirmación final del pedido una vez que su solicitud se haya procesado correctamente",
	CheckOut_WeWillContactYouDirectly: "Nos comunicaremos directamente con usted en caso de que tengamos alguna pregunta relacionada con su solicitud",
	CheckOut_AddToCart: "Agregar al carrito",
	CheckOut_CreditCart: "Carrito de crédito",
	CheckOut_DirectDebit: "Débito directo",
	CheckOut_TrainingCredits: "Créditos de capacitación",
	CheckOut_PreferredWholesaler: "Mayorista preferido",
	EventCalendar_EventCalendar: "Calendario de eventos",
	EventCalendar_TrainingEventsForAllYourNeeds: "Eventos de capacitación para todas sus necesidades",
	TrainingCourse_TrainingCourse: "Catálogo de cursos de capacitación",
	TrainingCourse_LearningAtTheHighestLevel: "Aprendizaje al más alto nivel",
	TrainingProgram_Title: "Catálogo de programas de capacitación",
	TrainingProgram_SubTitle: "Aprendizaje al más alto nivel",
	AccountDeactivationModal_Title: "¿Está seguro de que desea desactivar esta cuenta?",
	AccountDeactivationModal_Line1: "La desactivación de esta cuenta es permanente y eliminará todo el contenido, incluidos: transacciones de solicitud de servicio, saldos, miembros del equipo y configuraciones de perfil.",
	AccountDeactivationModal_Line2Part1: "Consulte nuestra política de cancelación en nuestra",
	AccountDeactivationModal_Line2Part2: "Términos y condiciones",
	AccountDeactivationModal_Line2Part3: "antes de enviar su solicitud.",
	AccountDeactivationModal_Line3: "¿Está seguro de que desea enviar una solicitud para desactivar esta cuenta?",
	AccountDeactivationModal_Cancel: "Cancelar",
	AccountDeactivationModal_Send: "Enviar",
	CurrentUserAccountDeactivationModal_Title: "¿Está seguro de que desea desactivar su cuenta?",
	CurrentUserAccountDeactivationModal_Line1: "La desactivación de su cuenta es permanente y eliminará todo el contenido, incluidos: transacciones de solicitud de servicio, saldos, miembros del equipo y configuraciones de perfil.",
	CurrentUserAccountDeactivationModal_Line2Part1: "Si se encuentra dentro de un período de suscripción activo, consulte nuestra política de cancelación en nuestros Términos y condiciones",
	CurrentUserAccountDeactivationModal_Line3: "¿Está seguro de que desea enviar una solicitud para desactivar su cuenta?",
	CurrentUserAccountDeactivationModal_Yes: "Sí",
	CurrentUserAccountDeactivationModal_No: "No",
	CourseWithdrawalModal_Title: "Cancelación del curso",
	CourseWithdrawalModal_Line1: "¿Está seguro de que desea retirarse del curso?",
	CourseWithdrawalModal_Line2Part1: "Recibirá una confirmación por correo electrónico una vez que se haya procesado su retiro. En caso de que tengamos alguna pregunta, nos comunicaremos con usted directamente. Lea nuestra",
	CourseWithdrawalModal_Line2Part2Link: "Política de cancelación en nuestros [Términos y condiciones]",
	CourseWithdrawalModal_Yes: "Retirarse",
	CourseWithdrawalModal_No: "Cancelar",
	Settings_Settings: "Configuración",
	Settings_ManageYourSettings: "Administrar su configuración",
	Settings_Profile: "Perfil",
	Settings_MyBoschGlobalIdLoginInformation: "Información de inicio de sesión de My Bosch Global ID",
	Settings_Edit: "Editar",
	Settings_CompanyDetails: "Datos de la empresa",
	Settings_RequestAccountDeletion: "Solicitar la eliminación de la cuenta",
	Settings_AccountDeletionText: "Si elimina su cuenta, todos sus datos (incluida su dirección y datos de pago) se borrarán y ya no estarán disponibles.",
	Settings_DeleteAccountRequestConfirmation: "Confirmación de eliminación de la cuenta",
	Settings_DeleteAccountRequestConfirmationText: "Hemos recibido su solicitud de eliminación de cuenta y nos pondremos en contacto con usted por correo electrónico o teléfono para la confirmación final",
	RecommendedHotels_RecommendedHotels: "Hoteles recomendados",
	RecommendedHotels_OurTrainingCentresAreSituatedThroughoutTheGlobe: "Nuestros centros de formación están repartidos por todo el mundo",
	Error_Common: "¡Algo ha ido mal!",
	Error_404: "La página que ha visitado no existe",
	Error_401: "No tiene acceso a esta página",
	Error_500: "El servidor está informando de un error",
	Dashboard_Courses_Table_Course: "Curso",
	Dashboard_Courses_Table_Date: "Fecha [Duración]",
	Dashboard_Courses_Table_Venue: "Lugar",
	Dashboard_Courses_Table_TrainingType: "Tipo de formación",
	Dashboard_Courses_Table_Action: "Acción",
	Dashboard_Courses_Table_TasksToComplete: "Tiene tareas que completar",
	Dashboard_Courses_Table_Withdraw: "Retirar",
	Dashboard_Courses_Table_Remove: "Eliminar",
	Dashboard_Courses_Table_View: "Ver",
	Dashboard_Courses_Table_NoRecordsToDisplay: "No hay registros para mostrar",
	Dashboard_Courses_Table_CompletionDate: "Fecha de finalización",
	Dashboard_Courses_Table_Actions_Assignments: "Asignaciones",
	Dashboard_Courses_Table_Actions_Certification: "Certificación",
	Dashboard_Courses_Table_Actions_Evaluations: "Evaluaciones",
	Dashboard_Courses_Table_Actions_Assessments: "Evaluaciones",
	Dashboard_Courses_Table_Actions_AddToCalendar: "Añadir al calendario",
	Dashboard_Courses_Table_CourseDetails_Task: "Tarea",
	Dashboard_Courses_Table_CourseDetails_DueDate: "Fecha de entrega",
	ContactUs_Headline: "Contáctenos",
	ContactUs_HowCanWeHelp: "¿Cómo podemos ayudarle?",
	ContactUs_EmailTab: "Correo electrónico",
	ContactUs_PhoneTab: "Teléfono",
	ContactUs_LetterTab: "Carta",
	ContactUs_Phone_Headline: "¡Llámenos!",
	ContactUs_Phone_Description: "Esperamos su llamada y estaremos encantados de ayudarle.",
	ContactUs_Phone_PhoneNumber: "Utilice el formulario de contacto para enviarnos un mensaje.",
	ContactUs_Phone_ChargeNotice: "Los cargos varían según su proveedor de servicios y país.",
	ContactUs_Letter_Headline: "¡Envíenos una carta!",
	ContactUs_Letter_Description: "¿Tiene preguntas, solicitudes o sugerencias? Esperamos su consulta.",
	ContactUs_Email_Headline: "¡Envíenos un correo electrónico!",
	ContactUs_Email_Description: "Envíenos su mensaje completando el formulario a continuación. Uno de nuestros asesores estará encantado de ponerse en contacto con usted.",
	ContactUs_Email_InputName: "Nombre*",
	ContactUs_Email_InputCompany: "Empresa",
	ContactUs_Email_InputCountry: "País*",
	ContactUs_Email_InputEmail: "Correo electrónico*",
	ContactUs_Email_InputMessage: "Mensaje*",
	ContactUs_Email_DataProtection: "Aviso de protección de datos*",
	ContactUs_Email_DataProtectionConfirm: "He leído la Política de privacidad*",
	ContactUs_Email_SecurityCheck: "Comprobación de seguridad",
	ContactUs_Email_SecurityCodeConfirm: "Ingrese los caracteres correctos que ve en el cuadro de arriba.",
	ContactUs_Email_SecurityCodeInvalid: "La respuesta del texto captcha que ingresó es incorrecta.",
	ContactUs_Email_MandatoryNotice: "Complete todos los campos obligatorios.",
	ContactUs_Email_SuccessMessage: "Su mensaje ha sido enviado",
	ContactUs_Email_TakenNoteDataProtection1: "He leído la",
	ContactUs_Email_TakenNoteDataProtection2: "Política de privacidad*",
	ContactUs_Email_SubmitButton: "Enviar",
	ContactUs_Email_FieldErrorMessage: "Complete todos los campos obligatorios.",
	ContactUs_Email_SuccessModal_OK: "Ok",
	AccountInReviewModal_Title: "¡Su cuenta está actualmente en revisión!",
	AccountInReviewModal_Paragraph1: "Gracias por registrarse en Bosch Automotive Training Solutions.",
	AccountInReviewModal_Paragraph2: "Nuestro personal de capacitación dedicado está finalizando la configuración de su cuenta. Le enviaremos una confirmación final al correo electrónico de su usuario cuando su cuenta esté lista para acceder.",
	AccountInReviewModal_Paragraph3: "Mientras tanto, siéntase libre de navegar por nuestro sitio web con acceso limitado.",
	AccountInReviewModal_Paragraph4: "Si tiene alguna pregunta o si su cuenta no se procesa dentro de las 48 horas (excluidos los fines de semana y feriados), no dude en",
	AccountInReviewModal_ContactUsLink: "Contáctenos",
	AccountInReviewModal_OKButton: "Ok",
	Header_YesButton: "Sí",
	Header_NoButton: "No",
	Header_LogOutText: "¿Quiere cerrar sesión?",
	Footer_EnquiryText: "Esperamos su consulta",
	Footer_Telephone: "Teléfono",
	Footer_TermsAndConditions: "Términos y condiciones",
	Footer_HowOurTrainingEvolves: "Cómo evoluciona nuestra capacitación",
	FooterSearch_SearchFieldPlaceholder: "Buscar",
	TrainingCatalogue_Action: "Acción",
	TrainingCatalogue_Duration: "Duración",
	TrainingCatalogue_Information: "Información",
	Settings_PersonalDetails: "Datos personales",
	Reset_All_Filters: "Restablecer todos los filtros",
	EventTable_Days: "Días",
	SearchField_AllResults: "Todos los resultados",
	TrainingCentresLayout_ViewDetails: "Ver detalles",
	Dropdown_Placeholder: "Elegir...",
	WaitlistPopup_SelectButtonText: "Seleccionar",
	WaitlistPopup_StartDate: "Fecha de inicio",
	WaitlistPopup_EndDate: "Fecha de finalización",
	WaitlistPopup_Language: "Idioma",
	WaitlistPopup_City: "Ciudad",
	WaitlistPopup_Workshop: "Taller",
	WaitlistPopup_Student: "Estudiante",
	WaitlistPopup_PreferredLocation: "Ubicación preferida",
	WaitlistPopup_SignUpCourseWaitingList: "Únete a la lista de espera del curso",
	WaitlistPopup_SignUpWithoutSelectingDate: "Únete a la lista de espera del curso sin seleccionar una fecha",
	WaitlistPopup_GeneralWaitList: "Lista de espera general",
	WatinglistPopup_CourseLabel: "Curso",
	Common_ContinueButton: "Continuar",
	UserAccountDeactivationModal_ConfirmLabel: "Confirmar",
	Filter_FilterLabel: "Filtrar",
	WaitinglistPopup_RemoveWaitlistItemConfirmMessage: "Ya estás en esta lista de espera. ¿Quieres eliminar tu registro existente y crear uno nuevo?",
	WaitinglistPopup_RemoveWaitlistItem_YesButton: "Sí, eliminar",
	WaitinglistPopup_RemoveWaitlistItem_NoButton: "No, cancelar",
	WaitinglistPopup_RemoveWaitlistItem_Title: "Lista de espera del curso",
	WaitinglistPopup_SignUpButtonText: "Registrarse",
	MyAccount_FirstNameRequied: "Nombre es obligatorio",
	MyAccount_LastNameRequired: "Apellido es obligatorio",
	MyAccount_RoleRequired: "Rol es obligatorio",
	MyAccount_MobileRequired: "Número de contacto es obligatorio",
	MyAccount_EmailRequired: "El correo electrónico es obligatorio",
	MyAccount_LanguageRequired: "El idioma es obligatorio",
	MyAccount_UpdateSuccessMessage: "¡Los datos de su cuenta se han actualizado correctamente!",
	MyAccount_UpdateSuccessModal_ButtonText: "Cerrar",
	Infor_CorporateInformation_Header: "Información corporativa",
	Infor_LegalNotice_Header: "Aviso legal",
	Infor_PrivacyPolicy_Header: "Aviso de protección de datos (Política de privacidad)",
	CreateEmployeeAccountModification_FormValidation_Role: "El puesto es obligatorio",
	CreateEmployeeAccountModification_FormValidation_Firstname: "El nombre es obligatorio",
	CreateEmployeeAccountModification_FormValidation_Lastname: "El apellido es obligatorio",
	CreateEmployeeAccountModification_FormValidation_Mobile: "El número de contacto es obligatorio",
	CreateEmployeeAccountModification_FormValidation_Email: "El correo electrónico es obligatorio",
	CreateEmployeeAccountModification_FormValidation_Language: "El idioma es obligatorio",
	CreateEmployeeAccountModification_SendInvitation_Modal_Title: "Invitación para unirse a Bosch Automotive Training Solutions",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content1: "¡Ha agregado correctamente un nuevo miembro a su equipo!",
	CreateEmployeeAccountModification_SendInvitation_Modal_Content2: "¿Le gustaría enviar una invitación por correo electrónico para crear una cuenta personal en Bosch Automotive Training Solutions a su nuevo miembro del equipo?",
	CreateEmployeeAccountModification_SendInvitation_Modal_No: "No",
	CreateEmployeeAccountModification_SendInvitation_Modal_Yes: "Sí, enviar invitación",
	ShoppingCart_Venue_Fee: "Tarifa del lugar",
	ShoppingCart_Trainning_Name: "Nombre de la capacitación",
	ShoppingCart_Available_Credits: "Créditos disponibles",
	ConfirmItsMeModal_Hi: "Hola",
	ConfirmItsMeModal_Description1: "Ha creado correctamente su propia cuenta en Bosch Automotive Training Solutions, según la invitación del administrador de su empresa.",
	ConfirmItsMeModal_Description2: "Antes de continuar, confirme o actualice los datos de su cuenta.",
	ConfirmItsMeModal_Description3: "Después de confirmar, inicie sesión nuevamente para finalizar la creación de su cuenta.",
	ConfirmItsMeModal_CompanyDetails: "Datos de la empresa",
	ConfirmItsMeModal_YourDetails: "Sus datos",
	ConfirmItsMeModal_YourDetails_Title: "Título",
	ConfirmItsMeModal_YourDetails_Function: "Función",
	ConfirmItsMeModal_YourDetails_Name: "Nombre",
	ConfirmItsMeModal_YourDetails_Mobile: "Número de contacto",
	ConfirmItsMeModal_YourDetails_Email: "Correo electrónico",
	ConfirmItsMeModal_YourDetails_Language: "Idioma",
	ConfirmItsMeModal_ConfirmButton: "Confirmación",
	SelectWholesalerModal_WholesalerGroupLabel: "Mayorista",
	SelectWholesalerModal_WholesalerBranchLabel: "Punto de venta",
	MyRequests_First_SelectServiceModal_Choose_PlaceHolder: "Seleccione",
	ApplyButton: "Solicitar",
	SelectWholesalerModal_Title: "Elija su mayorista",
	RemoveWaitlistConfirmPopupPopup_RemoveWaitlistItemConfirmMessage: "¿Quiere eliminar su registro de la lista de espera?",
	RemoveWaitlistConfirmPopupPopup_RemoveSuccessMessage: "¡Su registro de la lista de espera se ha eliminado correctamente!",
	WaitlistPopup_AddToWaitListSuccess: "¡Su registro a la lista de espera se ha añadido correctamente!",
	ErrorCommon_Header: "ERROR",
	CommonButton_Close: "Cerrar",
	MyELearning_OpeningVideoPlayerTitle: "Bosch Automotive eLearning",
	MyELearning_VideoPlayerClosingMessage: "¡Cierre esta ventana para continuar!",
	EmbeddedVideoPlayer_StartButtonText: "Comenzar",
	EmbeddedVideoPlayer_RefresherButtonText: "Refresco",
	DeactivateWholesalerModal_ConfirmMessage: "¿Está seguro de que desea eliminar a este mayorista?",
	EmployeeAccountModificationLayout_SendInvitationEmailSuccess: "¡Correo electrónico de invitación enviado correctamente!",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_ReSend: "Volver a invitar",
	ContactUs_Mobile_AR: "+549 5296-5200",
	ContactUs_Address_AR: "Robert Bosch Argentina Industrial S.A , Blanco Encalada 250, San Isidro, Buenos Aires , Cód. Postal 1642 , Argentina",
	ConfirmItsMeModal_CompanyDetails_Name: "Nombre de la empresa",
	ConfirmItsMeModal_CompanyDetails_Address: "Dirección",
	ConfirmItsMeModal_CompanyDetails_Postcode: "Código postal de la empresa",
	ConfirmItsMeModal_CompanyDetails_Phone: "Teléfono",
	ConfirmItsMeModal_CompanyDetails_Email: "Correo electrónico",
	CourseWithdrawalModal_WithdrawSuccessMessage: "Retiro exitoso del curso",
	EmployeeAccountModificationLayout_AddNew_UserExistErrorMessage: "La creación de la cuenta ha fallado. Esta información de cuenta ya existe.",
	EmployeeAccountModificationLayout_Update_UserExistErrorMessage: "La actualización de la cuenta ha fallado. Esta información de cuenta ya existe.",
	Footer_ExploreTrainingStories: "Explora todas nuestras historias de capacitación",
	Breadcrumb_Home: "Inicio",
	Breadcrumb_Contact: "Contacto",
	Breadcrumb_Stories: "Historias",
	Breadcrumb_Stories_BoschServiceTraining: "Capacitación de servicio de Bosch",
	Breadcrumb_Stories_ADayInLife: "Un día en la vida de un capacitador",
	Breadcrumb_Stories_BoschTrainingBenefits: "Beneficios de la capacitación de Bosch",
	Breadcrumb_Stories_TrainingApproach: "Un enfoque de capacitación moderno",
	Breadcrumb_Settings: "Configuración",
	Breadcrumb_ShoppingCart: "Carrito de compras",
	Breadcrumb_Checkout: "Pagar",
	Breadcrumb_OrderSummary: "Resumen del pedido",
	Breadcrumb_Exception: "Excepción",
	Breadcrumb_Dashboard: "Panel de control",
	Breadcrumb_MyCourses: "Mis cursos",
	Breadcrumb_MyELearning: "Mi aprendizaje electrónico",
	Breadcrumb_CompanyAdministration: "Administración de la empresa",
	Breadcrumb_EmployeeManagement: "Gestión de empleados",
	Breadcrumb_EmployeeAccount: "Cuenta de empleado",
	Breadcrumb_EmployeeCreation: "Creación de empleados",
	Breadcrumb_Administration: "Administración",
	Breadcrumb_EmployeeAdministration: "Administración de empleados",
	Breadcrumb_WholesalerManagement: "Gestión de mayoristas",
	Breadcrumb_NewWholesalerAccountCreation: "Creación de nueva cuenta de mayorista",
	Breadcrumb_Transactions: "Transacciones",
	Breadcrumb_MyAccount: "Mi cuenta",
	Breadcrumb_TrainingCentres: "Centros de capacitación",
	Breadcrumb_EventCalendar: "Calendario de eventos",
	Breadcrumb_TrainingCourseCatalogue: "Catálogo de cursos de formación",
	Breadcrumb_RecommendedHotels: "Hoteles recomendados",
	Breadcrumb_CorporateInformation: "Información corporativa",
	Breadcrumb_LegalNotice: "Aviso legal",
	Breadcrumb_DataProtectionNotice: "Aviso de protección de datos",
	Breadcrumb_PrivacyPolicy: "Política de privacidad",
	Breadcrumb_TermCondition: "Términos y condiciones",
	Breadcrumb_Terms: "Términos",
	Breadcrumb_PrivacyStatement: "Declaración de privacidad",
	Breadcrumb_Cookies: "Cookies",
	Breadcrumb_Provider: "Proveedor",
	Breadcrumb_RegisterAccount: "Registrar cuenta",
	Stories_ExploreMore: "Explorar más",
	CheckOutLayout_ShoppingCartHeader: "Carrito de compra",
	CheckOutLayout_AddedItemCartMessage: "Has añadido lo siguiente a tu carrito",
	CheckOutLayout_CheckYourCartMessage: "Revisa tu carrito",
	ModernTrainingApproach_DateText: "2/28/2022 12:00:00 AM",
	ADayInLifeofATrainer_DateText: "2/28/2022 12:00:00 AM",
	BoschTrainingBenefits_DateText: "2/28/2022 12:00:00 AM",
	BoschServiceTraining_DateText: "2/28/2022 12:00:00 AM",
	EmployeeAdministrationTable_EmployeeSearchBox_Placeholder: "Buscar empleados",
	CheckOutLayout_OrderWasNotPlaced_Message: "¡Lo sentimos! No se realizó su pedido",
	CheckOutLayout_RefusalReason: "Motivo del rechazo",
	CheckOutLayout_TryWithDifferentPaymentMethod_Message: "Pago fallido. Vuelva a intentarlo con un método de pago diferente",
	CheckOutLayout_SubmittingApiFailed_Message: "Error al enviar la API",
	CartItem_ChooseWorkshop_Message: "Seleccione el taller para elegir al participante",
	CartItem_ChooseParticipant_Message: "Seleccione el participante para continuar",
	CompanyAdministrationTable_SearchBox_Placeholder: "Buscar talleres",
	CartItem_RemoveCartItem_ConfirmText: "¿Quitar artículo del carrito?",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_Function: "Función",
	AddToCartSuccessModal_Message: "¡Su carrito de compras ha sido actualizado!",
	AddToCartSuccessModal_ContinueButtonText: "Continuar comprando",
	AddToCartSuccessModal_MyShoppingCartButtonText: "Ir a caja",
	AddVoucher_Header: "Código de cupón",
	AddVoucher_InputPlaceholder: "Ingrese el código de cupón",
	AddToCart_UnfortunatelyNotHaveScheduledEvent: "Lamentablemente, no tenemos ningún evento programado en este momento. Regístrese en la lista de espera y le informaremos cuando haya un evento disponible.",
	ADYEN_ERR_2_: "La transacción fue rechazada",
	ADYEN_ERR_3_: "Error de referencias",
	ADYEN_ERR_4_: "La transacción no se realizó debido a un error que ocurrió en el lado del adquirente.",
	ADYEN_ERR_5_: "La tarjeta utilizada para la transacción está bloqueada y, por lo tanto, no se puede utilizar. Utilice otra tarjeta",
	ADYEN_ERR_6_: "La tarjeta utilizada para la transacción ha expirado, por lo tanto, no se puede utilizar. Utilice otra tarjeta.",
	ADYEN_ERR_7_: "Se produjo una discrepancia en el monto durante el proceso de transacción.",
	ADYEN_ERR_8_: "El número de tarjeta especificado es incorrecto o no es válido.",
	ADYEN_ERR_9_: "No es posible comunicarse con el banco del comprador para autorizar la transacción.",
	ADYEN_ERR_10_: "El banco del comprador no admite o no permite este tipo de transacción.",
	ADYEN_ERR_11_: "No se ejecutó la autenticación 3D Secure o no se ejecutó correctamente.",
	ADYEN_ERR_12_: "La tarjeta no tiene suficiente dinero para cubrir el monto a pagar. Este problema podría ocurrir debido a un límite de monto a pagar mensual en su tarjeta. Intente usar otra tarjeta, como MasterCard, o comuníquese con su banco para restablecer el límite mensual de su tarjeta.",
	ADYEN_ERR_14_: "Posible fraude.",
	ADYEN_ERR_15_: "La transacción fue cancelada.",
	ADYEN_ERR_16_: "El comprador canceló la transacción antes de que se completara.",
	ADYEN_ERR_17_: "El PIN especificado es incorrecto o no es válido.",
	ADYEN_ERR_18_: "El comprador especificó un PIN incorrecto más de tres veces seguidas.",
	ADYEN_ERR_19_: "No es posible validar el número PIN especificado.",
	ADYEN_ERR_20_: "Posible fraude.",
	ADYEN_ERR_21_: "La transacción no se envió correctamente para su procesamiento.",
	ADYEN_ERR_22_: "El riesgo verificado marcó la transacción como fraudulenta, por lo tanto, la operación ha sido cancelada.",
	ADYEN_ERR_23_: "Los siguientes códigos rechazados se asignan a este motivo de rechazo:  La transacción no está permitida para el emisor/titular de la tarjeta",
	ADYEN_ERR_24_: "El CVC (código de seguridad de la tarjeta) especificado no es válido.",
	ADYEN_ERR_25_: "Los siguientes códigos de rechazo se asignan a este motivo de rechazo: Tarjeta no válida en este país",
	ADYEN_ERR_26_: "R1: Orden de revocación de autorización / \"R3: Orden de revocación de todas las autorizaciones\" / \"R0: Orden de suspensión de pago\"",
	ADYEN_ERR_27_: "Esta respuesta asigna todos los códigos de respuesta que no se pueden asignar de manera confiable. Esto facilita la diferenciación de los rechazos genéricos (por ejemplo, la respuesta \"05: No respetar\" de Mastercard) de los más específicos.",
	ADYEN_ERR_28_: "Se ha excedido el monto de retiro permitido para la tarjeta del comprador.",
	ADYEN_ERR_29_: "Se ha excedido la cantidad de retiros permitidos para la tarjeta del comprador.",
	ADYEN_ERR_31_: "El emisor informó que la transacción era sospechosa de fraude.",
	ADYEN_ERR_32_: "Los datos de la dirección que ingresó el comprador son incorrectos",
	ADYEN_ERR_33_: "El banco del comprador requiere que el comprador ingrese un PIN en línea.",
	ADYEN_ERR_34_: "El banco del comprador requiere una cuenta corriente para completar la compra.",
	ADYEN_ERR_35_: "El banco del comprador requiere una cuenta de ahorros para completar la compra.",
	ADYEN_ERR_36_: "El banco del comprador requiere que el comprador ingrese un PIN móvil.",
	ADYEN_ERR_37_: "El comprador abandonó la transacción después de intentar un pago sin contacto y se le solicitó que probara un método de ingreso de tarjeta diferente (PIN o deslizamiento).",
	ADYEN_ERR_38_: "El emisor rechazó la solicitud de exención de autenticación ya que la transacción requiere autenticación. Inténtelo nuevamente con 3D Secure.",
	ADYEN_ERR_39_: "El emisor o el esquema no pudieron comunicar el resultado a través de RReq",
	CreateEmployeeAccountModification_FormValidation_Invalid_Firstname: "El nombre no debe incluir números ni caracteres especiales (p. ej.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Firstname: "El nombre debe tener menos de 75 caracteres",
	CreateEmployeeAccountModification_FormValidation_Invalid_Lastname: "El nombre no debe incluir números ni caracteres especiales (p. ej.: @#$...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Lastname: "El apellido debe tener menos de 75 caracteres",
	CreateEmployeeAccountModification_FormValidation_Invalid_Mobile: "El número de móvil debe tener solo caracteres numéricos (p. ej.: +12567567...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Mobile: "El número de móvil debe tener menos de 45 caracteres",
	CreateEmployeeAccountModification_FormValidation_Invalid_Telephone: "El teléfono debe ser un valor numérico (p. ej.: +12567567...)",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Telephone: "El número de teléfono debe tener menos de 45 caracteres",
	CreateEmployeeAccountModification_FormValidation_Invalid_Email: "La dirección de correo electrónico no es válida",
	CreateEmployeeAccountModification_FormValidation_MaxLength_Email: "El correo electrónico debe tener menos de 100 caracteres",
	ConfirmGeolocationModal_ConfirmButton: "Continuar",
	ConfirmGeolocationModal_CancelButton: "Cambiar",
	ConfirmGeolocationModal_CurrentCountrySelection: "Su selección de país actual es:",
	ConfirmGeolocationModal_AskChangeCountry: "¿Desea permanecer en este sitio o cambiar su preferencia de país?",
	Addtocart_Modal_AddToCartFailTitle: "Participante ya registrado en este curso",
	Addtocart_Modal_AlreadyInCart: "Participante ya registrado en este evento",
	TrainingCatalogue_DateRange: "Rango de fechas",
	TrainingCatalogue_DateRange_placeholder: "Seleccionar filtro de rango de fechas",
	Dashboard_MyPendingCourses: "Mis cursos pendientes",
	Dashboard_MyWaitingList: "Mi lista de espera",
	Dashboard_LatestNews: "Últimas noticias",
	SettingsMainPage_EditAccountButton: "Editar cuenta",
	SettingsMainPage_EditBoschId: "Editar ID de Bosch",
	EventInfoView_EventDetailTab: "Detalles del evento",
	EventInfoView_AssignmentsTab: "Tareas",
	EventInfoView_EvaluationTab: "Evaluación",
	EventInfoView_AttachmentTab: "Archivos adjuntos",
	EventInfoView_CourseDescriptionTab: "Descripción",
	EventInfoView_DescriptionTab_General: "General",
	EventInfoView_DescriptionTab_PartLabel: "Parte",
	AssignmentTab_Description: "Aquí encontrará toda la información que necesitará para completar este curso.",
	AssignmentTab_Columns_Download: "Descargar",
	AssignmentTab_Columns_Upload: "Subir",
	AssignmentTab_Columns_TaskComplete: "Tarea completada",
	EventDetailTab_Location_Message_Video: "Este es un evento en línea al que se puede acceder mediante el enlace \"Iniciar video\".",
	EventDetailTab_Location_Message_WebBased: "Este es un evento en línea al que se puede acceder mediante el enlace \"Iniciar WBT\".",
	EventDetailTab_Location_Message_WebCast: "Este es un evento en línea al que se puede acceder mediante el enlace \"Unirse ahora\".",
	EventDetailTab_Link_Video: "Iniciar video",
	EventDetailTab_Link_Video_Remark: "*Al iniciar el video se abrirá una nueva ventana. Se lo redireccionará nuevamente a esta página una vez que se cierre la ventana.",
	EventDetailTab_Link_WebBased: "Iniciar WBT",
	EventDetailTab_Link_WebBased_Remark: "*Al iniciar el WBT se abrirá una nueva ventana. Se lo redireccionará nuevamente a esta página una vez que se cierre la ventana.",
	EventDetailTab_Link_WebCast: "Únase ahora",
	EventDetailTab_Link_WebCast_Remark: "*El enlace para unirse ahora estará activo 30 minutos antes del inicio del evento.",
	EventDetailTab_Title_Status: "Estado",
	EventDetailTab_Title_Duration: "Duración (hrs)",
	EventDetailTab_Title_Trainers: "Instructor(es)",
	EventDetailTab_Title_Language: "Idioma",
	EventDetailTab_Title_Score: "Puntuación (%)",
	EventDetailTab_Title_Calendar: "Agregar al calendario",
	EventDetailTab_Title_Certificate: "Descargar certificado",
	EventDetailTab_Title_Messages: "Mensajes",
	EventDetailTab_Title_StartDate: "Fecha de inicio",
	EventDetailTab_Title_AvailableFrom: "Disponible desde",
	EventDetailTab_Title_Time: "Hora",
	EventDetailTab_Title_EndDate: "Fecha de finalización",
	EventDetailTab_Title_ExpiresOn: "Expira el",
	EventDetailTab_Title_Room: "Sala",
	EventDetailTab_Title_Method: "Método",
	EventDetailTab_Title_Link: "Enlace*",
	EventDetailTab_Title_Location: "Ubicación",
	AttachmentTab_EmptyScreenDescription: "No hay archivos adjuntos para este evento.",
	AttachmentTab_Columns_Download: "Descargar",
	AttachmentTab_Description: "Aquí encontrará información importante que lo ayudará con este curso",
	EvaluationTab_Annotation: "Valoramos sus comentarios y nos encantaría saber de usted para ver cómo lo estamos haciendo. Tómese el tiempo para completar la siguiente evaluación para ayudarnos a mejorar continuamente nuestro servicio para usted. Gracias.",
	EvaluationTab_Notice: "Las calificaciones son las siguientes",
	EvaluationTab_ValidateCsiAnsweredSubmit_ErrorMessage: "Responda todas las preguntas obligatorias antes de enviar la evaluación de su curso",
	EvaluationTab_CsiAnsweredSubmit_SuccessMessage: "Su comentario se ha enviado correctamente",
	EvaluationTab_CsiAnsweredSubmit_ErrorMessage: "El envío de la evaluación ha fallado",
	TrainingCatalogueLayout_ExportButtonText: "Exportar",
	EvaluationTab_FeedbackHasBeenGiven_Message: "La evaluación se completó o ha expirado.",
	EventDetailTab_Link_CopiedTooltip: "Copiado al portapapeles",
	EventDetailTab_QRCode_ExplanationText_WBT: "Escanear para iniciar WBT",
	EventDetailTab_QRCode_ExplanationText_Video: "Escanear para iniciar el video",
	EventDetailTab_QRCode_ExplanationText_Webcast: "Escanear para iniciar la transmisión web",
	Footer_TrainingCoure_Headline: "Cursos de capacitación proporcionados por Bosch",
	Footer_TrainingCourse_Content: "Como Superbrand de renombre mundial y con más de un siglo de experiencia en innovación, Bosch ha marcado la pauta a nivel mundial como proveedor de sistemas, piezas y soluciones técnicas para la automoción. Gran parte de nuestra fortaleza proviene de nuestras estrechas colaboraciones con los fabricantes de vehículos, para quienes diseñamos, desarrollamos y producimos sistemas y componentes de última generación en todo el mundo. En consecuencia, Bosch ha cimentado una sólida trayectoria en productos y asistencia para el mercado de posventa de automóviles con una cartera diversificada de piezas, soluciones de diagnóstico y servicios de taller. El aprendizaje y el desarrollo son claves indudables para el éxito y esta filosofía se sustenta en una amplia gama de cursos de formación en todas las áreas de tecnología automotriz, sistemas de vehículos y experiencia en diagnóstico.",
	SearchField_NoRecordsFound: "No se encontraron registros",
	TrainingCatalogue_FreeTextSearch: "Texto libre",
	TrainingCourseCatalogue_Filter_TextSearchPlaceHolder: "Buscar",
	CheckoutLayout_WholeSalerGroup: "Elegir grupo de mayoristas",
	CreditInsufficientModal_Message: "No tiene suficiente crédito para usar este método de pago. Inténtelo nuevamente con otro método de pago.",
	ShoppingCart_Required_Credits: "Precio total",
	MaintainingInfo_Message1: "El sitio web está en modo de mantenimiento.",
	MaintainingInfo_Message2: "Volveremos pronto.",
	CountryValidationModal_Message: "No pudo iniciar sesión en [CountryA] porque su cuenta se creó en [CountryB].",
	CountryValidationModal_ButtonText: "Continuar en [Country]",
	EventTable_LoginRequired: "Iniciar sesión para registrarse",
	TrainingPartTableLayout_Date: "Fecha",
	TrainingPartTableLayout_StartTime: "Hora de inicio",
	TrainingPartTableLayout_EndTime: "Hora de finalización",
	TrainingPartTableLayout_Duration: "Duraciones (hrs)",
	TrainingPartTableLayout_TrainingMethod: "Método de capacitación",
	MyCoursesTableContents_ActiveHeader: "Cursos activos",
	MyCoursesTableContents_ActiveTabHeader: "Activo",
	MyCoursesTableContents_ScheduledHeader: "Cursos programados",
	MyCoursesTableContents_ScheduledTabHeader: "Programados",
	MyCoursesTableContents_OnlineHeader: "Cursos en línea",
	MyCoursesTableContents_OnlineTabHeader: "En línea",
	MyCoursesTableContents_CancelledHeader: "Cursos cancelados",
	MyCoursesTableContents_CancelledTabHeader: "Cancelado",
	OnlineCoursesTable_Title_Progress: "Progreso",
	ScheduledCoursesTable_Title_Date: "Fecha [Días]",
	CoursesTable_Title_Completed: "Completado",
	Footer_TrainingProgram: "Programas de capacitación",
	Breadcrumb_TrainingProgram: "Programas de capacitación",
	TrainingProgram_Title_Table: "Título",
	TrainingProgram_TrainingProgram: "Programas de capacitación",
	TrainingProgram_TargetGroup: "Grupo objetivo",
	TrainingProgram_MaxLength: "Longitud máxima",
	TrainingProgram_Information: "Información",
	TrainingProgram_Detail: "Detalles",
	TrainingProgram_Modules: "Módulos",
	TrainingProgram_Course: "Cursos",
	TrainingProgram_PartNumber: "Número de parte",
	TrainingProgram_Max_Length: "Duración máxima",
	TrainingProgram_Duration: "Duración",
	TrainingProgram_Exam: "Examen",
	TrainingProgram_Assign_Popup: "Registrarse en el programa de formación",
	TrainingProgram_Student: "Estudiante",
	TrainingProgram_Assign: "Asignar",
	TrainingProgram_Popup_Remove_Title: "¿Cancelación de la formación?",
	TrainingProgram_Description: "Descripción",
	TrainingProgram_Days: "Días",
	TrainingProgram_Type: "Tipo",
	TrainingProgram_Action: "Acción",
	TrainingProgram_Enrolled: "ya se ha inscrito en este programa de formación",
	TrainingProgram_SelfAssign_Enrolled: "Ya está asignado a este programa de formación",
	TrainingProgram_Warning: "Por favor, registre a otro estudiante o seleccione un programa de formación diferente.",
	TrainingProgram_Workshop: "Taller",
	TrainingProgram_Durations: "años",
	TrainingProgram_Duration_DaysLabel: "días",
	TrainingProgram_HasExam_Yes: "Sí",
	TrainingProgram_HasExam_No: "No",
	AssignModal_SuccessModal_Header: "¡Felicitaciones!",
	AssignModal_SuccessModal_Message: "se ha inscrito en este programa de formación con éxito.",
	AssignModal_SelfAssign_SuccessModal_Message: "Se le ha asignado correctamente al programa de formación.",
	Dashboard_MyTraningProgram: "Mi programa de formación",
	Dashboard_MyTraningProgram_Title: "Título",
	Dashboard_MyTraningProgram_Status: "Status",
	Dashboard_MyTraningProgram_Table_View: "Ver",
	Dashboard_MyTraningProgram_Register: "Registrarse",
	Dashboard_MyTraningProgram_Records: "Registros históricos",
	Dashboard_MyTrainingProgram_In_Progress: "En proceso",
	Dashboard_MyTrainingProgram_Accredited: "Acreditado",
	Dashboard_Program_Modal_Withdraw: "Retirarse",
	Dashboard_Program_Modal_Grade: "Grado",
	Dashboard_MyTrainingProgram_Registered: "Registrado",
	Dashboard_MyTrainingProgram_Completedd: "Completado",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Button: "Sí",
	Dashboard_MyTrainingProgram_Withdraw_Cancel_Button: "No",
	Dashboard_MyTrainingProgram_Withdraw_Confirm: "Tenga en cuenta que retirarse significa que se retirará del programa de capacitación completo, no solo de un curso de capacitación individual.",
	Dashboard_MyTrainingProgram_Withdraw_Confirm_Question: "¿Aún desea retirarse del programa de capacitación?",
	Dashboard_MyTrainingProgram_ModuleTab_CoursePendingStatus: "Pendiente",
	WithdrawProgramModal_Withdraw_Success_Message: "¡Se ​​retiró exitosamente del programa de capacitación!",
	Common_Warning_Title: "¡Atención!",
	Common_Error_Title: "¡Ups, algo salió mal!",
	AddEventFromCatalogToCartModal_AddToCartButton: "Agregar al carrito",
	AddEventFromCatalogToCartModal_HoursText: "horas",
	AddElearningToCartSuccessModal_Message: "se agregó a su carrito de compras!",
	Addtocart_Modal_AlreadyRegisteredOrInCart_ErrorMessage: "El participante seleccionado se registró en este curso o el curso ya se agregó al carrito.",
	EventDetailTab_SubjectForChange_Message: "Sujeto a cambios",
	Dashboard_MyTrainingProgram_Status_Failed: "Reprobado",
	Dashboard_MyTrainingProgram_Status_Passed: "Aprobado",
	Dashboard_MyTrainingProgram_Status_Merit: "Mérito",
	Dashboard_MyTrainingProgram_Status_Distinction: "Distinción",
	Dashboard_Employee_Courses: "Cursos",
	Dashboard_Employee_Program: "Programa de capacitación",
	Dashboard_Employee_WaitList: "Lista de espera",
	Dashboard_Employee_Account: "Cuenta",
	Course_Price_Free: "Gratis",
	Dashboard_Employee_Account_Update_Success_Message: "¡Actualizó exitosamente la información de su empleado!",
	Dashboard_Supplier: "Proveedor",
	Event_Detail_Comment: "Actualmente no hay mensajes",
	DownLoad_PDF: "Descargar PDF",
	TrainingCatalogue_Currency: "Moneda",
	My_Wallet: "Mi billetera",
	Wallet: "Billetera",
	Credits_Balance: "Saldo de créditos",
	MyFullCalendar_Today: "Hoy",
	Header_Available_Languages: "Idiomas disponibles",
	Header_CurrentRegion: "Actualmente se encuentra en",
	Header_ChangeRegions: "¿Quiere cambiar de país?",
	Header_Anonymous: "Capacitación en servicio",
	Header_Anonymous_Login: "Iniciar sesión/Registrarse",
	Header_RegisterCourse: "Registrar curso",
	Homepage_Start: "Comenzar",
	Homepage_Login: "Iniciar sesión",
	MainPage_Title: "La solución definitiva para talleres",
	MainPage_Content: "Su taller, su elección.",
	MainPage_ImageTitle: "Soluciones de capacitación de Bosch Automotive",
	MainPage_IntroContent: "Permite que el taller moderno trabaje de manera profesional, confiable y eficiente en procedimientos de diagnóstico, resolución de problemas, reparaciones y servicio de todo tipo de vehículos.",
	BoschNews_TrainingNews: "Novedades en capacitación",
	Service_Assist_Workshop: "Mejore su experiencia con Workshop Service Assist",
	Enhance_Title_CDM3_v2: "¿Qué puedo hacer con la aplicación?",
	Enhance_Body_CDM3_Item_1: "Reserve cursos y mantenga su recorrido de aprendizaje",
	Enhance_Body_CDM3_Item_2: "Descargue material de capacitación y certificados de cursos",
	Enhance_Body_CDM3_Item_3: "Inicie cursos de aprendizaje electrónico",
	Enhance_Body_CDM3_Item_4: "Acceda a varios servicios automotrices dentro de una aplicación móvil",
	Enhance_Body_CDM3_Item_5: "Transmisión de video en vivo con Visual Connect Pro",
	Enhance_Body_CDM3_Item_6: "Herramienta gratuita para escaneo automático de vehículos",
	Training_Stories: "Historias de capacitación",
	SettingsMyAccount_JoinCompanyButton: "Únase a la empresa",
	SettingsMyProfileActivation_JoinCompanyModal_Title: "Únase a la empresa",
	SettingsMyProfileActivation_JoinCompanyModal_Description: "Ingrese su código de activación a continuación",
	SettingsMyProfileActivation_JoinCompanyModal_Notes: "¡Nota! Debe comunicarse con el administrador de su empresa para obtener un código de activación.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line1: "Su activación se ha procesado correctamente y su cuenta ahora está vinculada a su empresa.",
	SettingsMyProfileActivationSuccessModal_SuccessMessage_Line2: "Por favor haga clic en Continuar para ingresar al sitio web.",
	ContinueButton: "Continuar",
	WelcomeBatsModal_Title: "¡Su cuenta está actualmente en revisión!",
	WelcomeBatsModal_ActivationLink: "¡Haga clic aquí si tiene un código de activación para unirse a una empresa!",
	WelcomeBatsModal_ActivationLinkNotes: "¡Nota! Debe comunicarse con el administrador de su empresa para obtener un código de activación.",
	AccountInReviewModal_Description_Paragraph1: "Gracias por registrar una cuenta personal en Bosch Automotive Training Solutions.",
	AccountInReviewModal_Description_Paragraph2: "Nuestro personal de capacitación dedicado está revisando y configurando su cuenta. Recibirá la confirmación final por correo electrónico, una vez que su cuenta se haya configurado correctamente.",
	AccountInReviewModal_CloseButton: "¡Bien, déjeme entrar!",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line1: "No pudimos validar su código de activación y dirección de correo electrónico.",
	SettingsMyProfileActivationErrorModal_ErrorMessage_Line2: "Inténtelo nuevamente o comuníquese con el administrador de su empresa para obtener ayuda.",
	TryAgain_Button: "Inténtelo nuevamente",
	Error_Title: "¡Vaya, algo salió mal!",
	SettingsMyProfileActivationSuccessModal_Title: "¡Éxito!",
	AccountInReviewModal_Description_Paragraph3: "Mientras tanto, no dude en navegar por nuestro sitio web con acceso limitado.",
	AccountInReviewModal_Description_Paragraph4: "Si tiene alguna pregunta o si su cuenta no se procesa dentro de las 48 horas, excepto fines de semana y feriados, puede comunicarse con nosotros [aquí]",
	Support_Headline: "Bosch Automotive Training Solutions",
	Support_SubHeadline: "Algunas pautas para ayudarlo...",
	Support_FAQ_Headline: "Preguntas frecuentes",
	Support_FAQs_section1_title: "General",
	Support_FAQs_section1_question1: "¿Qué es Bosch Training Solutions?",
	Support_FAQs_section1_question1_answer1: "Bosch Training Solutions es una plataforma que proporciona una descripción general completa sobre capacitaciones técnicas y permite reservar y administrar capacitaciones.",
	Support_FAQs_section1_question2: "¿Puedo utilizar mi cuenta Bosch existente (ID de clave única) para iniciar sesión en Bosch Training Solutions?",
	Support_FAQs_section1_question2_answer1: "Sí, se puede acceder a ella con todas las cuentas creadas con \"Mi ID de Bosch\".",
	Support_FAQs_section1_question3: "¿Puedo utilizar una cuenta creada para Bosch Training Solutions con otros servicios o aplicaciones de Bosch?",
	Support_FAQs_section1_question3_answer1: "Sí, también puede utilizar su Bosch ID personal para una variedad de otras aplicaciones de Bosch, tanto a nivel profesional como privado, como bicicletas eléctricas o casas inteligentes.",
	Support_FAQs_section2_title: "Capacitación",
	Support_FAQs_section2_question1: "¿Cómo puedo reservar una capacitación?",
	Support_FAQs_section2_question1_answer1: "Inicie sesión con su cuenta existente O registre una cuenta en el portal. Vaya a \"Servicios de capacitación\" O \"Calendario de eventos\", seleccione una capacitación y haga clic en el botón Agregar a la tarjeta.",
	Support_FAQs_section2_question2: "No hay una fecha de capacitación adecuada disponible. ¿Qué puedo hacer?",
	Support_FAQs_section2_question2_answer1: "Tiene la posibilidad de agregarse a una lista de espera \"general\" (sin seleccionar una fecha) y nuestro equipo le informará tan pronto como haya una nueva capacitación disponible.",
	Support_FAQs_section2_question3: "¿Dónde puedo encontrar una descripción general de mis cursos reservados?",
	Support_FAQs_section2_question3_answer1: "Puede encontrar una descripción general completa de todos los cursos reservados y completados después de iniciar sesión en su panel de control.",
	Support_FAQs_section3_title: "Configuración",
	Support_FAQs_section3_question1: "¿Cómo puedo cambiar mi correo electrónico y contraseña?",
	Support_FAQs_section3_question1_answer1: "Debe iniciar sesión en el portal y cambiar a su perfil personal. (Dentro de su Panel de Control puede encontrar la información de su perfil o si hace clic en el siguiente botón en el encabezado",
	Support_FAQs_section3_question1_answer2: ")",
	Support_FAQs_section3_question1_answer3: "Allí tiene la posibilidad de cambiar los detalles de su Bosch ID",
	Support_FAQs_section3_question2: "¿Qué puedo hacer si olvidé mi contraseña?",
	Support_FAQs_section3_question2_answer1: "Utilice el botón de inicio de sesión/registro y complete su dirección de correo electrónico",
	Support_FAQs_section3_question2_answer2: "Si hace clic en continuar, tiene la posibilidad de hacer clic en \"Olvidé mi contraseña\"",
	Support_FAQs_section3_question3: "¿Cómo configurar una cuenta de taller?",
	Support_FAQs_section3_question3_answer1: "Si desea obtener un rol de taller, comuníquese con su Administración de Capacitación local, por ejemplo, puede utilizar el formulario de contacto en el sitio web.",
	LandingPage_Header_Title: "Soluciones de formación para la automoción de Bosch",
	LandingPage_ChooseCountry_Title: "Seleccione su país/región",
	MaintenanceBanner_Message_1: "El portal de soluciones de formación para la automoción de Bosch se actualizará el [maintenance_schedule]. Se espera que la actualización demore 30 minutos. El portal no estará disponible durante la actualización.",
	MaintenanceBanner_Message_2: "\nPara obtener más ayuda, póngase en contacto con nosotros por correo electrónico [support_email]",
	MaintenancePage_Message_1: "El portal de soluciones de formación para la automoción de Bosch se está actualizando actualmente",
	MaintenancePage_Message_2: "Esperamos que el portal vuelva a estar disponible el [maintenance_online_date] a las [maintenance_online_time]",
	MenuHeader_Support: "Soporte",
	Course_Detail_No_Prerequisites: "Sin requisitos previos",
	TableCell_Hours: "Horas",
	Dashboard_MenuItem_Dashboard: "Panel de control",
	Dashboard_MenuItem_Curricula: "Planes de estudio",
	Dashboard_MenuItem_Certificates: "Certificados",
	Dashboard_MenuItem_Team: "Equipo",
	Dashboard_MenuItem_Workshops: "Talleres",
	Dashboard_MenuItem_External: "Externo",
	Dashboard_MenuItem_Wallet: "Billetera",
	Dashboard_MenuItem_Profile: "Perfil",
	Dashboard_Headline_YourUpcomingCourses: "Sus próximos cursos",
	Dashboard_Headline_YourELearningCourses: "Sus cursos de aprendizaje electrónico",
	Dashboard_Headline_TeamOverview: "Descripción general del equipo",
	Dashboard_Headline_YourOverview: "Su descripción general",
	Dashboard_Headline_YourWishlist: "Su lista de deseos",
	Dashboard_Headline_ActivePrograms: "Programas activos",
	Dashboard_Headline_CompletedPrograms: "Programas completados",
	Dashboard_Headline_YourCertificates: "Sus certificados",
	Dashboard_Headline_Transactions: "Transacciones",
	Dashboard_Table_NoRecordsToDisplay: "No hay registros para mostrar",
	Dashboard_TableColumn_Location_Online: "En línea",
	Dashboard_TableColumn_Duration_Hours: "Horas",
	Dashboard_ELearningCourses_TableHeader_Title: "Título",
	Dashboard_ELearningCourses_TableHeader_Duration: "Duración (hrs)",
	Dashboard_ELearningCourses_ActionButton_View: "Ver",
	Dashboard_UpcomingCourses_TableHeader_Title: "Título",
	Dashboard_UpcomingCourses_TableHeader_DateDays: "Fecha [Días]",
	Dashboard_UpcomingCourses_TableHeader_Location: "Ubicación",
	Dashboard_UpcomingCourses_ActionButton_View: "Ver",
	Dashboard_YourOverview_TableHeader_Title: "Título",
	Dashboard_YourOverview_TableHeader_DateDaysDuration: "Fecha / Duración",
	Dashboard_YourOverview_TableHeader_Location: "Ubicación",
	Dashboard_YourOverview_TableHeader_Status: "Status",
	Dashboard_YourOverview_ActionButton_View: "Ver",
	Dashboard_YourWishlist_TableHeader_Title: "Título",
	Dashboard_YourWishlist_TableHeader_Action: "Acción",
	Dashboard_YourWishlist_ViewAllRecords: "Ver todos los registros",
	Dashboard_TeamOverview_TableHeader_Employee: "Empleado",
	Dashboard_TeamOverview_TableHeader_Title: "Título",
	Dashboard_TeamOverview_TableHeader_DateDaysDuration: "Fecha / Duración",
	Dashboard_TeamOverview_TableHeader_Location: "Ubicación",
	Dashboard_TeamOverview_TableHeader_Status: "Status",
	Dashboard_TeamOverview_ActionButton_View: "Ver",
	Dashboard_TeamOverview_SearchBox_Placeholder: "Buscar",
	Dashboard_ActiveProgram_TableHeader_Title: "Título",
	Dashboard_ActiveProgram_TableHeader_Status: "Status",
	Dashboard_ActiveProgram_ActionButton_View: "Ver",
	Dashboard_CompletedProgram_TableHeader_Title: "Título",
	Dashboard_CompletedProgram_TableHeader_Status: "Status",
	Dashboard_CompletedProgram_ActionButton_View: "Ver",
	Dashboard_Certificates_TableHeader_Title: "Título",
	Dashboard_Certificates_TableHeader_Date: "Fecha",
	Dashboard_Certificates_TableHeader_Status: "Status",
	Dashboard_Certificates_TableHeader_Action: "Acción",
	Dashboard_Certificates_ActionButton_Download: "Descargar",
	Dashboard_Transactions_TableHeader_InvoiceNumber: "Número de factura",
	Dashboard_Transactions_TableHeader_Description: "Descripción",
	Dashboard_Transactions_TableHeader_PaymentMethod: "Método de pago",
	Dashboard_Transactions_TableHeader_Date: "Fecha",
	Dashboard_Transactions_TableHeader_Amount: "Monto",
	Dashboard_Transactions_ActionButton_Download: "Descargar",
	Breadcrumb_MyTraining: "MyTraining",
	Breadcrumb_MyTraining_CourseDetails: "Detalles del curso",
	Breadcrumb_MyTraining_Curricula: "Planes de estudio",
	Breadcrumb_MyTraining_Curricula_TrainingProgramDetails: "Detalles del programa de formación",
	Breadcrumb_MyTraining_Team: "Equipo",
	Breadcrumb_MyTraining_Team_ViewEmployee: "Ver empleado",
	MyTraining_Headerbar_CourseDetails: "Detalles del curso",
	MyTraining_Headerbar_TrainingProgramDetails: "Detalles del programa de formación",
	TrainingProgramDetails_Label_Status: "Estado",
	TrainingProgramDetails_Label_OverallProgress: "Progreso general",
	TrainingProgramDetails_ProgramItems_Headline: "Elementos del programa",
	TrainingProgramDetails_ProgramItems_TableHeader_Title: "Título",
	TrainingProgramDetails_ProgramItems_TableHeader_Status: "Status",
	TrainingProgramDetails_ProgramItems_TableHeader_Grade: "Grado",
	TrainingProgramDetails_ProgramItems_TableHeader_Date: "Fecha",
	TrainingProgramDetails_ProgramItems_ActionButton_View: "Ver",
	TrainingProgramDetails_Status_InProgress: "En proceso",
	TrainingProgramDetails_Status_Completed: "Completado",
	CourseDetails_Label_Status: "Status",
	CourseDetails_Label_Duration: "Duración",
	CourseDetails_Label_Trainer: "Formador",
	CourseDetails_Label_Language: "Idioma",
	CourseDetails_Label_Score: "Puntuación:",
	CourseDetails_Label_StartDate: "Fecha de inicio",
	CourseDetails_Label_EndDate: "Fecha de finalización",
	CourseDetails_Label_Venue: "Lugar",
	CourseDetails_Label_Address: "Dirección",
	CourseDetails_Label_Grade: "Calificación",
	CourseDetails_Action_Withdraw: "Retirarse",
	CourseDetails_Action_Evaluate: "Evaluar curso",
	CourseDetails_Action_Evaluate_Description: "¡Por favor, evalúe este curso para generar su certificado!",
	CourseDetails_Action_DownloadCertificate: "Descargar certificado",
	CourseDetails_Action_JoinWebcast: "Unirse al webcast",
	CourseDetails_Action_StartCourse: "Iniciar curso",
	CourseDetails_Action_RestartCourse: "Reiniciar curso",
	CourseDetails_Action_RepeatCourse_Description: "¡Por favor, repita y apruebe este curso para descargar su certificado!",
	CourseDetails_PartSpecificDetails_Headline: "Detalles específicos de la parte",
	CourseDetails_PartSpecificDetails_Tab_Part: "Parte",
	CourseDetails_PartSpecificDetails_Description: "Descripción",
	CourseDetails_PartSpecificDetails_StartDate: "Fecha de inicio",
	CourseDetails_PartSpecificDetails_EndDate: "Fecha de finalización",
	CourseDetails_PartSpecificDetails_StartTime: "Hora de inicio",
	CourseDetails_PartSpecificDetails_EndTime: "Hora de finalización",
	CourseDetails_PartSpecificDetails_Method: "Método",
	Dashboard_Team_TableHeader_Name: "Nombre",
	Dashboard_Team_TableHeader_Role: "Rol",
	Dashboard_Team_TableHeader_Email: "Correo electrónico",
	Dashboard_Team_TableHeader_PersonalAccount: "Cuenta personal",
	Dashboard_Team_ActionButton_Select: "Seleccionar",
	Dashboard_Team_PersonalAccount_Activated: "Activado",
	Dashboard_Team_PersonalAccount_NotActivated: "No activado",
	Dashboard_Team_ActionButton_AddMember: "Agregar miembro",
	Dashboard_Team_EmployeeSearchBox_Placeholder: "Buscar",
	Dashboard_External_TableHeader_Name: "Nombre",
	Dashboard_External_ActionButton_AddNew: "Agregar nuevo",
	Dashboard_External_Headline: "Acceso externo aprobado",
	Dashboard_Workshops_Headline: "Talleres",
	Dashboard_Workshops_TableHeader_Company: "Empresa",
	Dashboard_Workshops_TableHeader_Address: "Dirección",
	Dashboard_Workshops_TableHeader_Postcode: "Código postal",
	Dashboard_Workshops_TableHeader_City: "Ciudad",
	Dashboard_Workshops_ActionButton_Select: "Seleccionar",
	Wallet_ServiceCredits: "Créditos de servicio",
	Wallet_Headline_CreditBalance: "Saldo de crédito",
	Wallet_Headline_CreditActivity: "Actividad de crédito",
	Wallet_Headline_BillingTransactions: "Transacciones de facturación",
	Wallet_BillingTransaction_TableHeader_Order: "Pedido",
	Wallet_BillingTransaction_TableHeader_Date: "Fecha",
	Wallet_BillingTransaction_TableHeader_Amount: "Monto",
	Wallet_BillingTransaction_TableHeader_Action: "Acción",
	Wallet_CreditActivity_TableHeader_Date: "Fecha",
	Wallet_CreditActivity_TableHeader_Description: "Descripción",
	Wallet_CreditActivity_TableHeader_User: "Usuario",
	Wallet_CreditActivity_TableHeader_Amount: "Monto",
	FooterAdditionalLinks_Copyright: "© Robert Bosch, todos los derechos reservados",
	FooterAdditionalLinks_Navigation_ProductSecurity: "Seguridad de productos (PSIRT)",
	FooterAdditionalLinks_Navigation_Patents: "Innovaciones, patentes y licencias",
	FooterAdditionalLinks_Navigation_Logistics: "Compras y logística",
	MenuHeader_MyTraining: "Mi capacitación",
	MenuHeader_TrainingServices: "Servicios de capacitación",
	MenuHeader_OurLocations: "Nuestras ubicaciones",
	MenuHeader_ContactsUs: "Contáctenos",
	MenuHeader_Logout: "Cerrar sesión",
	MenuHeader_FAQ: "Preguntas frecuentes",
	MyProfile_MyBoschId: "Mi identificación de Bosch",
	MyProfile_AccountDeletion: "Eliminación de cuenta",
	MyProfile_Edit: "Cambiar correo electrónico o editar contraseña",
	MyProfile_Profile: "MyBosch",
	MyProfile_EditUserProfile: "Editar perfil de usuario",
	BackButton: "Volver",
	ContactUs_Email_DataProtection1_kr: "N/A",
	ContactUs_Email_DataProtection2_kr: "N/A",
	ContactUs_Email_DataProtection3_kr: "N/A",
	ContactUs_Email_DataProtection4_kr: "N/A",
	ContactUs_Email_DataProtection5_kr: "N/A",
	ContactUs_Email_DataProtection6_kr: "N/A",
	ContactUs_Email_DataProtection7_kr: "N/A",
	ContactUs_Email_DataProtection_kr: "N/A",
	ContactUs_Phone_Description_V3: "Esperamos su llamada y estaremos encantados de atenderle. Estamos disponibles para usted las 24 horas, los 7 días de la semana.",
	ContactUs_Email_SecurityCodeConfirm_V3: "Introduzca los caracteres correctos, tal y como se indica en el cuadro de arriba.",
	ContactUs_Email_MandatoryNotice_V3: "Complete todos los campos obligatorios.",
	ContactUs_Email_SendButton: "Enviar",
	Breadcrumb_TrainingCenters: "Centros de formación",
	Training_Centres_Book_Btn: "Reservar un curso",
	Training_centres_booking_code: "Código de reserva",
	Training_centers_header_bar_title: "Nuestros centros de formación",
	Training_centers_filter_item: "Centro",
	TeamEdit_PersonalAccountToolTop: "Al habilitar esta función, el empleado podrá acceder a este sitio web. El usuario podrá crear una cuenta personal y ser parte de su cuenta corporativa. Una vez habilitada, podrá enviar una invitación con un código de activación al empleado. La invitación se enviará a la dirección de correo electrónico.",
	TeamEdit_Update: "Al habilitar la función de administrador de la empresa, este empleado tendrá acceso total a su cuenta corporativa de manera automática. Para habilitar esta función, el usuario debe cerrar sesión y volver a iniciarla en el sitio web, si ya ha iniciado sesión.",
	TeamEdit_PersonalAccount: "Permitir cuenta web personal de Bosch Training Solutions",
	TeamEdit_SendInvitation: "Enviar invitación",
	TeamEdit_Deactivate: "Desactivar",
	TeamEdit_Telephone: "Teléfono",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Mobile: "El móvil debe ser un número",
	CreateTeamMemberAccountModification_FormValidation_Not_Numbers_Telephone: "El teléfono debe ser un número",
	TeamEdit_Activate: "Activo",
	TeamEdit_NotActivated: "No activar",
	TeamEdit_StatusPopup: "El estado se mostrará como \"Activo\" si el usuario ha creado una cuenta personal correctamente. Si el estado se establece como \"No activo\", significa que el usuario no ha completado su invitación.",
	Training_Catalog_Courses: "Cursos",
	Training_Catalog_Course: "Curso",
	Reset_Filter: "Restablecer filtro",
	TrainingCatalogue_CourseCatalogue: "Catálogo de cursos",
	CheckoutHeader_CartItemCount_Text: "Tienes [count] artículo en tu carrito de compras",
	CheckoutHeader_CartItemsCount_Text: "Tienes [count] artículos en tu carrito de compras",
	ShoppingCart_PriceItem: "Precio del artículo",
	ShoppingCartItemParticipants_AddParticipant_Button: "Agregar participantes",
	ShoppingCartTotalPrice_SubTotalLabel: "Subtotal",
	ShoppingCartTotalPrice_TaxesLabel: "Impuestos",
	ShoppingCartTotalPrice_TotalLabel: "Total",
	ShoppingCartTotalPrice_IncludedVATLabel: "Incluye IVA de [vat_amount]",
	AddVoucher_AvailablePaymentMethods: "Métodos de pago en línea disponibles",
	ShoppingCartItemRow_VenueFeeLabel: "Cuota del lugar [fee_amount] por participante",
	ShoppingCartItemRow_OnlineLabel: "En línea",
	AddParticipantsModal_Title: "Agregar participante",
	AddParticipantsModal_SelectEmployeeLabel: "Agregar participante",
	AddParticipantsModal_SelectWorkshopLabel: "Seleccionar taller",
	ShoppingCartTotalPrice_ContinueButtonText: "Continuar con el pago",
	ShoppingCartTotalPrice_NoParticipantModal_Title: "¡No se agregó ningún participante!",
	ShoppingCartTotalPrice_NoParticipantModal_Message1: "Tienes uno o más cursos en tu carrito de compras sin un participante.",
	ShoppingCartTotalPrice_NoParticipantModal_Message2: "Agrega un participante para continuar con el pago.",
	ShoppingCartItemParticipants_ShowMoreLinkText: "Editar participantes ([num_participants])",
	AddParticipantsModal_ParticipantTable_Name: "Nombre",
	AddParticipantsModal_ParticipantTable_Email: "Correo electrónico",
	AddParticipantsModal_ParticipantTable_Action: "Acción",
	AddParticipantsModal_ParticipantTable_NoParticipant: "No hay participantes…",
	AddParticipantsModal_ParticipantEmailValidationModal_Title: "Dirección de correo electrónico no configurada en el participante seleccionado",
	AddParticipantsModal_ParticipantEmailValidationModal_Message1: "El participante seleccionado no tiene una dirección de correo electrónico configurada en su perfil. La dirección de correo electrónico es obligatoria para registrarse en un curso de capacitación.",
	AddParticipantsModal_ParticipantEmailValidationModal_Message2: "Agregue una dirección de correo electrónico para continuar o cancele para seleccionar otro participante.",
	AddParticipantsModal_ParticipantEmailValidationModal_AcceptButton: "Agregar correo electrónico",
	CancelButton: "Cancelar",
	AddParticipantsModal_AddEmployeeButtonText: "Agregar empleado",
	ShoppingCartItemRow_RemoveCourseConfirm_Title: "Está a punto de eliminar un curso de su carrito",
	ShoppingCartItemRow_RemoveCourseConfirm_Message1: "Está intentando eliminar un curso con participantes agregados de su carrito.",
	ShoppingCartItemRow_RemoveCourseConfirm_Message2: "Haga clic en \"Continuar\" si desea continuar o haga clic en \"Cancelar\".",
	ShoppingCartItemHeader_Participants: "Participante(s)",
	ShoppingCartItemHeader_ParticipantPrice: "Precio por asiento",
	CheckOutMainContent_ViewPriceInEUR_ButtonText: "Mostrar en [currency]",
	CheckOutMainContent_ViewPriceInCredit_ButtonText: "Mostrar en créditos",
	ShoppingCart_CreditsPriceUnit: "Créditos",
	CheckOutHeader_Step1_Question: "¿Cómo desea pagar?",
	CheckOutHeader_Step2_Question: "Revise su pedido",
	CheckOutHeader_Step3_Question: "Complete su pedido",
	CheckOutHeader_Step4_Question: "¡Su reserva fue exitosa!",
	CheckOutHeader_Step_Payment: "Pago",
	CheckOutHeader_Step_Review: "Revisar",
	CheckOutHeader_Step_Place_Order: "Realizar pedido",
	CheckOutRightPanel_Review_Order_Btn: "Revisar pedido",
	CheckOutRightPanel_CheckOut_Step_Place_Order_Btn: "Realizar pedido",
	CheckoutRightPanelContent_OrderSummary_Title: "Resumen del pedido",
	CheckoutRightPanelContent_Edit_Shopping_Cart: "Editar carrito de compras",
	ShoppingCart_Participants: "Participantes",
	CheckOutStepPayment_Payment_Methods_Title: "Seleccione el método de pago",
	CheckOutStepPayment_Credit_Title: "Crédito o débito",
	CheckOutStepPayment_Wholesaler_Title: "Mayorista",
	CheckOutStepPayment_ServiceCredit_Title: "Créditos de servicio",
	CheckOutStepPayment_ServiceCredit_AvailableCredit: "Tiene [available_credit] créditos",
	CheckOutStepPayment_BoschAccount_Title: "Cuenta Bosch",
	Checkout_Second_PaymentMethod_Headline: "Método de pago",
	CheckOutStepReview_Edit: "Editar",
	CheckOutStepReview_Terms_Label: "Términos, condiciones y política de cancelación",
	CheckOutStepReview_Terms_Text: "He leído, comprendido y acepto los [terms, conditions and cancellation policy] de venta.",
	SelectSecondPaymentMethodModal_Title: "Seleccione un método de pago secundario",
	SelectSecondPaymentMethodModal_ConfirmButtonText: "Pague [amount]",
	StepPaymentWholesaler_Title: "Seleccione un mayorista",
	CheckOutRightPanel_PaymentTermsInvalidModal_Title: "Aceptar términos y condiciones",
	CheckOutRightPanel_PaymentTermsInvalidModal_Message: "Lea y acepte nuestros términos, condiciones y política de cancelación antes de continuar.",
	CheckoutDescription_ThankYou: "¡Gracias por enviar un pedido en Bosch Automotive Training Solutions!",
	CheckoutDescription_Message: "Nuestros bits and bytes ya han comenzado a procesar su pedido. Mientras tanto, mantenga el motor en ralentí.",
	CheckoutDesctiption_Text: "Le enviaremos una confirmación final por correo electrónico que incluye todos los detalles de su reserva.",
	WorkshopSelection_RemoveWorkshopWarning_Title: "Está a punto de eliminar el taller seleccionado",
	WorkshopSelection_RemoveWorkshopWarning_Message1: "Al eliminar el taller seleccionado, se restablecerán todos los participantes agregados. Este cambio afectará a todos los cursos en su carrito de compras.",
	WorkshopSelection_RemoveWorkshopWarning_Message2: "Haga clic en \"Continuar\" si desea continuar o haga clic en \"Cancelar\" para detener esta acción.",
	CheckOutStepPayment_Insufficient_Credit_Message: "No tiene suficientes créditos. Su saldo de créditos es de [available_credit] créditos, pero necesita [cart_total_credits] créditos para continuar con el pago. Seleccione otro método de pago o elimine artículos de su carrito de compras.",
	Training_centers_filter_items: "Centros",
	Training_Programs_Filter_Item: "Programas",
	Training_Program_Filter_Item: "Programa",
	Training_Program_Year: "Año(s)",
	Training_Program_Detail_Title: "Programas de cursos de formación",
	AccountButton_Workshops_MenuItem: "Talleres",
	AccountButton_Wallet_MenuItem: "Billetera",
	Header_MyBoschProfile_Header: "Perfil MyBosch",
	Dashboard_MyTrainingProgram_ModuleTab_CourseAvailableStatus: "Disponible",
	AddParticipantsModal_AddOrEditParticipantsTitle: "Añadir/Editar participante",
	ShoppingCart_CreditPriceUnit: "Crédito",
	Common_IncludedVAT: "IVA incluido",
	TrainingCatalogue_Length: "Duración",
	TrainingCatalogue_Method: "Método",
	Course_Price_Credits: "Créditos",
	Catalogue_Grid_Register_Button: "Registrarse",
	Catalogue_Credit_Tilte: "Haga clic aquí para ver los precios en créditos​",
	Catalogue_Price_Tilte: "Haga clic aquí para ver los precios en EUR​",
	Catalogue_Grid_Vat: "IVA excl.",
	EventCalendar_Seats: "Plazas",
	MenuHeader_Calendar: "Calendario",
	MenuHeader_Catalog: "Catálogo",
	TableCell_Minutes: "Minutos",
	ListFilter_NoResult: "Sin resultados",
	Course_Description: "Descripción del curso​",
	Training_course_detail: "Conceptos básicos de las redes sociales​",
	Training_course_detail_add_waiting: "Añadir a la lista de espera general​",
	Register_now_btn: "¡Regístrese ahora!",
	Register_now_btn_add_event_to_cart: "¡Regístrese ahora!",
	Dashboard_TrainingCourses_YourOverview_ViewAllRecords: "Ver todos los registros",
	Dashboard_TrainingCourses_YourOverview_ViewLess: "Ver menos",
	Dashboard_TrainingCourses_TeamOverview_ViewAllRecords: "Ver todos los registros",
	Dashboard_TrainingCourses_TeamOverview_ViewLess: "Ver menos",
	Dashboard_TrainingCourses_YourWishlist_ViewAllRecords: "Ver todos los registros",
	Dashboard_TrainingCourses_YourWishlist_ViewLess: "Ver menos",
	TeamCreate_TitleError: "El título es obligatorio",
	Dashboard_Headerbar_BookCourse: "Reservar curso",
	Dashboard_Headerbar_ExitProxy: "Salir de Proxy",
	Dashboard_Headerbar_EvaluateCourse: "Evaluar curso",
	Dashboard_Headerbar_EvaluateCourse_Note: "¡Evalúa este curso para generar tu certificado!",
	Dashboard_Headerbar_DownloadCertificate: "Descargar certificado",
	Dashboard_Headerbar_TrainingCourse_Withdraw: "Retirar",
	Dashboard_Headerbar_TrainingProgram_Withdraw: "Retirar",
	Dashboard_Headerbar_WelcomeMessage: "Bienvenido a tu panel de aprendizaje",
	Dashboard_Headerbar_ProxingAs: "Representando como proxy",
	Dashboard_TrainingProgram_Status_Completed: "Completado",
	Dashboard_TrainingProgram_Status_InProgress: "En progreso",
	External_SelectWholesalerModal_Title: "Agregar acceso de usuario externo",
	External_SelectWholesalerModal_Company_Label: "Empresa",
	External_SelectWholesalerModal_Outlet_Label: "Outlet",
	External_SelectWholesalerModal_Company_Placeholder: "Selecciona",
	External_SelectWholesalerModal_Outlet_Placeholder: "Selecciona",
	External_SelectWholesalerModal_ApplyButton: "Aplicar",
	External_SelectWholesalerModal_CancelButton: "Cancelar",
	External_RemoveWholesalerModal_ConfirmLabel: "Confirmación",
	External_SelectWholesalerModal_ConfirmMessage: "¿Estás seguro de que deseas eliminar a este mayorista?",
	External_SelectWholesalerModal_YesButton: "Sí",
	External_SelectWholesalerModal_NoButton: "No",
	Training_program_team_member: "Miembro del equipo",
	Training_program_assign_training_program: "Asignar programa de capacitación",
	Assign_modal_student_confirmation_text1: "Estás a punto de asignar [Training Program Name] a tu recorrido de aprendizaje.",
	Assign_modal_student_confirmation_text2: "¿Desea continuar?",
	MyFullCalendar_Jan: "Ene",
	MyFullCalendar_Feb: "Feb",
	MyFullCalendar_Mar: "Mar",
	MyFullCalendar_Apr: "Abr",
	MyFullCalendar_May: "May",
	MyFullCalendar_Jun: "Jun",
	MyFullCalendar_Jul: "Jul",
	MyFullCalendar_Aug: "Ago",
	MyFullCalendar_Sep: "Sep",
	MyFullCalendar_Oct: "Oct",
	MyFullCalendar_Nov: "Nov",
	MyFullCalendar_Dec: "Dic",
	MenuHeader_CourseCatalog: "Catálogo de cursos",
	MenuHeader_Event: "Calendario de eventos",
	MenuHeader_Program: "Programa de capacitación",
	MenuHeader_Services: "Servicios de capacitación",
	MenuHeader_Log_out: "Cerrar sesión",
	Filter_Location: "Ubicación",
	TeamEdit_UpgradeToCompanyAdministrator: "Actualizar a Administrador de la empresa",
	TeamEdit_PersonalWebAccountStatusIs: "El estado de la cuenta web personal es",
	Header_CreateNewEmployee: "Crear nuevo empleado",
	AddParticipantsModal_NoMoreSeatAvailable_Title: "¡No hay cupos disponibles!",
	AddParticipantsModal_NoMoreSeatAvailable_Message1: "No hay cupos disponibles en el evento seleccionado.",
	AddParticipantsModal_NoMoreSeatAvailable_Message2: "Seleccione otro evento o elimine un participante ya agregado para continuar.",
	Common_SuccessModal_Title: "¡Felicitaciones!",
	TrainingProgramDetails_Merit_StatusText: "Mérito",
	TrainingProgramDetails_Resit_StatusText: "Reanudar",
	EmployeeProfile_UserExistErrorMessage: "¡La cuenta ya existe!",
	PaymentAdyenCheckout_PaymentMethod_NotSupport_Message: "Pago fallido. Inténtalo de nuevo o utiliza otro método de pago.",
	EmployeeProfile_DeactivateEmployee_SuccessMessage: "¡Has desactivado correctamente al empleado seleccionado!",
	EmployeeProfile_ProfileUpdateSuccess_Title: "¡Guardado!",
	EmployeeProfile_ProfileUpdateSuccess_Message: "Se han guardado tus cambios.",
	CourseDetail_Withdrawal_NotPossible_Tooltips: "No es posible retirarse en los cursos de eLearning. Ponte en contacto con nosotros si necesitas más información.",
	Dashboard_Team_PersonalAccount_Pending: "Pendiente",
	EmployeeProfile_EmployeeCreatedSuccess_Title: "¡Empleado creado!",
	EmployeeProfile_EmployeeCreatedSuccess_Message: "La creación de tu empleado se ha completado correctamente.",
	Dashboard_AdministrationSection_EmployeeAdministrationSection_EmployeeAccountSection_AllowEmployeeToRegisterForTrainingEvents_Tooltip: "Esta configuración te permite restringir que un empleado se registre en eventos de capacitación. Solo los administradores de la empresa podrán registrar al empleado en eventos de capacitación cuando esta configuración no esté seleccionada.",
	Training_catalog_sort_by: "Ordenar por",
	CourseDetail_EvaluationRestriction_Title: "¡No es posible realizar evaluaciones de capacitación!",
	CourseDetail_EvaluationRestriction_Message: "Las evaluaciones de capacitación solo pueden ser enviadas por el propio estudiante. \nInstruye al estudiante a iniciar sesión en Bosch Automotive Training Solutions y completar la evaluación de capacitación.",
	CourseDetail_EvaluationRestriction_AdditionalMessage: "Si el estudiante aún no tiene una cuenta, se puede compartir una invitación en la configuración del perfil de usuario del miembro del equipo.",
	Breadcrumb_Download_Page: "Descargar",
	Download_Table_Category_Cell: "Categoría",
	Download_Table_Title_Cell: "Título",
	Download_File_Filter_Item: "Archivo",
	Download_File_Filter_Items: "Archivos",
	Download_Header: "Área de descarga",
	DownloadArea_Button_Download: "Descargar",
	Popup_Title_Error: "Error",
	Popup_Title_Success: "Éxito",
	Popup_Title_Info: "Información",
	Popup_Title_Warning: "¡Atención!",
	Dashboard_MyTrainingProgram_Withdraw_EventRegistrationExisting: "Las inscripciones a los siguientes eventos potenciales ya existentes no se cancelarán y deberán cancelarse individualmente.",
	TrainingProgram_PDF_Export: "Exportación de PDF",
	TrainingProgram_PDF_Export_Participant: "Participante:",
	TrainingProgram_PDF_Export_Status: "Estado:",
	TrainingProgram_PDF_Export_Progress: "Progreso general:",
	TrainingProgram_PDF_Export_TableHeader_Title: "Título de la capacitación",
	TrainingProgram_PDF_Export_TableHeader_Status: "Estado",
	TrainingProgram_PDF_Export_TableHeader_Grade: "Grado",
	TrainingProgram_PDF_Export_TableHeader_Date: "Fecha",
	AccountButton_CompanyChange_MenuItem: "Cambio de empresa",
	SelectCompanyModal_Title: "Seleccionar empresa",
	CloseButton: "Cerrar",
	Change_Button: "Cambio",
	ChangeManagerModal_Content: "Seleccionar usuario",
	ChangeCompanyModal_HeadOffice: "(Oficina central)",
	SelectCompanyModal_Content: "Seleccione la empresa para iniciar sesión…",
	AlertSuccess_Title: "Éxito",
	CheckOutStepPayment_SecondaryPayment_Title: "Seleccione el método de pago secundario",
	CheckOutStepPayment_SecondaryPayment_AddPaymentbtn: "Añadir un método de pago secundario",
	CheckOutStepPayment_SecondaryPayment_Content_Bottom: "Añade un método de pago secundario a continuación o selecciona otro método de pago arriba.",
	CheckOutStepPayment_SecondaryPayment_Content_Top: "No tienes suficientes créditos de servicio en tu cuenta para pagar los artículos de tu carrito. Para continuar usando el método de pago seleccionado, debes pagar la diferencia. La diferencia es [difference_amount] (sin IVA).",
	ShoppingCartTotalPrice_TotalInCredit: "Precio total en créditos",
	ShoppingCartTotalPrice_YourAvaliableCredit: "Tus créditos disponibles",
	ShoppingCartTotalPrice_YourDifference: "Diferencia",
	ShoppingCartTotalPrice_SubtotalExTax: "Subtotal sin impuestos",
	ShoppingCartTotalPrice_SubtotalInTax: "Subtotal con impuestos",
	CheckOutStepPayment_Second_Payment_Methods_Title: "Método de pago secundario",
	CheckOutStepPayment_SecondaryPayment_EditPaymentbtn: "Editar método de pago",
	Checkout_Second_PaymentMethod: "Segundo método de pago",
	Breadcrumb_TrainingCourseDetails: "Detalles del curso de capacitación",
	DownloadArea_FailedToDownload_ErrorMessage: "No se pudo descargar el documento. Inténtelo nuevamente o comuníquese con el administrador de su empresa para obtener ayuda.",
	DownloadArea_FileNotAvailable_ErrorMessage: "El archivo de descarga no está disponible. Inténtelo nuevamente o comuníquese con el administrador de su empresa para obtener ayuda.",
	DownloadArea_FileAccessForbidden_ErrorMessage: "La descarga del archivo está prohibida. Inténtelo nuevamente o comuníquese con el administrador de su empresa para obtener ayuda.",
	SSO_Redirecting_Message: "Será redirigido a Bosch Automotive Training Solutions...",
	SSO_Redirecting_ParameterErrorMessage: "No se especificó el país o el idioma. Inténtelo nuevamente o comuníquese con el administrador de su empresa para obtener ayuda.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorTitle: "Solicitudes de inscripción no aceptadas.",
	EventCalendar_EnrolmentRequest_NotAccepted_ErrorMessage: "Esto puede deberse a que se encuentra dentro del límite de días de inscripción del curso para uno o más de los cursos. Utilice el área \"Contáctenos\" si tiene alguna pregunta o solicitud.",
	TimeSlotPicker_DateFormat_Title: "Formato de fecha",
	UpdateAccountSettingsConfirmationModal_Title: "¡Está a punto de cambiar el formato de fecha!",
	UpdateAccountSettingsConfirmationModal_Message1: "El formato de fecha se mostrará de acuerdo con su selección y se puede cambiar en cualquier momento.",
	UpdateAccountSettingsConfirmationModal_Message2: "¿Desea continuar?",
	ProfileSummary_Settings_Title: "Configuración",
	UpdateAccountSettings_DateFormat_Title: "Configuración del formato de fecha",
	UpdateAccountSettings_DateFormat_ErrorMessage: "No se pudo actualizar el formato de fecha.",
	UpdateAccountSettings_DateFormat_SuccessMessage: "La configuración del formato de fecha se actualizó correctamente."
};